// icon:arrows_right | Linea Iconset https://linea.io/ | Benjamin Sigidi
import * as React from "react";
import styles from "./component.module.css";

function ArrowRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={`${styles.deleteIcon}`} viewBox="0 0 64 64" fill="currentColor" height="1em" width="1em" {...props}>
      <path fill="none" stroke="currentColor" strokeLinejoin="bevel" strokeMiterlimit={10} strokeWidth={2} d="M27 15l17 17-17 17" />
    </svg>
  );
}

export default ArrowRightIcon;
