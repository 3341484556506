import { Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewProps } from "./type";
import Menu from "./Menu";
import ListViewItem from "./ListViewItem";
import ListViewAddItem from "./ListViewAddItem";
import { AdminUserType } from "../../../infrastructure/enums";
import { UUID } from "../../../infrastructure/types";
import Utils from "../../../infrastructure/utils";

function ListView(props: ListViewProps) {
  const { adminUsers, onAddClicked, onChildClicked, onSearchTyped, onAdminUserTypeChanged, triggerIsLoading } = props;

  const handleSearchTyped = (searchText: string) => {
    onSearchTyped(searchText);
  };

  const handleAdminUserType = (adminUserType: AdminUserType) => {
    onAdminUserTypeChanged(adminUserType);
  };

  const handleListViewChildClicked = (recordID: UUID) => {
    Utils.scrollToTop();

    onChildClicked(recordID);
  };

  const listViewItems = adminUsers.map((adminUser) => <ListViewItem key={adminUser.id} adminUser={adminUser} onClicked={handleListViewChildClicked} />);
  listViewItems.unshift(<ListViewAddItem key={"lol"} onClicked={onAddClicked} />);

  let usedComponent: any = <div>Loading ...</div>;
  if (!triggerIsLoading) usedComponent = listViewItems;

  return (
    <Container fluid>
      <Row className={`${styles.container} g-4`}>
        <Menu onAdminUserTypeChanged={handleAdminUserType} onSearchTyped={handleSearchTyped} />
      </Row>

      <Row xs={1} md={2} className={`${styles.container} g-4`}>
        {usedComponent}
      </Row>
    </Container>
  );
}

export default ListView;
