import styles from "./component.module.css";

type SVGRendererProps = {
  svgString: string;
};

function SVGRenderer(props: SVGRendererProps) {
  const { svgString } = props;
  if (!svgString) return <></>;

  return <div className={styles.pushLeft1} dangerouslySetInnerHTML={{ __html: svgString }} />;
}

export default SVGRenderer;
