import { Button, Col, Container, Form, Row } from "react-bootstrap";
import styles from "./component.module.css";
import sharedStyles from "../../..//component.shared.module.css";
import { MenuProps } from "../type";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import UnsavedChangesModal from "../../../UnsavedChangesModal/UnsavedChangesModal";
import { useState } from "react";
import BackIcon from "../../../Icons/BackIcon";
import DoneIcon from "../../../Icons/DoneIcon";

type CompValues = {
  showUnsavedChangesModal: boolean;
};

function Menu(props: MenuProps) {
  const { translate } = TranslationHandler();
  const [compValues, setCompValues] = useState<CompValues>({ showUnsavedChangesModal: false });

  const handleSaveOnClick = (event: any) => {
    event.preventDefault();
    props.onSaveClicked();
  };

  const handleOnAbortClick = () => {
    if (props.disableSave) {
      setCompValues({ showUnsavedChangesModal: true });
      return;
    }

    props.onAbortClicked();
  };

  const handleUnsavedChangesOnAbortClicked = () => {
    setCompValues({ showUnsavedChangesModal: false });
  };

  const handleUnsavedChangesOnDiscardClicked = () => {
    setCompValues({ showUnsavedChangesModal: false });
    props.onAbortClicked();
  };

  return (
    <>
      <UnsavedChangesModal
        handleAborted={() => handleUnsavedChangesOnAbortClicked()}
        handleDiscard={() => handleUnsavedChangesOnDiscardClicked()}
        showModal={compValues.showUnsavedChangesModal}
        key={"UnsavedChangesModal"}
      />

      <Container fluid className={sharedStyles.menu}>
        <Row className="align-items-center">
          <Col xs={6}>
            <Button size="sm" type="submit" variant="outline-dark" onClick={() => handleOnAbortClick()}>
              <BackIcon className={styles.backBtn} />
              {translate("topmenu_btn_back")}
            </Button>
          </Col>
          <Col>
            <Button
              size="sm"
              disabled={!props.disableSave}
              type="button"
              variant="outline-dark"
              className={`${styles.spaceLeft} float-end`}
              onClick={handleSaveOnClick}
            >
              <DoneIcon className={styles.doneIcon} />
              {translate("topmenu_btn_save")}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Menu;
