import styles from "./component.module.css";

import { toast, ToastContainer, ToastOptions } from "react-toastify";
import { UUID } from "../../../infrastructure/types";
import { Col, Container, Row } from "react-bootstrap";

export const toastOptions: ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function ToastContainerFactory() {
  return (
    <ToastContainer
      position="top-center"
      hideProgressBar={false}
      newestOnTop={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      className={styles.up}
      theme="dark"
    />
  );
}

function copyTextToUserCache(textToCopy: string) {
  const tempTextArea = document.createElement("textarea");
  tempTextArea.value = textToCopy;

  document.body.appendChild(tempTextArea);
  tempTextArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    // supress
  } finally {
    document.body.removeChild(tempTextArea);
  }
}

const toastWithCorrelationID = (message: string, correlationID: UUID) => (
  <Container fluid onClick={() => copyTextToUserCache(correlationID)}>
    <Row className={styles.message}>
      <Col>
        <p>{message}</p>
      </Col>
    </Row>
    <Row>
      <Col className={styles.corrID}>ErrorID: {correlationID}</Col>
    </Row>
  </Container>
);

export function toastErrorWithCorrelationID(message: string, correlationID: string, durationInSec?: number): void {
  const usedToastOptions: ToastOptions = { ...toastOptions };
  if (durationInSec) usedToastOptions.autoClose = durationInSec * 1000;

  toast.error(toastWithCorrelationID(message, correlationID)), usedToastOptions;
}

export function toastError(message: string, durationInSec?: number): void {
  const usedToastOptions: ToastOptions = { ...toastOptions };
  if (durationInSec) usedToastOptions.autoClose = durationInSec * 1000;

  toast.error(message, usedToastOptions);
}

export function toastInfo(message: string, durationInSec?: number): void {
  const usedToastOptions: ToastOptions = { ...toastOptions };
  if (durationInSec) usedToastOptions.autoClose = durationInSec * 1000;

  toast.info(message, usedToastOptions);
}

export function toastSuccess(message: string, durationInSec?: number): void {
  const usedToastOptions: ToastOptions = { ...toastOptions };
  if (durationInSec) usedToastOptions.autoClose = durationInSec * 1000;

  toast.success(message, usedToastOptions);
}

export default ToastContainerFactory;
