import { Badge, Card } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewItemProps } from "./type";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import { TranslationAdminUserMap } from "./shared";

function ListViewItem(props: ListViewItemProps) {
  const { adminUser, onClicked } = props;
  const { firstName, email, id, lastName, permissions, type, isActive } = adminUser;
  const { translate } = TranslationHandler();

  const name = `${lastName} ${firstName}`;
  const permissionsCount = permissions.length;

  const translationIdentifier = TranslationAdminUserMap.get(type) as string;
  const cardStyle = isActive ? styles.card : `${styles.card} ${styles.inActive}`;

  const handleOnClick = () => onClicked(id);

  return (
    <Card key={id} className={cardStyle} onClick={handleOnClick}>
      <Card.Header className={styles.cardHeader}>
        <Badge bg="dark" className={styles.listItemAdminUserTypeBadge}>
          {translate(translationIdentifier)}
        </Badge>

        <h5>{name}</h5>
      </Card.Header>
      <Card.Body>
        <Card.Subtitle>{email}</Card.Subtitle>
        <Card.Text className={styles.pullDown}>{permissionsCount} Rechte</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ListViewItem;
