import { Row } from "react-bootstrap";
import styles from "./component.module.css";

import { useState } from "react";
import { Picture, Translation, UUID } from "../../../../infrastructure/types";

import ApiClient from "../../../../interface/client/apiClient";
import ImageUploader from "./ImageUploader/ImageUploader";
import PictureCard from "./PictureItem/PictureItem";
import TranslationModal from "./PictureItem/TranslationModal/TranslationModal";

type PictureHandlerProps = {
  pictureIDs: UUID[];

  picturesUploaded: (newPictureIDs: UUID[]) => void;
  pictureRemoved: (newPictureID: UUID) => void;
  pictureFavoritChanged: (newPictureID: UUID) => void;
};

function PictureHandler(props: PictureHandlerProps) {
  const { pictureIDs, pictureRemoved } = props;

  const [mainPictureID, setMainPictureID] = useState<UUID | null>(pictureIDs[0]);

  const handlePictureUploadedIDs = (newPictureIDs: UUID[]) => {
    const { picturesUploaded } = props;
    picturesUploaded(newPictureIDs);
  };

  const handleFavoriteOnClicked = (pictureID: UUID): void => {
    const { pictureFavoritChanged } = props;

    setMainPictureID(pictureID);
    pictureFavoritChanged(pictureID);
  };

  const handleRemoveOnClicked = (pictureID: UUID): void => {
    pictureRemoved(pictureID);
  };

  const buildAddPictureCard = () => {
    return <ImageUploader disabled={false} uploadedPictureIDs={handlePictureUploadedIDs} />;
  };

  const buildPictures = (): JSX.Element[] => {
    const pictureCards: JSX.Element[] = [buildAddPictureCard()];

    for (const pictureID of pictureIDs) {
      const isMainPicture = pictureID === mainPictureID;

      const pictureCard = (
        <PictureCard
          key={pictureID}
          isMainPicture={isMainPicture}
          pictureID={pictureID}
          handleFavoriteOnClicked={handleFavoriteOnClicked}
          handleRemoveOnClicked={handleRemoveOnClicked}
        />
      );
      pictureCards.push(pictureCard);
    }

    return pictureCards;
  };

  return (
    <Row className={`${styles.container} g-4`}>
      {/* pictures */}
      {buildPictures()}
    </Row>
  );
}

export default PictureHandler;
