import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./component.module.css";
import { Saison } from "../../../../infrastructure/types";
import { TranslationHandler } from "../../../Utils/TranslationProvider";

const dayMap = new Map([
  [1, "day_mon_short"],
  [2, "day_tue_short"],
  [3, "day_wed_short"],
  [4, "day_thu_short"],
  [5, "day_fri_short"],
  [6, "day_sat_short"],
  [0, "day_sun_short"],
]);

type HorizontalCalendarProps = {
  date: Date;
  saison: Pick<Saison, "entries">;
};

type CompValues = {
  date: Date;
};

function HeaderItem(props: HorizontalCalendarProps) {
  const { translate } = TranslationHandler();
  const [compValues, setCompValues] = useState<CompValues>({ date: new Date() });

  useEffect(() => {
    const compValues: CompValues = { date: props.date };

    setCompValues(compValues);
  }, []);

  const checkIsToday = (dateToCompare: Date): boolean => {
    const currentDate = new Date();
    const compDate = new Date(dateToCompare);

    currentDate.setHours(0, 0, 0, 0);
    compDate.setHours(0, 0, 0, 0);

    return compDate.getTime() === currentDate.getTime();
  };

  const hasSeason = (dateToCompare: Date) => {
    dateToCompare.setHours(0, 0, 0, 0);

    let seasonHeaderStyle = null;
    let saisonType = -1;
    for (const saisonEntry of props.saison.entries) {
      saisonEntry.fromDate.setHours(0, 0, 0, 0);
      saisonEntry.toDate.setHours(0, 0, 0, 0);

      const isInSaison = dateToCompare >= saisonEntry.fromDate && dateToCompare <= saisonEntry.toDate;
      if (isInSaison) {
        saisonType = saisonEntry.type;
        break;
      }
    }

    let saisonText = "";
    switch (saisonType) {
      default:
        saisonText = "nüx";
        break;
      case 0:
        saisonText = "peak";
        seasonHeaderStyle = styles.seasonIndicatorPeak;

        break;
      case 1:
        saisonText = "haupt";
        seasonHeaderStyle = styles.seasonIndicatorHight;

        break;
      case 2:
        saisonText = "mittle";
        seasonHeaderStyle = styles.seasonIndicatorMiddle;

        break;
      case 3:
        saisonText = "neben";
        seasonHeaderStyle = styles.seasonIndicatorLow;

        break;
    }

    return { saisonText, seasonHeaderStyle };
  };

  const checkIsInThePast = (dateToCompare: Date): boolean => {
    const currentDate = new Date();
    const compDate = new Date(dateToCompare);

    currentDate.setHours(0, 0, 0, 0);
    compDate.setHours(0, 0, 0, 0);

    return compDate.getTime() < currentDate.getTime();
  };

  const checkIsWeekendDay = (dateToCompare: Date): boolean => {
    return [0, 6].includes(dateToCompare.getDay());
  };

  const highlightBackgroundCollection = [styles.itemHeader];

  const { seasonHeaderStyle } = hasSeason(compValues.date);
  if (seasonHeaderStyle) highlightBackgroundCollection.push(seasonHeaderStyle);

  const isToday = checkIsToday(compValues.date);
  if (isToday) highlightBackgroundCollection.push(styles.today);

  const isInThePast = checkIsInThePast(compValues.date);
  if (isInThePast) highlightBackgroundCollection.push(styles.past);

  const isWeekendDay = checkIsWeekendDay(compValues.date);
  if (isWeekendDay) highlightBackgroundCollection.push(styles.weekend);

  const higlightBackground = highlightBackgroundCollection.join(" ");

  return (
    <>
      <Container className={higlightBackground}>
        <Row>
          <Col className="text-center">{translate(dayMap.get(compValues.date.getDay()) as string)}</Col>
        </Row>
        <Row>
          <Col className="text-center">{compValues.date.getDate()}</Col>
        </Row>
      </Container>
    </>
  );
}

export default HeaderItem;
