import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { SaisonPrice } from "../../../../infrastructure/types";

import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Utils from "../../../../infrastructure/utils";
import FixCosts from "./FixCosts/FixCosts";
import { CLEANING_FEE_DEFAULT_PRICE } from "../../../../infrastructure/consts";

type FormValues = {
  basePrice: string;

  vatPrice: string;
  vatPercent: string;

  peakPercent: string;
  peakPrice: string;

  highPercent: string;
  highPrice: string;

  middlePercent: string;
  middlePrice: string;

  lowPercent: string;
  lowPrice: string;

  fixCostCleaningFeePrice: string;

  totalFixCost: string;
  total: string;
};

type PriceModalProps = {
  showModal: boolean;

  saisonPrice: SaisonPrice;

  handleAssumeSaisonPrice: (saisonPrice: SaisonPrice) => void;
  close: () => void;
};

function buildDefaultFormValues(saisonPrice: SaisonPrice): FormValues {
  const { fixCost, highPercent, lowPercent, middlePercent, peakPercent, vatPrice, basePrice, highPrice, lowPrice, middlePrice, peakPrice, total, vatPercent } =
    saisonPrice;

  const transform = (value: number): string => (value ? value.toFixed(2) : "0.00");

  // we need to define some defaults to ensure that the cleaningfee is from the begining on part of the total amount
  const defaultCleaningFee = fixCost.cleaningFee.price === 0 ? CLEANING_FEE_DEFAULT_PRICE : fixCost.cleaningFee.price;
  const defaultTotal = total;
  const defaultTotalFixCost = defaultCleaningFee;

  const defaultFormValues: FormValues = {
    basePrice: transform(basePrice),
    peakPrice: transform(peakPrice),
    highPrice: transform(highPrice),
    middlePrice: transform(middlePrice),
    lowPrice: transform(lowPrice),
    vatPercent: transform(vatPercent),
    vatPrice: transform(vatPrice),
    peakPercent: transform(peakPercent),
    highPercent: transform(highPercent),
    middlePercent: transform(middlePercent),
    lowPercent: transform(lowPercent),
    fixCostCleaningFeePrice: transform(defaultCleaningFee),
    totalFixCost: transform(defaultTotalFixCost),
    total: transform(defaultTotal),
  };

  return defaultFormValues;
}

function calculatePrice(basePrice: string, percent: string, vatPercent?: string, totalFixCost?: string): string {
  let price = Number(percent) * 0.01 * Number(basePrice) + Number(basePrice);
  if (vatPercent) price += 0.01 * Number(vatPercent) * price;
  if (totalFixCost) price += Number(totalFixCost);

  return Utils.roundNumber(price).toString();
}

function calculateTotal(basePrice: string, vatPrice?: string): string {
  const usedVatPrice = vatPrice ? vatPrice : 0;

  const totalPrice = Number(basePrice) + Number(usedVatPrice);
  return Utils.roundNumber(totalPrice).toString();
}

function calculateVat(basePrice: string, vatPercent?: string): string {
  const vatPrice = 0.01 * Number(vatPercent) * Number(basePrice);

  return Utils.roundNumber(vatPrice).toString();
}

function calculatePercent(basePrice: string, price: string): string {
  const percent = (100 / Number(basePrice)) * Number(price) - 100;
  return Utils.roundNumber(percent).toString();
}

function PriceModal(props: PriceModalProps) {
  const { close, handleAssumeSaisonPrice, showModal, saisonPrice } = props;
  const { translate } = TranslationHandler();

  const [show, setShow] = useState<boolean>(false);

  const defaultFormValues = buildDefaultFormValues(saisonPrice);
  const [formValue, setFormValue] = useState<FormValues>(defaultFormValues);

  const setFields = (data: Partial<FormValues>) => setFormValue({ ...formValue, ...data });

  const handleSaisonPriceUpdate = (): void => {
    const {
      basePrice,
      highPrice,
      lowPrice,
      highPercent,
      lowPercent,
      middlePercent,
      peakPercent,
      middlePrice,
      peakPrice,
      total,
      vatPercent,
      fixCostCleaningFeePrice,
    } = formValue;

    // deep copy
    const updatedSaisonPrice: SaisonPrice = JSON.parse(JSON.stringify(saisonPrice));

    updatedSaisonPrice.basePrice = Number(basePrice);
    updatedSaisonPrice.highPrice = Number(highPrice);
    updatedSaisonPrice.lowPrice = Number(lowPrice);
    updatedSaisonPrice.middlePrice = Number(middlePrice);
    updatedSaisonPrice.peakPrice = Number(peakPrice);
    updatedSaisonPrice.vatPrice = Number(vatPercent);

    updatedSaisonPrice.highPercent = Number(highPercent);
    updatedSaisonPrice.lowPercent = Number(lowPercent);
    updatedSaisonPrice.middlePercent = Number(middlePercent);
    updatedSaisonPrice.peakPercent = Number(peakPercent);

    updatedSaisonPrice.fixCost.cleaningFee.price = Number(fixCostCleaningFeePrice);

    updatedSaisonPrice.total = Number(total);
    updatedSaisonPrice.vatPercent = Number(vatPercent);

    handleAssumeSaisonPrice(updatedSaisonPrice);
    close();
  };

  const calculateTotalFixCost = (fieldName: keyof FormValues, valueStr: string) => {
    let total = 0;
    total += fieldName === "fixCostCleaningFeePrice" ? Number(valueStr) : Number(formValue.fixCostCleaningFeePrice);

    return total.toString();
  };

  const handleInputChange = (fieldName: keyof FormValues, e: any) => {
    const value = e.target.value as string;

    // Check if the entered value is a valid float number
    const isInputAllowed = Utils.onlyNumericsAllowed(value);
    if (!isInputAllowed) return;

    let updateFormValues: Partial<FormValues> = { [fieldName]: value };

    const usedTotalFixCost = calculateTotalFixCost(fieldName, value);

    const hasBaseValuesChanged = ["basePrice", "vatPercent", "fixCostCleaningFeePrice"].includes(fieldName);
    if (hasBaseValuesChanged) {
      const usedBasePrice = fieldName === "basePrice" ? value : formValue.basePrice;
      const usedVatPercent = fieldName === "vatPercent" ? value : formValue.vatPercent;

      updateFormValues.basePrice = usedBasePrice;
      updateFormValues.peakPrice = calculatePrice(usedBasePrice, formValue.peakPercent, usedVatPercent);
      updateFormValues.highPrice = calculatePrice(usedBasePrice, formValue.highPercent, usedVatPercent);
      updateFormValues.middlePrice = calculatePrice(usedBasePrice, formValue.middlePercent, usedVatPercent);
      updateFormValues.lowPrice = calculatePrice(usedBasePrice, formValue.lowPercent, usedVatPercent);

      updateFormValues.vatPrice = calculateVat(usedBasePrice, usedVatPercent);
      updateFormValues.total = calculateTotal(usedBasePrice, updateFormValues.vatPrice);

      updateFormValues.fixCostCleaningFeePrice = usedTotalFixCost;

      setFields(updateFormValues);
      return;
    }

    const hasPriceChanged = fieldName.includes("Price");
    if (hasPriceChanged) {
      const percent = calculatePercent(formValue.basePrice, value);
      const usedFieldName = fieldName.replace("Price", "Percent") as keyof FormValues;

      updateFormValues = { ...updateFormValues, [usedFieldName]: percent };
    }

    const hasPercentChanged = fieldName.includes("Percent");
    if (hasPercentChanged) {
      const price = calculatePrice(formValue.basePrice, value, formValue.vatPercent);
      const usedFieldName = fieldName.replace("Percent", "Price") as keyof FormValues;

      updateFormValues = { ...updateFormValues, [usedFieldName]: price };
    }

    setFields(updateFormValues);
  };

  const handleFixCostHasChanged = (changedValue: "cleaningFee", priceStr: string) => {
    if (changedValue === "cleaningFee") handleInputChange("fixCostCleaningFeePrice", { target: { value: priceStr } });
  };

  useEffect(() => {
    setFormValue(defaultFormValues);
    setShow(showModal);
  }, [showModal, saisonPrice]);

  return (
    <Modal show={show} onHide={close} aria-labelledby="contained-modal-title-vcenter" size="lg" centered keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{translate("attribute_price_modal_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form>
            <Row>
              <Col xs={8}>
                <Form.Group className="mb-3">
                  <b>{translate("apartment_label_base_price")}</b>
                  <InputGroup className="mb-3">
                    <Form.Control className="text-end" type="text" value={formValue.basePrice} onChange={(e) => handleInputChange("basePrice", e)} />
                    <InputGroup.Text>CHF</InputGroup.Text>
                    <Form.Control className="text-end" type="text" value={formValue.vatPercent} onChange={(e) => handleInputChange("vatPercent", e)} />
                    <InputGroup.Text>% {translate("apartment_label_tax")}</InputGroup.Text>
                    <Form.Control className="text-end" type="text" disabled={true} value={formValue.vatPrice} />
                    <InputGroup.Text>CHF</InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <b>{translate("apartment_label_total")}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2>{formValue.total} CHF</h2>
                  </Col>
                </Row>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <b>{translate("saison_type_peak")}</b>
                  <InputGroup className="mb-3">
                    <Form.Control type="text" className="text-end" value={formValue.peakPrice} onChange={(e) => handleInputChange("peakPrice", e)} />
                    <InputGroup.Text>CHF</InputGroup.Text>
                    <Form.Control className="text-end" type="text" value={formValue.peakPercent} onChange={(e) => handleInputChange("peakPercent", e)} />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <b>{translate("saison_type_high")}</b>
                  <InputGroup className="mb-3">
                    <Form.Control className="text-end" type="text" value={formValue.highPrice} onChange={(e) => handleInputChange("highPrice", e)} />
                    <InputGroup.Text>CHF</InputGroup.Text>
                    <Form.Control className="text-end" type="text" value={formValue.highPercent} onChange={(e) => handleInputChange("highPercent", e)} />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <b>{translate("saison_type_middle")}</b>
                  <InputGroup className="mb-3">
                    <Form.Control className="text-end" type="text" value={formValue.middlePrice} onChange={(e) => handleInputChange("middlePrice", e)} />
                    <InputGroup.Text>CHF</InputGroup.Text>
                    <Form.Control className="text-end" type="text" value={formValue.middlePercent} onChange={(e) => handleInputChange("middlePercent", e)} />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <b>{translate("saison_type_low")}</b>
                  <InputGroup className="mb-3">
                    <Form.Control className="text-end" type="text" value={formValue.lowPrice} onChange={(e) => handleInputChange("lowPrice", e)} />
                    <InputGroup.Text>CHF</InputGroup.Text>
                    <Form.Control className="text-end" type="text" value={formValue.lowPercent} onChange={(e) => handleInputChange("lowPercent", e)} />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <hr />
            <hr />
            {/* --- */}

            <FixCosts hasChanged={handleFixCostHasChanged} defaultValues={{ cleaningFeePrice: saisonPrice.fixCost.cleaningFee.price }} />
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {translate("modal_btn_abort")}
        </Button>
        <Button variant="primary" onClick={handleSaisonPriceUpdate}>
          {translate("modal_btn_ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PriceModal;
