import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { BookingMessage, BookingSummary, UUID, UUIDOrNull } from "../../../../infrastructure/types";

import styles from "./component.module.css";
import { Col, Container, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { Mode } from "../../../../interface/client/enums";
import Utils from "../../../../infrastructure/utils";
import ChildIcon from "../../../Icons/ChildIcon";
import AdultIcon from "../../../Icons/AdultIcon";
import PetIcon from "../../../Icons/PetIcon";
import Messages from "./Messages/Messages";
import { BookingStatus } from "../../../../infrastructure/enums";

const bookingStatusTRKeys = new Map([
  [BookingStatus.AVAILABLE, "booking_state_available"],
  [BookingStatus.RESERVED, "booking_state_reserved"],
  [BookingStatus.CONFIRMED, "booking_state_confirmed"],
  [BookingStatus.CANCELED, "booking_state_canceled"],
  [BookingStatus.COMPLETED, "booking_state_completed"],
  [BookingStatus.BLOCKED, "booking_state_blocked"],
  [BookingStatus.BLOCKED_BY_ADMIN, "booking_state_blocked"],
]);

const bookingStatus = new Map([
  [BookingStatus.AVAILABLE, "Available"],
  [BookingStatus.RESERVED, "Reserved"],
  [BookingStatus.CONFIRMED, "Confirmed"],
  [BookingStatus.CANCELED, "Canceled"],
  [BookingStatus.COMPLETED, "Completed"],
  [BookingStatus.BLOCKED, "Blocked"],
  [BookingStatus.BLOCKED_BY_ADMIN, "Blocked"],
]);

const bookingStatusReverse = new Map([
  ["Available", BookingStatus.AVAILABLE],
  ["Reserved", BookingStatus.RESERVED],
  ["Confirmed", BookingStatus.CONFIRMED],
  ["Canceled", BookingStatus.CANCELED],
  ["Completed", BookingStatus.COMPLETED],
  ["Blocked", BookingStatus.BLOCKED],
  ["Blocked", BookingStatus.BLOCKED_BY_ADMIN],
]);

const bookingStatusPriorities = new Map([
  [BookingStatus.AVAILABLE, [BookingStatus.RESERVED, BookingStatus.BLOCKED, BookingStatus.BLOCKED_BY_ADMIN]],
  [BookingStatus.RESERVED, [BookingStatus.CONFIRMED, BookingStatus.CANCELED]],
  [BookingStatus.CONFIRMED, [BookingStatus.CANCELED, BookingStatus.COMPLETED]],
  [BookingStatus.BLOCKED, [BookingStatus.CANCELED]],
  [BookingStatus.BLOCKED_BY_ADMIN, [BookingStatus.CANCELED]],
  [BookingStatus.CANCELED, []],
  [BookingStatus.COMPLETED, []],
]);

type EditModalProps = {
  showModal: boolean;
  modalTitleTranslationKey: string;
  bookingSummary: BookingSummary | null;
  apartmentID: UUIDOrNull;
  mode: Mode;
  renderForApartmentOwner: boolean;

  onMessageAdded: (bookingID: UUID, bookingMessage: BookingMessage) => void;
  onAssume: (bookingSummary: BookingSummary, apartmentID: UUIDOrNull) => void;
  close: () => void;
};

type FormValues = {
  show: boolean;
  usedBookingSummary: BookingSummary | null;
  mode: Mode;

  status: number;
  adultAmount: number;
  childAmount: number;
  petAmount: number;
  fromDate: Date;
  toDate: Date;

  newMessageText: string;
  messages: BookingMessage[];
};

type RecordData = Pick<FormValues, "status" | "adultAmount" | "childAmount" | "petAmount" | "fromDate" | "toDate">;

function EditModal(props: EditModalProps) {
  const { close, onAssume, bookingSummary, modalTitleTranslationKey, showModal, mode } = props;
  const { translate } = TranslationHandler();

  const defaultFormValues = {
    status: 0,
    adultAmount: 0,
    childAmount: 0,
    petAmount: 0,
    fromDate: new Date(),
    toDate: new Date(),
    mode: Mode.ADD,
    show: showModal,
    usedBookingSummary: bookingSummary,
    newMessageText: "",
    messages: [],
  };

  const [formValues, setFormValues] = useState<FormValues>(defaultFormValues);
  const [recordData, setRecordData] = useState<RecordData>(defaultFormValues);

  const buildRecordDataFromFormValue = (_formData?: FormValues): RecordData => {
    const { status, adultAmount, childAmount, petAmount, fromDate, toDate } = _formData ? _formData : formValues;
    const recordData: RecordData = {
      status,
      adultAmount,
      childAmount,
      petAmount,
      fromDate,
      toDate,
    };

    return recordData;
  };

  const setFormValueField = (fieldName: keyof FormValues, value: any) => {
    setFormValues({ ...formValues, [fieldName]: value });
  };

  const setDateField = (fieldName: "fromDate" | "toDate", value: Date) => {
    const nextDate = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 3);

    setFormValueField(fieldName, nextDate);
  };

  useEffect(() => {
    const currentDate = new Date();

    const usedFormValues: Partial<FormValues> = {
      usedBookingSummary: bookingSummary,
      show: showModal,
      mode,
      fromDate: currentDate,
      adultAmount: 0,
      childAmount: 0,
      petAmount: 0,
      status: 0,
      toDate: currentDate,
    };

    if (props.bookingSummary) {
      const { fromDate, guestInfo, status, toDate, messages } = props.bookingSummary;
      const { adultAmount, childAmount, petAmount } = guestInfo;
      usedFormValues.fromDate = fromDate;
      usedFormValues.adultAmount = adultAmount;
      usedFormValues.childAmount = childAmount;
      usedFormValues.petAmount = petAmount;
      usedFormValues.status = status;
      usedFormValues.toDate = toDate;
      usedFormValues.messages = messages;
    }

    const defaultCompValues = { ...formValues, usedBookingSummary: bookingSummary, show: showModal, mode, ...usedFormValues };
    setFormValues(defaultCompValues);
    setRecordData(buildRecordDataFromFormValue(defaultCompValues));
  }, [showModal, bookingSummary]);

  const handleOnAssume = () => {
    const { adultAmount, childAmount, fromDate, petAmount, status, toDate, usedBookingSummary } = formValues;
    const cache = { ...(usedBookingSummary as BookingSummary) };

    cache.fromDate = fromDate;
    cache.toDate = toDate;
    cache.status = status;
    cache.stayDays = 10;

    if (cache.guestInfo) {
      cache.guestInfo.adultAmount = adultAmount;
      cache.guestInfo.childAmount = childAmount;
      cache.guestInfo.petAmount = petAmount;
    }

    onAssume(cache, props.apartmentID);
  };

  const hasSomethingChanged = (): boolean => {
    const compData = buildRecordDataFromFormValue();
    return Utils.areObjEqual(compData, recordData);
  };

  const buildBookingStatusItem = (key: number, value: string): JSX.Element => {
    return (
      <option key={key} value={value}>
        {translate(bookingStatusTRKeys.get(key) as string)}
      </option>
    );
  };

  const buildBookingStatusItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    for (const [key, value] of bookingStatus.entries()) {
      // exception for apartment owner
      if (props.renderForApartmentOwner) {
        // owner is only allowed to cancel or block
        if (![BookingStatus.CANCELED, BookingStatus.BLOCKED].includes(key)) continue;
      }

      // exception for apartment owner
      if (!props.renderForApartmentOwner) {
        // remove owner blocking state from selection
        if (BookingStatus.BLOCKED == key) continue;
      }

      const item = buildBookingStatusItem(key, value);
      items.push(item);
    }

    return items;
  };

  const buildNumberItems = (startValue: number, amount: number): JSX.Element[] => {
    const items: JSX.Element[] = [];
    for (let loop = startValue; loop < amount; loop++) {
      const item = (
        <option key={loop} value={loop}>
          {loop}
        </option>
      );

      items.push(item);
    }

    return items;
  };

  let title = translate(modalTitleTranslationKey);
  if (mode === Mode.EDIT && bookingSummary) title += `:  ${bookingSummary.bookingNumber}`;

  const handleAddMessage = () => {
    const { messages, newMessageText } = formValues;
    if (newMessageText.length <= 3) {
      return;
    }

    const cache = [...messages];

    const newBookingMessage: BookingMessage = {
      sendFromUser: false,
      text: newMessageText,
      timestamp: new Date(),
    };
    cache.push(newBookingMessage);

    setFormValues({ ...formValues, messages: cache, newMessageText: "" });

    props.onMessageAdded(props.bookingSummary?.bookingID as UUID, newBookingMessage);
  };

  return (
    <Modal show={formValues.show} onHide={close} size="lg" keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs id="overview" className="mb-3">
          <Tab key={"overview"} eventKey={"overview"} title={translate("booking_modal_tab_overview")}>
            <Container fluid>
              <Row className={styles.pushDown25em}>
                <Col xs={1} className={styles.pullDown04em}>
                  <b>{translate("booking_modal_status")}</b>
                </Col>
                <Col xs={3}>
                  <Form.Select
                    id={"selectBookingStatus"}
                    value={bookingStatus.get(formValues.status)}
                    onChange={(e) => setFormValueField("status", bookingStatusReverse.get(e.target.value))}
                    key={"selectBookingStatus"}
                  >
                    {buildBookingStatusItems()}
                  </Form.Select>
                </Col>
                <Col xs={1} className={`${styles.pullDown04em} ${styles.pullRight21em}`}>
                  <b>{translate("booking_modal_from")}</b>
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type="date"
                    value={Utils.formatDateForDatePicker(formValues.fromDate)}
                    onChange={(e: any) => setDateField("fromDate", new Date(e.target.value))}
                  />
                </Col>
                <Col xs={1} className={`${styles.pullDown04em} text-center`}>
                  <b>{translate("booking_modal_to")}</b>
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type="date"
                    value={Utils.formatDateForDatePicker(formValues.toDate)}
                    onChange={(e: any) => setDateField("toDate", new Date(e.target.value))}
                  />
                </Col>
              </Row>

              {/* guests */}
              {!props.renderForApartmentOwner && (
                <>
                  <hr />

                  <Row className={styles.pullDown25em}>
                    <Col className={styles.pullDown04em}>
                      <b>{translate("booking_modal_guest")}</b>
                    </Col>
                    <Col xs={1} className={`${styles.pullDown04em} ${styles.icon}`}>
                      <AdultIcon />
                    </Col>
                    <Col>
                      <Form.Select
                        id={"selectAdultAmount"}
                        value={formValues.adultAmount}
                        onChange={(e) => setFormValueField("adultAmount", Number(e.target.value))}
                        key={"selectAdultAmount"}
                      >
                        {buildNumberItems(1, 11)}
                      </Form.Select>
                    </Col>
                    <Col xs={1} className={`${styles.pullDown04em} ${styles.icon}`}>
                      <ChildIcon />
                    </Col>
                    <Col>
                      <Form.Select
                        id={"selectChildAmount"}
                        value={formValues.childAmount}
                        onChange={(e) => setFormValueField("childAmount", Number(e.target.value))}
                        key={"selectChildAmount"}
                      >
                        {buildNumberItems(0, 11)}
                      </Form.Select>
                    </Col>
                    <Col xs={1} className={`${styles.pullDown04em} ${styles.icon}`}>
                      <PetIcon />
                    </Col>
                    <Col>
                      <Form.Select
                        id={"selectPetAmount"}
                        value={formValues.petAmount}
                        onChange={(e) => setFormValueField("petAmount", Number(e.target.value))}
                        key={"selectPetAmount"}
                      >
                        {buildNumberItems(0, 11)}
                      </Form.Select>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </Tab>

          {!props.renderForApartmentOwner && (
            <Tab key={"messages"} eventKey={"messages"} title={translate("booking_modal_tab_message")} disabled={props.mode === Mode.ADD}>
              <Container fluid>
                <Row>
                  <Col>
                    <b>{props.bookingSummary?.userName}</b>
                  </Col>
                </Row>

                <Messages messages={formValues.messages} />

                <Row className={styles.pullDown25em}>
                  <Col>
                    <InputGroup className="mb-3">
                      <Form.Control
                        value={formValues.newMessageText}
                        placeholder={translate("booking_modal_message_write_msg")}
                        onChange={(e) => setFormValueField("newMessageText", e.target.value)}
                      />
                      <Button variant="outline-secondary" id="button-addon2" onClick={() => handleAddMessage()} disabled={formValues.newMessageText.length < 3}>
                        {translate("booking_modal_message_send")}
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {translate("modal_btn_abort")}
        </Button>
        <Button variant="primary" onClick={handleOnAssume} disabled={!hasSomethingChanged()}>
          {translate("modal_btn_ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
