import { Badge, Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import styles from "./component.module.css";
import Menu from "./Menu/Menu";
import { EditViewProps } from "./type";
import { useEffect, useState } from "react";
import ApiClient from "../../../interface/client/apiClient";
import { NameToValue, SaisonPrice, Translation, UUID } from "../../../infrastructure/types";
import { Mode } from "../../../interface/client/enums";
import { toastErrorWithCorrelationID, toastSuccess } from "../../Utils/ToastContainerFactory/ToastContainerFactory";
import DescriptionView from "./Description/Description";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import EditIcon from "../../Icons/EditIcon";
import DataPicker from "./DataPicker/DataPicker";
import AttributeModal from "./AttributeModal/AttributeModal";
import PriceModal from "./Price/PriceModal";
import PriceViewer from "./Price/PriceViewer";
import PictureHandler from "./PictureHandler/PictureHandler";
import Utils from "../../../infrastructure/utils";
import ReadOnlyView from "../ReadOnlyView/ReadOnlyView";
import { CLEANING_FEE_DEFAULT_PRICE } from "../../../infrastructure/consts";

type Modals = {
  attribute?: boolean;
  topAttribute?: boolean;
  price?: boolean;
};

type RecordData = {
  ownerID: UUID;
  isActive: boolean;
  realEstateID: UUID;
  name: string;
  description: Translation;
  attributeIDs: UUID[];
  topAttributeIDs: UUID[];
  allowedNumberOfPeople: number;
  allowedNumberOfPets: number;
  bathRooms: number;
  roomSize: number;
  sleepingPlaces: number;
  saisonPrice: SaisonPrice;
};

type ErrorValues = {
  name?: string;
  ownerID?: string;
  realEstateID?: string;
};

const defaultRecordData: RecordData = {
  isActive: false,
  attributeIDs: [],
  topAttributeIDs: [],
  ownerID: "",
  realEstateID: "",
  description: {},
  name: "",
  allowedNumberOfPeople: 1,
  allowedNumberOfPets: 0,
  roomSize: 10,
  sleepingPlaces: 1,
  bathRooms: 1,

  saisonPrice: {
    highPercent: 0,
    lowPercent: 0,
    middlePercent: 0,
    peakPercent: 0,
    vatPrice: 0,
    basePrice: 0,
    total: 0,
    id: "",
    fixCost: { total: 0, cleaningFee: { id: "", name: {}, price: 0 } },
    highPrice: 0,
    lowPrice: 0,
    middlePrice: 0,
    peakPrice: 0,
    vatPercent: 0,
  },
};

function EditView(props: EditViewProps) {
  const { translate, translateObj } = TranslationHandler();

  const [ownerID, setOwnerID] = useState<UUID>("");
  const [realEstateID, setRealEstateID] = useState<UUID>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<Translation>({});
  const [attributeIDs, setAttributeIDs] = useState<UUID[]>([]);
  const [topAttributeIDs, setTopAttributeIDs] = useState<UUID[]>([]);
  const [allowedNumberOfPeople, setAllowedNumberOfPeople] = useState<number>(1);
  const [allowedNumberOfPets, setAllowedNumberOfPets] = useState<number>(0);
  const [roomSize, setRoomSize] = useState<number>(10);
  const [bathRooms, setBathRooms] = useState<number>(1);
  const [sleepingPlaces, setSleepingPlaces] = useState<number>(1);
  const [saisonPrice, setSaisonPrice] = useState<SaisonPrice>({
    highPercent: 0,
    lowPercent: 0,
    middlePercent: 0,
    peakPercent: 0,
    vatPrice: 0,
    basePrice: 0,
    total: 0,
    id: "",
    fixCost: { total: 0, cleaningFee: { id: "", name: {}, price: CLEANING_FEE_DEFAULT_PRICE } },
    highPrice: 0,
    lowPrice: 0,
    middlePrice: 0,
    peakPrice: 0,
    vatPercent: 0,
  });
  const [pictureIDs, setPictureIDs] = useState<UUID[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [recordData, setRecordData] = useState<RecordData>(defaultRecordData);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [inputErrors, setInputErrors] = useState<ErrorValues>({});

  const [realEstates, setRealEstates] = useState<NameToValue<string>>(new Map());
  const [realEstateOwners, setRealEstateOwners] = useState<NameToValue<string>>(new Map());
  const [attributes, setAttributes] = useState<NameToValue<Translation>>(new Map());

  const [activeTab, setActiveTab] = useState<string | null>(null);

  const [showModal, setShowModal] = useState<Modals>({});

  const buildRecordDataFromFormValue = (_recordData?: RecordData): RecordData => {
    const updatedRecordData: RecordData = {
      isActive: _recordData ? _recordData.isActive : isActive,
      ownerID: _recordData ? _recordData.ownerID : ownerID,
      realEstateID: _recordData ? _recordData.realEstateID : realEstateID,
      name: _recordData ? _recordData.name : name,
      description: _recordData ? _recordData.description : description,
      attributeIDs: _recordData ? _recordData.attributeIDs : attributeIDs,
      topAttributeIDs: _recordData ? _recordData.topAttributeIDs : topAttributeIDs,
      saisonPrice: _recordData ? _recordData.saisonPrice : saisonPrice,
      allowedNumberOfPeople: _recordData ? _recordData.allowedNumberOfPeople : allowedNumberOfPeople,
      allowedNumberOfPets: _recordData ? _recordData.allowedNumberOfPets : allowedNumberOfPets,
      roomSize: _recordData ? _recordData.roomSize : roomSize,
      bathRooms: _recordData ? _recordData.bathRooms : bathRooms,
      sleepingPlaces: _recordData ? _recordData.sleepingPlaces : sleepingPlaces,
    };

    return updatedRecordData;
  };

  const hasError = (field: keyof ErrorValues) => inputErrors[field] != null;

  const validateForm = () => {
    const foundErrors: ErrorValues = {};

    let isNotValid = name.length < 4;
    if (isNotValid) foundErrors.name = translate("apartment_error_name");

    isNotValid = realEstateID === "";
    if (isNotValid) foundErrors.realEstateID = translate("apartment_error_real_eastate");

    isNotValid = ownerID === "";
    if (isNotValid) foundErrors.ownerID = translate("apartment_error_apartment");

    setInputErrors(foundErrors);

    const isValid = Object.keys(foundErrors).length === 0;
    return isValid;
  };

  useEffect(() => {
    const loadData = async () => {
      // real estate
      const [realEstateResponse, realEstateOwnerResponse, attributeResponse] = await Promise.all([
        ApiClient().realEstateGetMany(),
        ApiClient().adminUserGetRealEstateOwner(),
        ApiClient().attributeGetMany(),
      ]);

      if (realEstateResponse.error === null) {
        const realEstateMap: NameToValue<string> = new Map();

        for (const realEstate of realEstateResponse.data) {
          const { id, name } = realEstate;
          realEstateMap.set(id, name);
        }

        setRealEstates(realEstateMap);
      } else {
        toastErrorWithCorrelationID(translate(realEstateResponse.errorTRKey), realEstateResponse.correlationID);
      }

      // realEstate owner
      if (realEstateOwnerResponse.error === null) {
        const realEstateOwnerMap: NameToValue<string> = new Map();

        for (const realEstateOwner of realEstateOwnerResponse.data) {
          const { firstName, id, lastName } = realEstateOwner;
          const fullName = `${firstName} ${lastName}`;

          realEstateOwnerMap.set(id, fullName);
        }

        setRealEstateOwners(realEstateOwnerMap);
      } else {
        toastErrorWithCorrelationID(translate(realEstateOwnerResponse.errorTRKey), realEstateOwnerResponse.correlationID);
      }

      // attributes
      if (attributeResponse.error === null) {
        const attributesMap: NameToValue<Translation> = new Map();

        for (const attribute of attributeResponse.data) {
          const { name, id } = attribute;

          attributesMap.set(id, name);
        }

        setAttributes(attributesMap);
      } else {
        toastErrorWithCorrelationID(translate(attributeResponse.errorTRKey), attributeResponse.correlationID);
      }

      setIsLoading(false);

      if (!props.recordID) return;

      const apartmentResponse = await ApiClient().apartmentGet(props.recordID);
      if (apartmentResponse.error === null) {
        const { attributeCollection, isActive, ownerID, saisonPrice, realEstateID, description, name, pictureIDs } = apartmentResponse.data;
        const { attributeIDs, topAttributeIDs, allowedNumberOfPeople, allowedNumberOfPets, bathRooms, roomSize, sleepingPlaces } = attributeCollection;

        const initialRecordData: RecordData = {
          isActive,
          attributeIDs: attributeIDs,
          topAttributeIDs: topAttributeIDs,
          ownerID: ownerID,
          realEstateID: realEstateID,
          description,
          saisonPrice,
          name,
          allowedNumberOfPeople,
          allowedNumberOfPets,
          bathRooms,
          roomSize,
          sleepingPlaces,
        };

        setAttributeIDs(attributeIDs);
        setTopAttributeIDs(topAttributeIDs);
        setOwnerID(ownerID);
        setRealEstateID(realEstateID);
        setDescription(description);
        setSaisonPrice(saisonPrice);
        setName(name);
        setPictureIDs(pictureIDs);
        setAllowedNumberOfPeople(allowedNumberOfPeople);
        setAllowedNumberOfPets(allowedNumberOfPets);
        setRoomSize(roomSize);
        setSleepingPlaces(sleepingPlaces);
        setBathRooms(bathRooms);
        setIsActive(isActive);

        setRecordData(buildRecordDataFromFormValue(initialRecordData));
      } else {
        toastErrorWithCorrelationID(translate(apartmentResponse.errorTRKey), apartmentResponse.correlationID);
      }
    };

    if (isLoading) loadData();
  });

  const buildAttributeItem = (name: Translation, pos: number): JSX.Element => {
    return (
      <Badge key={pos} className={styles.badge} bg="light">
        {translateObj(name)}
      </Badge>
    );
  };

  const buildAttributeItems = (attributeMap: NameToValue<Translation>, attributeIDsCollection: UUID[]): JSX.Element[] => {
    const badges: JSX.Element[] = [];
    let posCounter = 0;

    for (const [attributeID, name] of attributeMap) {
      const shouldBeAdded = attributeIDsCollection.includes(attributeID);
      if (!shouldBeAdded) continue;

      const checkBoxItem = buildAttributeItem(name, posCounter);
      badges.push(checkBoxItem);

      posCounter++;
    }

    return badges;
  };

  const buildNumberMap = (minAmount: number, maxAmount: number) => {
    const numbers: NameToValue<string> = new Map();
    for (let loop = minAmount; loop <= maxAmount; loop++) numbers.set(loop.toString(), loop.toString());

    return numbers;
  };

  const handlePictureMainIDChanged = async (newMainPictureID: UUID) => {
    const { recordID } = props;
    if (!recordID) return;

    const adjustedPictureIDs = [newMainPictureID];
    for (const pictureID of pictureIDs) {
      if (pictureID == newMainPictureID) continue;
      adjustedPictureIDs.push(pictureID);
    }

    setPictureIDs(adjustedPictureIDs);

    const response = await ApiClient().apartmentUpdate(recordID, adjustedPictureIDs);
    if (response.error !== null) toastErrorWithCorrelationID(translate(response.errorTRKey), response.correlationID);
  };

  const handlePictureIDRemoved = async (pictureIDToRemove: UUID) => {
    const { recordID } = props;
    if (!recordID) return;

    const reducedPictureIDs = pictureIDs.filter((pictureID: UUID) => pictureID !== pictureIDToRemove);
    setPictureIDs(reducedPictureIDs);

    const response = await ApiClient().apartmentUpdate(recordID, reducedPictureIDs);
    if (response.error !== null) toastErrorWithCorrelationID(translate(response.errorTRKey), response.correlationID);
  };

  const handleNewPictureIDsAdded = async (newPictureIDs: UUID[]) => {
    const { recordID } = props;
    if (!recordID) return;

    const updatedPictureIDs = [...new Set([...pictureIDs, ...newPictureIDs])];
    setPictureIDs(updatedPictureIDs);

    const response = await ApiClient().apartmentUpdate(recordID, updatedPictureIDs);
    if (response.error !== null) toastErrorWithCorrelationID(translate(response.errorTRKey), response.correlationID);
  };

  const handleAssumeAttributes = (fieldName: "attributeIDs" | "topAttributeIDs", attributeIDs: UUID[]): void => {
    if (fieldName === "attributeIDs") setAttributeIDs(attributeIDs);
    if (fieldName === "topAttributeIDs") setTopAttributeIDs(attributeIDs);
  };

  const handleShowModal = (modalName: keyof Modals, isActive: boolean, event?: any) => {
    if (event) event.preventDefault();
    setShowModal({ ...showModal, [modalName]: isActive });
  };

  const handleSaveClicked = async () => {
    const isValid = validateForm();
    if (!isValid) return;

    let pictureIDs: UUID[] | null = null;

    switch (props.mode) {
      case Mode.ADD:
        const addResponse = await ApiClient().apartmentCreate(
          ownerID,
          realEstateID,
          name,
          allowedNumberOfPeople,
          allowedNumberOfPets,
          roomSize,
          bathRooms,
          sleepingPlaces,
          description,
          saisonPrice,
          attributeIDs,
          topAttributeIDs,
          pictureIDs
        );
        if (addResponse.error === null) {
          setRecordData(buildRecordDataFromFormValue());
          props.hasNewRealEstateBeenAdded(addResponse.data);
          toastSuccess(translate("apartment_toast_create"), 2);
        } else {
          toastErrorWithCorrelationID(translate(addResponse.errorTRKey), addResponse.correlationID);
        }

        break;
      case Mode.EDIT:
        if (!props.recordID) return;

        const editResponse = await ApiClient().apartmentUpdate(
          props.recordID,
          pictureIDs,
          isActive,
          ownerID,
          realEstateID,
          name,
          allowedNumberOfPeople,
          allowedNumberOfPets,
          roomSize,
          bathRooms,
          sleepingPlaces,
          description,
          saisonPrice,
          attributeIDs,
          topAttributeIDs
        );

        if (editResponse.error === null) {
          setRecordData(buildRecordDataFromFormValue());
          toastSuccess(translate("apartment_toast_update"), 2);
        } else {
          toastErrorWithCorrelationID(translate(editResponse.errorTRKey), editResponse.correlationID);
        }

        break;
    }
  };

  const handleDeletClicked = async () => {
    if (!props.recordID) return;

    const response = await ApiClient().apartmentDelete(props.recordID);
    if (!response.error) {
      toastSuccess(translate("apartment_toast_deleted"), 2);

      props.close();
    }
  };

  const handleOnAbortClicked = () => {
    props.close();
  };

  const handleDescriptionViewOnChanged = (description: Translation) => {
    setDescription(description);
  };

  const handleSetName = (name: string) => {
    setName(name);
    setInputErrors({ ...inputErrors, name: undefined });
  };
  const handleSetRealEstateID = (realEstateID: UUID) => {
    setRealEstateID(realEstateID);
    setInputErrors({ ...inputErrors, realEstateID: undefined });
  };
  const handleSetOwnerID = (ownerID: UUID) => {
    setOwnerID(ownerID);
    setInputErrors({ ...inputErrors, ownerID: undefined });
  };

  const hasSomethingChanged = (): boolean => {
    const compData = buildRecordDataFromFormValue();
    const hasChanged = Utils.areObjEqual(compData, recordData);

    return hasChanged;
  };

  const ActiveHandler = () => {
    const showBtn = props.mode !== Mode.ADD;

    let text = "Wohnung ist inaktiv, zum aktivieren klicken !";
    let btnVariant = "warning";

    if (isActive) {
      text = "Wohnung ist aktiv, zum deaktivieren klicken !";
      btnVariant = "success";
    }

    let usedComp = <></>;
    if (showBtn) {
      usedComp = (
        <Row>
          <Col>
            <Button style={{ width: "100%", marginBottom: "2em", border: "1px solid black" }} variant={btnVariant} onClick={() => setIsActive(!isActive)}>
              {text}
            </Button>
          </Col>
        </Row>
      );
    }

    return usedComp;
  };

  return (
    <Container fluid>
      <AttributeModal
        modalTitleTranslationKey="attribute_modal_title"
        handleAssumeItems={(attrIDs) => handleAssumeAttributes("attributeIDs", attrIDs)}
        showModal={!!showModal.attribute}
        close={() => handleShowModal("attribute", false)}
        attributeMap={attributes}
        attributeIDs={attributeIDs}
      />
      <AttributeModal
        modalTitleTranslationKey="attributeTop_modal_title"
        handleAssumeItems={(attrIDs) => handleAssumeAttributes("topAttributeIDs", attrIDs)}
        showModal={!!showModal.topAttribute}
        close={() => handleShowModal("topAttribute", false)}
        attributeMap={attributes}
        attributeIDs={topAttributeIDs}
      />
      <PriceModal
        handleAssumeSaisonPrice={(updateSaisonPrice: SaisonPrice) => setSaisonPrice(updateSaisonPrice)}
        showModal={!!showModal.price}
        close={() => handleShowModal("price", false)}
        saisonPrice={saisonPrice}
      />

      <Row className={`${styles.menu} g-4`}>
        <Menu
          mode={props.mode}
          onDeleteClicked={handleDeletClicked}
          onSaveClicked={handleSaveClicked}
          onAbortClicked={handleOnAbortClicked}
          disableSave={hasSomethingChanged()}
        />
      </Row>
      <Tabs id="descriptonView" className="mb-3" onSelect={(activeTab) => setActiveTab(activeTab)}>
        <Tab key="content" eventKey="content" className={`${styles.tab}`} title={translate("attribute_tab_desciption")}>
          <Row className={`${styles.container} g-4`}>
            <Form>
              <ActiveHandler />
              <Row className={styles.content}>
                <Col>
                  <Form.Group className="mb-3">
                    <b>{translate("label_name")}</b>
                    <Form.Control id="name" type="text" value={name} isInvalid={hasError("name")} onChange={(e) => handleSetName(e.target.value)} />
                    <Form.Control.Feedback type="invalid">{inputErrors.name}</Form.Control.Feedback>
                  </Form.Group>

                  <Col>
                    <Row>
                      {/*realEstate pick*/}
                      <DataPicker
                        itemContentMap={realEstates}
                        onChanged={(value) => handleSetRealEstateID(value)}
                        topItemID={realEstateID}
                        translationKey="label_realEstate"
                        errorText={inputErrors.realEstateID}
                      />

                      {/*owner pick*/}
                      <DataPicker
                        itemContentMap={realEstateOwners}
                        onChanged={(value) => handleSetOwnerID(value)}
                        topItemID={ownerID}
                        translationKey="label_owner"
                        errorText={inputErrors.ownerID}
                      />
                    </Row>
                  </Col>

                  {/*description*/}
                  {description && (
                    <>
                      <b>{translate("label_description")}</b>
                      <DescriptionView description={description} onChanged={handleDescriptionViewOnChanged} />
                    </>
                  )}
                </Col>
                <Col xs={4}>
                  <>
                    {/*Price*/}
                    <Col>
                      <b>{translate("label_prices")}</b>
                      <Button className={styles.btn} size="sm" type="submit" variant="outline-white" onClick={(e) => handleShowModal("price", true, e)}>
                        <EditIcon />
                      </Button>
                      <PriceViewer saisonPrice={saisonPrice} />
                    </Col>

                    {/* start ATTRIBUTES */}
                    <Row style={{ paddingTop: "2em" }}>
                      <Col style={{ paddingBottom: "1em" }}>
                        <b>{translate("label_fix_attributes")}</b>
                      </Col>

                      {/* static attr. room size */}
                      <Row>
                        <Col xs={3}>{translate("apartment_label_size")} &#13217; </Col>
                        <DataPicker
                          itemContentMap={buildNumberMap(10, 150)}
                          onChanged={(value) => setRoomSize(Number(value))}
                          topItemID={roomSize.toString()}
                        />
                      </Row>

                      {/* static attr. number of people */}
                      <Row>
                        <Col xs={3}>{translate("apartment_label_allowed_number_of_people")}</Col>
                        <DataPicker
                          itemContentMap={buildNumberMap(1, 10)}
                          onChanged={(value) => setAllowedNumberOfPeople(Number(value))}
                          topItemID={allowedNumberOfPeople.toString()}
                        />
                      </Row>

                      {/* static attr. number of pets */}
                      <Row>
                        <Col xs={3}>{translate("apartment_label_allowed_number_of_pets")}</Col>
                        <DataPicker
                          itemContentMap={buildNumberMap(0, 5)}
                          onChanged={(value) => setAllowedNumberOfPets(Number(value))}
                          topItemID={allowedNumberOfPets.toString()}
                        />
                      </Row>

                      {/* static attr. sleeping palces */}
                      <Row>
                        <Col xs={3}>{translate("apartment_label_sleeping_places")}</Col>
                        <DataPicker
                          itemContentMap={buildNumberMap(1, 5)}
                          onChanged={(value) => setSleepingPlaces(Number(value))}
                          topItemID={sleepingPlaces.toString()}
                        />
                      </Row>

                      {/* static attr. bath rooms */}
                      <Row>
                        <Col xs={3}>{translate("apartment_label_bath_rooms")}</Col>
                        <DataPicker itemContentMap={buildNumberMap(1, 3)} onChanged={(value) => setBathRooms(Number(value))} topItemID={bathRooms.toString()} />
                      </Row>

                      {/*Top Attributes*/}
                      <Col style={{ paddingBottom: "1em" }}>
                        <b>{translate("label_attributes")}</b>
                      </Col>

                      <Row>
                        <Col xs={3}>
                          Favoriten
                          <Button
                            className={styles.btn}
                            size="sm"
                            type="submit"
                            variant="outline-white"
                            onClick={(e) => handleShowModal("topAttribute", true, e)}
                          >
                            <EditIcon />
                          </Button>
                        </Col>
                        <Col>{buildAttributeItems(attributes, topAttributeIDs)}</Col>
                      </Row>
                    </Row>

                    {/*Attributes*/}
                    <Row style={{ paddingTop: "1em" }}>
                      <Col xs={3}>
                        Standart
                        <Button className={styles.btn} size="sm" type="submit" variant="outline-white" onClick={(e) => handleShowModal("attribute", true, e)}>
                          <EditIcon />
                        </Button>
                      </Col>
                      <Col style={{ paddingLeft: "0.2em" }}>{buildAttributeItems(attributes, attributeIDs)}</Col>
                    </Row>
                  </>
                </Col>
              </Row>
            </Form>
          </Row>
        </Tab>
        <Tab disabled={props.mode === Mode.ADD} key="pictures" eventKey="pictures" className={`${styles.tab}`} title={translate("attribute_tab_pictures")}>
          {activeTab === "pictures" && (
            <PictureHandler
              pictureIDs={pictureIDs}
              picturesUploaded={handleNewPictureIDsAdded}
              pictureRemoved={handlePictureIDRemoved}
              pictureFavoritChanged={handlePictureMainIDChanged}
            />
          )}
        </Tab>
        <Tab disabled={props.mode === Mode.ADD} key="preview" eventKey="preview" className={`${styles.tab}`} title={translate("attribute_tab_preview")}>
          {activeTab === "preview" && <ReadOnlyView recordID={props.recordID} onAbortClicked={() => {}} />}
        </Tab>
      </Tabs>
    </Container>
  );
}

export default EditView;
