import { Card } from "react-bootstrap";
import styles from "./component.module.css";
import { HistoryItemProps } from "./type";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import Utils from "../../../infrastructure/utils";
import { News } from "../../../infrastructure/types";
import { useEffect, useState } from "react";

type FormValues = {
  news: News;
};

function buildDateText(publishedAt: Date): string {
  const currentDate = new Date();
  const usedDate = new Date(publishedAt);

  const liesInThePast = currentDate.getTime() - usedDate.getTime();
  let publishedAtText = liesInThePast ? "Veröffentlicht am: " : "Geplante veröffentlichung: ";
  publishedAtText += Utils.formatDate(usedDate);

  return publishedAtText;
}

function ListViewItem(props: HistoryItemProps) {
  const { translate, translateObj } = TranslationHandler();
  const { onClicked, news } = props;

  const [formValues, setFormValues] = useState<FormValues>({ news });

  let publishedAtText = buildDateText(formValues.news.publishAt);
  let activeHighlight = null;

  if (!formValues.news.active) {
    activeHighlight = styles.cardScheduled;
    publishedAtText = translate("label_not_active");
  }

  useEffect(() => {
    setFormValues({ news });
  }, [news]);

  return (
    <Card className={`${styles.card} ${activeHighlight}`} onClick={() => onClicked(news)}>
      <Card.Header className={styles.cardHeader}>
        <h5>{translateObj(formValues.news.title)}</h5>
        {publishedAtText}
      </Card.Header>
      <Card.Body>{translateObj(formValues.news.content)}</Card.Body>
    </Card>
  );
}

export default ListViewItem;
