import { Badge, Button, Col, Container, Form, OverlayTrigger, Popover, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import styles from "./component.module.css";
import Menu from "./Menu/Menu";
import { EditViewProps } from "./type";
import { useEffect, useState } from "react";
import ApiClient from "../../../interface/client/apiClient";
import { AdminUserInvitation, NameToValue, Translation, UUIDOrNull } from "../../../infrastructure/types";
import { Mode } from "../../../interface/client/enums";
import { toastError, toastErrorWithCorrelationID, toastInfo, toastSuccess } from "../../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import AdminTypeSelecter from "../AdminTypeSelecter/AdminTypeSelecter";
import ItemSelecterModal from "./ItemSelecterModal/ItemSelecterModal";
import EditIcon from "./EditIcon";
import Utils from "../../../infrastructure/utils";
import { Locale } from "../../../infrastructure/enums";
import LocalePicker from "../../Utils/LocalePicker/LocalePicker";
import EmailSendIcon from "../../Icons/EmailSendIcon";
import SendInvitationModal from "./SendInvitationModal/SendInvitationModal";
import ProcessLogView from "./ProcessLog/ProcessLogView";

type FormValues = {
  isActive: boolean;
  email: string;
  firstName: string;
  lastName: string;
  type: number;
  locale: Locale;
  permissions: string[];

  isLoading: boolean;
  recordID: UUIDOrNull;
  invited: AdminUserInvitation;
  showSendInvitationEmailModal: boolean;
  permissionCollection: NameToValue<Translation>;
};

type RecordData = Pick<FormValues, "isActive" | "email" | "firstName" | "lastName" | "type" | "locale" | "permissions">;

type ErrorValues = {
  email?: string;
  firstName?: string;
  lastName?: string;
  type?: string;
};

type ActiveModals = {
  permissions: boolean;
};

function EditView(props: EditViewProps) {
  const { translate } = TranslationHandler();

  const defaultFormValues: FormValues = {
    recordID: null,
    isActive: true,
    isLoading: true,
    email: "",
    firstName: "",
    lastName: "",
    locale: Locale.enGB,
    type: 2,
    permissions: [],
    permissionCollection: new Map(),
    showSendInvitationEmailModal: false,
    invited: { acceptedAt: null, at: null },
  };

  const [formValue, setFormValue] = useState<FormValues>(defaultFormValues);
  const [recordData, setRecordData] = useState<RecordData>(defaultFormValues);
  const [inputError, setInputErrors] = useState<ErrorValues>({});

  const [activeModal, setActiveShowModal] = useState<ActiveModals>({ permissions: false });

  const setShowModal = (fieldName: keyof ActiveModals, value: boolean): void => setActiveShowModal({ ...activeModal, [fieldName]: value });
  const setField = (fieldName: keyof FormValues, value: any) => {
    setFormValue({ ...formValue, [fieldName]: value });

    // remove entry from InputErros, if exists
    const isErrorFieldAvailable = fieldName in inputError;
    if (isErrorFieldAvailable) setInputErrors({ ...inputError, [fieldName]: null });
  };
  const hasError = (fieldName: keyof ErrorValues) => inputError[fieldName] != null;

  const buildRecordDataFromFormValue = (_formData?: FormValues): RecordData => {
    const { email, type, firstName, isActive, lastName, locale, permissions } = _formData ? _formData : formValue;
    const recordData: RecordData = {
      email,
      type,
      firstName,
      isActive,
      lastName,
      locale,
      permissions,
    };

    return recordData;
  };

  const validateForm = () => {
    const foundErrors: ErrorValues = {};

    let isNotValid = formValue.firstName.length < 2;
    if (isNotValid) foundErrors.firstName = translate("realestate_error_name");

    isNotValid = formValue.lastName.length < 2;
    if (isNotValid) foundErrors.lastName = translate("realestate_error_name");

    isNotValid = !Utils.isValidEmail(formValue.email);
    if (isNotValid) foundErrors.email = translate("realestate_error_name");

    isNotValid = formValue.type < 1;
    if (isNotValid) foundErrors.type = translate("realestate_error_name");

    setInputErrors(foundErrors);

    const isValid = Object.keys(foundErrors).length === 0;
    return isValid;
  };

  useEffect(() => {
    const loadData = async () => {
      if (!formValue.isLoading) return;

      const permissionItemMap: NameToValue<Translation> = new Map();
      const defaultFormValues: FormValues = {
        recordID: "",
        email: "",
        firstName: "",
        lastName: "",
        permissions: [],
        type: 2,
        locale: Locale.enGB,
        isActive: true,
        isLoading: true,
        permissionCollection: permissionItemMap,
        showSendInvitationEmailModal: false,
        invited: { acceptedAt: null, at: null },
      };

      // permissions
      const permissionResponse = await ApiClient().permissionGetMany();
      if (permissionResponse.error === null) {
        for (const permission of permissionResponse.data) {
          const { name } = permission;
          const translation: Translation = { all: name };
          permissionItemMap.set(name, translation);
        }
      } else {
        toastErrorWithCorrelationID(translate(permissionResponse.errorTRKey), permissionResponse.correlationID);
      }

      const { recordID } = props;
      if (!recordID) {
        setFormValue({ ...defaultFormValues, isLoading: false });
        setRecordData(buildRecordDataFromFormValue());
        return;
      }

      const responseGetAdminUser = await ApiClient().adminUserGet(recordID);
      if (responseGetAdminUser.error === null) {
        const { email, firstName, lastName, permissions, type, locale, isActive, invited } = responseGetAdminUser.data;

        defaultFormValues.recordID = recordID;
        defaultFormValues.email = email;
        defaultFormValues.firstName = firstName;
        defaultFormValues.lastName = lastName;
        defaultFormValues.permissions = permissions;
        defaultFormValues.type = type;
        defaultFormValues.isActive = isActive;
        defaultFormValues.locale = locale;
        defaultFormValues.invited = invited;

        setFormValue({ ...defaultFormValues, isLoading: false });
        setRecordData(buildRecordDataFromFormValue(defaultFormValues));
      } else {
        toastErrorWithCorrelationID(translate(responseGetAdminUser.errorTRKey), responseGetAdminUser.correlationID);
      }
    };

    if (formValue.isLoading) loadData();
  });

  const handleSaveClicked = async () => {
    const isValid = validateForm();
    if (!isValid) return;

    const { isActive, email, firstName, lastName, type, permissions, locale } = formValue;
    switch (props.mode) {
      case Mode.ADD:
        const createResponse = await ApiClient().adminUserCreate(email, firstName, lastName, type, locale);
        if (createResponse.error == null) {
          setField("recordID", createResponse.data.id);
          setRecordData(buildRecordDataFromFormValue());

          toastInfo(translate("adminUser_toast_create"), 2);
        } else {
          toastErrorWithCorrelationID(translate(createResponse.errorTRKey), createResponse.correlationID);
        }

        break;
      case Mode.EDIT:
        if (!props.recordID) return;

        const updateResponse = await ApiClient().adminUserUpdate(props.recordID, isActive, firstName, lastName, type, permissions, locale);
        if (updateResponse.error === null) {
          setRecordData(buildRecordDataFromFormValue());

          toastSuccess(translate("adminUser_toast_update"), 2);
        } else {
          toastErrorWithCorrelationID(translate(updateResponse.errorTRKey), updateResponse.correlationID);
        }
        break;
    }
  };

  const handleAdminUserTypeOnChange = (adminUserType: number) => {
    setField("type", adminUserType);
  };

  const handleOnAbortClicked = () => {
    props.close();
  };

  const buildPermissionBadge = (name: string, pos: number): JSX.Element => {
    return (
      <Badge key={pos} className={styles.badge} bg="light">
        {name}
      </Badge>
    );
  };

  const buildPermissionBadges = (): JSX.Element[] => {
    const { permissions } = formValue;
    const badges: JSX.Element[] = [];
    let posCounter = 0;

    for (const permission of permissions) {
      const checkBoxItem = buildPermissionBadge(permission, posCounter);
      badges.push(checkBoxItem);

      posCounter++;
    }

    return badges;
  };

  const handleShowPermissionModal = (event: any): void => {
    event.preventDefault();
    setShowModal("permissions", true);
  };

  const sendInvitationInfo = () => {
    const { invited } = formValue;

    const invitedAt = invited.at ? Utils.formatDate(invited.at.time) : translate("adminUser_popover_invitation_not_send_yet");
    const acceptedAt = invited.acceptedAt ? Utils.formatDate(invited.acceptedAt) : translate("adminUser_popover_invitation_not_accepted_yet");

    return (
      <Popover id={`popover-invitation`} style={{ width: "50em" }}>
        <Popover.Header>{translate("adminUser_popover_invitation_title")}</Popover.Header>
        <Popover.Body>
          <Container>
            <Row>
              <Col>
                <strong>{translate("adminUser_popover_invitation_send")}</strong>
              </Col>
            </Row>
            <Row>
              <Col>{invitedAt}</Col>
            </Row>
            {invited.acceptedAt && (
              <>
                <Row>
                  <Col>
                    <strong>{translate("adminUser_popover_invitation_accepted")}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col>{acceptedAt}</Col>
                </Row>
              </>
            )}
          </Container>
        </Popover.Body>
      </Popover>
    );
  };

  const handleSendInvitation = async () => {
    const { recordID } = formValue;

    const response = await ApiClient().adminUserSendInvitation(recordID);
    if (response.error === null) {
      toastSuccess(translate("adminUser_toast_success_invitation"));
      setField("showSendInvitationEmailModal", false);
    } else {
      toastError(translate(response.errorTRKey));
    }
  };

  const hasSomethingChanged = (): boolean => {
    const compData = buildRecordDataFromFormValue();
    return Utils.areObjEqual(compData, recordData);
  };

  return (
    <Container fluid>
      <ItemSelecterModal
        modalTitleTranslationKey="adminUser_permission_modal_title"
        handleAssumeItems={(itemIDs) => setField("permissions", itemIDs)}
        showModal={activeModal.permissions}
        close={() => setShowModal("permissions", false)}
        itemMap={formValue.permissionCollection}
        itemIDs={formValue.permissions}
      />

      <SendInvitationModal
        handleAborted={() => setField("showSendInvitationEmailModal", false)}
        handleDeleteApproved={() => handleSendInvitation()}
        showModal={formValue.showSendInvitationEmailModal}
      />

      <Row className={`${styles.menu} g-4`}>
        <Menu mode={props.mode} onSaveClicked={handleSaveClicked} onAbortClicked={handleOnAbortClicked} disableSave={hasSomethingChanged()} />
      </Row>
      <Tabs defaultActiveKey="content">
        <Tab className={styles.tab} eventKey="content" title={translate("tab_overview")}>
          <Row className={`${styles.container} g-4`}>
            <Col>
              <Form>
                <Row className={styles.spacerBottom07}>
                  <Col className={styles.spacerBottom17} xs={6}>
                    <Row>
                      <Col xs={2}>
                        <b>{translate("label_active")}</b>
                        <Form.Check
                          type="switch"
                          id="activeSwitch"
                          label=""
                          checked={formValue.isActive}
                          onChange={(e) => setField("isActive", e.target.checked)}
                        />
                      </Col>
                      <Col xs={3}>
                        <b>{translate("label_admin_type")}</b>
                        <div className={styles.pullLeft}>
                          <AdminTypeSelecter
                            disableAllSelect
                            preSelectType={formValue.type}
                            onAdminUserTypeChanged={(adminUserType: number) => handleAdminUserTypeOnChange(adminUserType)}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <b>{translate("label_language")}</b>
                        <LocalePicker preSelectLocale={formValue.locale} onLocaleChanged={(changedLocale) => setField("locale", changedLocale)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className={styles.spacerTop10}>
                          <b>{translate("label_firstname")}</b>
                          <Form.Control
                            type="text"
                            value={formValue.firstName}
                            isInvalid={hasError("firstName")}
                            onChange={(e) => setField("firstName", e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">{inputError.firstName}</Form.Control.Feedback>
                        </div>

                        <div className={styles.spacerTop10}>
                          <b>{translate("label_lastname")}</b>
                          <Form.Control
                            type="text"
                            value={formValue.lastName}
                            isInvalid={hasError("lastName")}
                            onChange={(e) => setField("lastName", e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">{inputError.lastName}</Form.Control.Feedback>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Col>
                      <b>{translate("label_email")}</b>
                      <Container style={{ paddingLeft: 0 }}>
                        <Row>
                          <Col>
                            <Form.Control
                              disabled={props.mode === Mode.EDIT}
                              type="text"
                              value={formValue.email}
                              isInvalid={hasError("email")}
                              onChange={(e) => setField("email", e.target.value)}
                              className={`${styles.spacerBottom10} ${formValue.invited.acceptedAt ? styles.invitationAccepted : ""}`}
                            />
                          </Col>
                          <Col xs={1}>
                            <OverlayTrigger key="send-invitation" placement="left" overlay={sendInvitationInfo()}>
                              <Button
                                variant="white"
                                style={{ marginTop: "-0.5em" }}
                                id="button-invite"
                                className="float-end"
                                onClick={() => setField("showSendInvitationEmailModal", true)}
                              >
                                <EmailSendIcon />
                              </Button>
                            </OverlayTrigger>
                          </Col>
                        </Row>
                      </Container>

                      <Form.Control.Feedback type="invalid">{inputError.email}</Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <b>{translate("label_permissions")}</b>
                          <Button className={styles.btn} size="sm" type="submit" variant="outline-white" onClick={(e) => handleShowPermissionModal(e)}>
                            <EditIcon />
                          </Button>
                          <Row>
                            <Col className={styles.pullUp05}>{buildPermissionBadges()}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Tab>
        <Tab className={styles.tab} eventKey="logs" title={translate("tab_history")}>
          <Row className={`${styles.container} g-4`}>
            <Col>
              <ProcessLogView userID={formValue.recordID} />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default EditView;
