import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Locale } from "../../../../infrastructure/enums";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { Translation } from "../../../../infrastructure/types";
import { Form } from "react-bootstrap";

import styles from "./component.module.css";

type DescriptionProps = {
  description: Translation | undefined;

  onChanged: (description: Translation) => void;
};

const isTranslationUsable = (translation: Translation | undefined): boolean => {
  if (!translation) return false;

  for (const value of Object.values(translation)) if (value?.length !== 0) return true;
  return false;
};

const buildUsableTranslation = (translation: Translation): Translation => {
  const usableTranslation: Translation = {};

  for (const locale of Object.keys(Locale)) usableTranslation[locale] = translation[locale] ?? "";
  return usableTranslation;
};

function Description(props: DescriptionProps) {
  const { description } = props;

  const { translate } = TranslationHandler();

  const [isInit, setIsInit] = useState<boolean>(false);
  const [selectedLocale, setSelectedLocale] = useState<string>(Locale.deDE);
  const [usedDescription, setUsedDescription] = useState<Translation>({});
  const [FormErrors, setInputErrors] = useState<Translation>({});

  const setField = (field: string, event: any) => {
    const updatedDesciption = { ...usedDescription, [field]: event.target.value };

    props.onChanged(updatedDesciption);
    setUsedDescription(updatedDesciption);

    // remove entry from InputErros, if exists
    if (!!FormErrors[field]) setInputErrors({ ...FormErrors, [field]: undefined });
  };
  const hasError = (field: string) => FormErrors[field] != null;

  useEffect(() => {
    if (isInit) return;

    if (isTranslationUsable(description)) {
      setUsedDescription(buildUsableTranslation(description as Translation));
      setIsInit(true);
    }
  }, [description, isInit]);

  const items: JSX.Element[] = [];
  for (const locale of Object.keys(Locale)) {
    if (!description) continue;

    const value = usedDescription[locale] ?? "";

    const item = (
      <Tab key={locale} eventKey={locale} title={translate(locale)}>
        <Form.Group className="mb-3">
          <Form.Control
            className={styles.textArea}
            as="textarea"
            rows={9}
            value={value}
            isInvalid={hasError(selectedLocale)}
            onChange={(e) => setField(selectedLocale, e)}
            id={locale}
          />
          <Form.Control.Feedback type="invalid">{FormErrors.descriptionDeDE}</Form.Control.Feedback>
        </Form.Group>
      </Tab>
    );
    items.push(item);
  }

  return (
    <Tabs id="descriptonView" className="mb-3" activeKey={selectedLocale as string} onSelect={(selected) => setSelectedLocale(selected as string)}>
      {items}
    </Tabs>
  );
}

export default Description;
