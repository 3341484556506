import { I18N } from "./types";

const translations: I18N = {
  default_error: {
    deDE: "Es ist ein unerwarteter Fehler aufgetreten, sollte dieser bestehen bleiben, kontaktiere bitte den Admin.",
    enGB: "An unexpected error occurred, if this persists, please contact the admin.",
    frFR: "Une erreur inattendue s'est produite, si cela persiste, veuillez contacter l'administrateur.",
    itIT: "Si è verificato un errore inaspettato, se ciò persiste, contattare l'amministratore.",
  },

  deDE: {
    deDE: "Deutsch",
    enGB: "German",
    frFR: "Allemand",
    itIT: "Tedesco",
  },
  enGB: {
    deDE: "English",
    enGB: "English",
    frFR: "Anglais",
    itIT: "Inglese",
  },
  itIT: {
    deDE: "Italienisch",
    enGB: "Italy",
    frFR: "Italien",
    itIT: "Italiano",
  },
  frFR: {
    deDE: "Französich",
    enGB: "French",
    frFR: "Français",
    itIT: "Francese",
  },

  // sidemenu
  sideMenu_AdminUser: {
    all: "AdminUser",
  },
  sideMenu_Apartment: {
    deDE: "Wohnungen",
    enGB: "Apartments",
    itIT: "Appartamenti",
    frFR: "Appartements",
  },
  sideMenu_Booking: {
    deDE: "Buchungen",
    enGB: "Bookings",
    frFR: "Réservations",
    itIT: "Prenotazioni",
  },
  sideMenu_news: {
    all: "News",
  },
  sideMenu_RealEstate: {
    deDE: "Immobilien",
    enGB: "Real estate",
    frFR: "Biens immobiliers",
    itIT: "Immobili",
  },
  sideMenu_Saison: {
    all: "Saison",
  },
  sideMenu_Settings: {
    deDE: "Einstellungen",
    enGB: "Settings",
    frFR: "Paramètres",
    itIT: "Impostazioni",
  },

  // components
  component_title_AdminUser: {
    all: "AdminUser",
  },
  component_title_Apartment: {
    deDE: "Wohnungen",
    enGB: "Apartments",
    itIT: "Appartamenti",
    frFR: "Appartements",
  },
  component_title_booking: {
    deDE: "Buchungen",
    enGB: "Bookings",
    frFR: "Réservations",
    itIT: "Prenotazioni",
  },
  component_title_news: {
    all: "News",
  },
  component_title_RealEstate: {
    deDE: "Immobilien",
    enGB: "Real estate",
    frFR: "Biens immobiliers",
    itIT: "Immobili",
  },
  component_title_Saison: {
    all: "Saison",
  },
  component_title_Settings: {
    deDE: "Einstellungen",
    enGB: "Settings",
    frFR: "Paramètres",
    itIT: "Impostazioni",
  },

  // months
  january: {
    deDE: "Januar",
    enGB: "January",
    frFR: "Janvier",
    itIT: "Gennaio",
  },
  february: {
    deDE: "Februar",
    enGB: "February",
    frFR: "février",
    itIT: "febbraio",
  },
  march: {
    deDE: "März",
    enGB: "March",
    frFR: "Mars",
    itIT: "Marzo",
  },
  april: {
    deDE: "April",
    enGB: "April",
    frFR: "Avril",
    itIT: "Aprile",
  },
  may: {
    deDE: "Mai",
    enGB: "May",
    frFR: "Mai",
    itIT: "Maggio",
  },
  june: {
    deDE: "Juni",
    enGB: "June",
    frFR: "Juin",
    itIT: "Giugno",
  },
  july: {
    deDE: "Juli",
    enGB: "July",
    frFR: "Juillet",
    itIT: "Luglio",
  },
  august: {
    deDE: "August",
    enGB: "August",
    frFR: "Août",
    itIT: "Agosto",
  },
  september: {
    deDE: "September",
    enGB: "September",
    frFR: "Septembre",
    itIT: "Settembre",
  },
  october: {
    deDE: "Oktober",
    enGB: "October",
    frFR: "Octobre",
    itIT: "Ottobre",
  },
  november: {
    deDE: "November",
    enGB: "November",
    frFR: "Novembre",
    itIT: "Novembre",
  },
  december: {
    deDE: "Dezember",
    enGB: "December",
    frFR: "Décembre",
    itIT: "Dicembre",
  },

  // days short version
  day_mon_short: {
    deDE: "Mo",
    enGB: "Mon",
    frFR: "Lun",
    itIT: "Lun",
  },
  day_tue_short: {
    deDE: "Di",
    enGB: "Tue",
    frFR: "Mar",
    itIT: "Mar",
  },
  day_wed_short: {
    deDE: "Mi",
    enGB: "Wed",
    frFR: "Mer",
    itIT: "Mer",
  },
  day_thu_short: {
    deDE: "Do",
    enGB: "Thu",
    frFR: "Jeu",
    itIT: "Gio",
  },
  day_fri_short: {
    deDE: "Fr",
    enGB: "Fri",
    frFR: "Ven",
    itIT: "Ven",
  },
  day_sat_short: {
    deDE: "Sa",
    enGB: "Sat",
    frFR: "Sam",
    itIT: "Sab",
  },
  day_sun_short: {
    deDE: "So",
    enGB: "Sun",
    frFR: "Dim",
    itIT: "Dom",
  },

  // settings
  settings_header_subtitle_selection: {
    deDE: "Auswahl oder Hinzufügen von Einstellungen",
    enGB: "Select or Add Settings",
    frFR: "Sélectionner ou Ajouter des Paramètres",
    itIT: "Seleziona o Aggiungi Impostazioni",
  },

  settings_header_subtitle_add: {
    deDE: "Hinzufügen eines neuen Admin-Benutzers",
    enGB: "Adding a new admin user",
    frFR: "Ajout d'un nouvel utilisateur administrateur",
    itIT: "Aggiunta di un nuovo utente amministratore",
  },
  settings_header_subtitle_edit: {
    deDE: "Bearbeiten des Admin-Benutzers",
    enGB: "Editing the admin user",
    frFR: "Modification de l'utilisateur administrateur",
    itIT: "Modifica dell'utente amministratore",
  },

  // menu
  topmenu_btn_new: {
    deDE: "Neu",
    enGB: "New",
    frFR: "Nouveau",
    itIT: "Nuovo",
  },
  topmenu_btn_back: {
    deDE: "Zurück",
    enGB: "Back",
    frFR: "En arrière",
    itIT: "Indietro",
  },
  topmenu_btn_save: {
    deDE: "Speichern",
    enGB: "Save",
    frFR: "Enregistrer",
    itIT: "Salvare",
  },
  topmenu_btn_delete: {
    deDE: "Löschen",
    enGB: "Delete",
    frFR: "Supprimer",
    itIT: "Eliminare",
  },
  topmenu_btn_language: {
    deDE: "Sprache",
    enGB: "Language",
    frFR: "Langue",
    itIT: "Lingua",
  },
  topmenu_btn_logout: {
    deDE: "Abmelden",
    enGB: "Logout",
    frFR: "Déconnexion",
    itIT: "Disconnessione",
  },
  topmenu_label_adminUser_signedIn: {
    deDE: "Angemeldet als",
    enGB: "Signed in as",
    frFR: "Connecté en tant que",
    itIT: "Connesso come",
  },

  menu_search_input: {
    deDE: "Suche...",
    enGB: "Search...",
    frFR: "Cherche...",
    itIT: "Ricerca...",
  },

  // tab
  tab_history: {
    deDE: "Verlauf",
    enGB: "History",
    frFR: "Historique",
    itIT: "Cronologia",
  },
  tab_overview: {
    deDE: "Übersicht",
    enGB: "Overview",
    frFR: "Vue d'ensemble",
    itIT: "Panoramica",
  },

  // label
  lable_title: {
    deDE: "Titel",
    enGB: "Title",
    frFR: "Titre",
    itIT: "Titolo",
  },
  label_news: {
    all: "News",
  },

  label_permissions: {
    deDE: "Berechtigungen",
    enGB: "Permissions",
    frFR: "Permissions",
    itIT: "Autorizzazioni",
  },
  label_email: {
    all: "E-Mail",
  },
  label_firstname: {
    deDE: "Vorname",
    enGB: "First name",
    frFR: "Prénom",
    itIT: "Nome",
  },
  label_lastname: {
    deDE: "Nachname",
    enGB: "Last name",
    frFR: "Nom de famille",
    itIT: "Cognome",
  },
  label_language: {
    deDE: "Sprache",
    enGB: "Language",
    frFR: "Langue",
    itIT: "Lingua",
  },
  label_admin_type: {
    deDE: "Admin-Typ",
    enGB: "Admin Type",
    frFR: "Type d'administrateur",
    itIT: "Tipo di amministratore",
  },
  label_reset_password: {
    deDE: "Passwort zurücksetzen",
    enGB: "Reset password",
    frFR: "Réinitialiser le mot de passe",
    itIT: "Reimposta password",
  },
  label_not_active: {
    deDE: "Nicht aktiv",
    enGB: "Not active",
    frFR: "Non actif",
    itIT: "Non attivo",
  },
  label_active: {
    deDE: "Aktiv",
    enGB: "Active",
    frFR: "Actif",
    itIT: "Attivo",
  },
  label_add: {
    deDE: "Hinzufügen",
    enGB: "Add",
    frFR: "Ajouter",
    itIT: "Aggiungi",
  },
  label_created: {
    deDE: "Erstellt",
    enGB: "Created",
    frFR: "Créé",
    itIT: "Creato",
  },
  label_edited: {
    deDE: "Geänderet",
    enGB: "Changed",
    frFR: "Modifié",
    itIT: "Modificato",
  },
  label_name: {
    deDE: "Name",
    enGB: "Name",
    frFR: "Nom",
    itIT: "Nome",
  },
  label_picture: {
    deDE: "Bild",
    enGB: "Picture",
    frFR: "Image",
    itIT: "Immagine",
  },
  label_pictures: {
    deDE: "Bilder",
    enGB: "Pictures",
    frFR: "Images",
    itIT: "immagini",
  },
  label_realEstate: {
    deDE: "Immobilie",
    enGB: "Real Estate",
    frFR: "immobilière",
    itIT: "immobiliare",
  },
  label_owner: {
    deDE: "Besitzer",
    enGB: "Owner",
    frFR: "Propriétaire",
    itIT: "Proprietario",
  },
  label_description: {
    deDE: "Beschreibung",
    enGB: "Description",
    frFR: "Description",
    itIT: "Descrizione",
  },
  label_topAttributes: {
    deDE: "Top Attribute",
    enGB: "Top Attributes",
    frFR: "Top attribut",
    itIT: "Attributo Top",
  },
  label_attributes: {
    deDE: "Attribute",
    enGB: "Attributes",
    frFR: "Attribut",
    itIT: "Attributo",
  },
  label_fix_attributes: {
    deDE: "Fix Werte",
    enGB: "Fixed values",
    frFR: "Valeurs fixes",
    itIT: "Valori fissi",
  },
  label_fixCosts: {
    deDE: "Fix kosten",
    enGB: "Fixed costs",
    frFR: "Coûts fixes",
    itIT: "Costi fissi",
  },
  label_prices: {
    deDE: "Preise",
    enGB: "Prices",
    frFR: "Prix",
    itIT: "Prezzi",
  },
  label_apartments: {
    deDE: "Wohnungen",
    enGB: "Apartments",
    itIT: "Appartamenti",
    frFR: "Appartements",
  },

  // fix costs
  fix_costs_label_cleaning_fee: { deDE: "Reinigungspauschale", enGB: "Cleaning fee", frFR: "Frais de nettoyage", itIT: "Tariffa di pulizia" },

  // saison
  saison_modal_delete_body: {
    deDE: "Bist du dir sicher, das du die Saison löschen möchtest?",
    enGB: "Are you sure you want to delete the season?",
    frFR: "Es-tu sûr de vouloir supprimer la saison?",
    itIT: "Sei sicuro di voler eliminare la stagione?",
  },
  saison_modal_title_add: {
    deDE: "Hinzufügen einer neuen Saison",
    enGB: "Adding a new season",
    frFR: "Ajouter une nouvelle saison",
    itIT: "Aggiunta di una nuova stagione",
  },
  saison_modal_title_edit: {
    deDE: "Editieren der Saison",
    enGB: "Editing the season",
    frFR: "Édition de la saison",
    itIT: "Modifica della stagione",
  },
  saison_type_peak: {
    deDE: "Peak Saison",
    enGB: "Peak Saison",
    frFR: "Haute saison",
    itIT: "Alta stagione",
  },
  saison_type_high: {
    deDE: "Hoch Saison",
    enGB: "High Saison",
    frFR: "Saison haute",
    itIT: "Alta stagione",
  },
  saison_type_middle: {
    deDE: "Zwischen Saison",
    enGB: "Middle Saison",
    frFR: "Saison intermédiaire",
    itIT: "Stagione intermedia",
  },
  saison_type_low: {
    deDE: "Neben Saison",
    enGB: "Off Saison",
    frFR: "Basse saison",
    itIT: "Bassa stagione",
  },
  saison_type_base: {
    deDE: "Basis Saison",
    enGB: "Base Season",
    frFR: "Saison de base",
    itIT: "Stagione base",
  },
  saison_label_year: {
    deDE: "Jahr",
    enGB: "Year",
    frFR: "Année",
    itIT: "Anno",
  },
  saison_label_entries: {
    deDE: "Einträge",
    enGB: "Entries",
    frFR: "Entrées",
    itIT: "Voci",
  },
  saison_label_from: {
    deDE: "von",
    enGB: "from",
    frFR: "de",
    itIT: "di",
  },
  saison_label_to: {
    deDE: "bis",
    enGB: "until",
    frFR: "jusqu'à",
    itIT: "fino a",
  },

  saison_toast_deleted: {
    deDE: "Saison wurde gelöscht",
    enGB: "Season was deleted",
    frFR: "La saison a été supprimée",
    itIT: "Stagione eliminata",
  },
  saison_toast_created: {
    deDE: "Saison wurde erstellt",
    enGB: "Season was created",
    frFR: "La saison a été créée",
    itIT: "Stagione creata",
  },
  saison_toast_updated: {
    deDE: "Saison wurde aktualisiert",
    enGB: "Season has been updated",
    frFR: "La saison a été mise à jour",
    itIT: "La stagione è stata aggiornata",
  },

  // realestate
  realestate_picture_handler_modal_label_rotate: {
    deDE: "Rotieren",
    enGB: "Rotate",
    frFR: "Tourner",
    itIT: "Ruotare",
  },
  realestate_picture_handler_modal_label_zoom: {
    all: "Zoom",
  },
  realestate_picture_handler_modal_title: {
    deDE: "Passe dein Bild an",
    enGB: "Adjust your image",
    frFR: "Ajustez votre image",
    itIT: "Regola la tua immagine",
  },
  realestate_picture_handler_disabled: {
    deDE: "Du musst zuerst speichern, bevor du ein Bild hochladen kannst!",
    enGB: "You must save first before you can upload an image!",
    frFR: "Vous devez d'abord enregistrer avant de pouvoir télécharger une image !",
    itIT: "Devi prima salvare prima di poter caricare un'immagine!",
  },
  realestate_picture_handler_drag_and_drop: {
    deDE: "Drag & Drop ein Bild, oder klicke, um eines auszuwählen",
    enGB: "Drag & Drop an image, or click to select one",
    frFR: "Glissez-déposez une image ou cliquez pour en sélectionner une",
    itIT: "Trascina e rilascia un'immagine o clicca per selezionarne una",
  },
  realestate_header_subtitle_selection: {
    deDE: "Auswahl oder Hinzufügen einer Immobilie",
    enGB: "Selecting or Adding a Property",
    frFR: "Sélectionner ou Ajouter une Propriété",
    itIT: "Selezione o Aggiunta di un Immobile",
  },
  realestate_header_subtitle_add: {
    deDE: "Hinzufügen einer neuen Immobilie",
    enGB: "Adding a new property",
    frFR: "Ajout d'une nouvelle propriété",
    itIT: "Aggiunta di una nuova proprietà",
  },
  realestate_header_subtitle_edit: {
    deDE: "Bearbeiten der Immobilie",
    enGB: "Edit property",
    frFR: "Modifier la propriété",
    itIT: "Modifica della proprietà",
  },
  realestate_error_name: {
    deDE: "Bitte gib eine Namen ein",
    enGB: "Please enter a name",
    frFR: "Veuillez entrer un nom",
    itIT: "Per favore inserisci un nome",
  },
  realestate_toast_create: {
    deDE: "Immobilie erstellt",
    enGB: "Property created",
    frFR: "Propriété créée",
    itIT: "Proprietà creata",
  },
  realestate_toast_update: {
    deDE: "Immobilie aktualisiert",
    enGB: "Property updated",
    frFR: "Propriété mise à jour",
    itIT: "Proprietà aggiornata",
  },
  realestate_toast_deleted: {
    deDE: "Immobilie gelöscht",
    enGB: "Property deleted",
    frFR: "Propriété supprimée",
    itIT: "Proprietà eliminata",
  },
  realestate_taost_picture_upload_note: {
    deDE: "Die Immobilie muss vorher gespeichert werden, bevor du ein Bild hochladen kannst.",
    enGB: "The property must be saved before you can upload an image.",
    frFR: "La propriété doit être enregistrée avant de pouvoir télécharger une image.",
    itIT: "La proprietà deve essere salvata prima di poter caricare un'immagine.",
  },
  realestate_toast_error_cant_delete: {
    deDE: "Es ist nicht möglich die Immobilie zu löschen, da zuerst noch die verknüpften Wohnungen entfernt werden müssen",
    enGB: "It is not possible to delete the property, as the linked apartments must first be removed.",
    frFR: "Il n'est pas possible de supprimer la propriété, car les appartements liés doivent d'abord être supprimés.",
    itIT: "Non è possibile eliminare l'immobile, poiché prima è necessario rimuovere gli appartamenti collegati.",
  },

  // adminUser
  unsavedChanges_modal_title: {
    deDE: "Nicht gespeicherte Änderungen",
    enGB: "Unsaved changes",
    frFR: "Modifications non enregistrées",
    itIT: "Modifiche non salvate",
  },
  unsavedChanges_modal_content: {
    deDE: "Es gibt nicht gespeicherte Änderungen, möchtest du sie verwerfen ?",
    enGB: "There are unsaved changes, do you want to discard them?",
    frFR: "Il y a des modifications non enregistrées, voulez-vous les ignorer ?",
    itIT: "Ci sono modifiche non salvate, vuoi eliminarle?",
  },
  adminUser_header_subtitle_selection: {
    deDE: "Auswahl oder Hinzufügen eines Admin-Benutzers",
    enGB: "Selecting or Adding an Admin User",
    frFR: "Sélectionner ou Ajouter un Utilisateur Administrateur",
    itIT: "Selezione o Aggiunta di un Utente Amministratore",
  },
  adminUser_header_subtitle_add: {
    deDE: "Hinzufügen eines neuen Admin-Benutzers",
    enGB: "Adding a new admin user",
    frFR: "Ajout d'un nouvel utilisateur administrateur",
    itIT: "Aggiunta di un nuovo utente amministratore",
  },
  adminUser_header_subtitle_edit: {
    deDE: "Bearbeiten des Admin-Benutzers",
    enGB: "Editing the admin user",
    frFR: "Modification de l'utilisateur administrateur",
    itIT: "Modifica dell'utente amministratore",
  },

  adminUser_menu_selectAll: {
    deDE: "Alle",
    enGB: "All",
    frFR: "Tout",
    itIT: "Tutto",
  },
  adminUser_menu_selectSuperAdmin: {
    all: "Super Admin",
  },
  adminUser_menu_selectAdmin: {
    all: "Admin",
  },
  adminUser_menu_selectRealEstateOwner: {
    deDE: "Besitzer",
    enGB: "Owner",
    frFR: "Propriétaire",
    itIT: "Proprietario",
  },
  adminUser_permission_modal_title: {
    deDE: "Änderung von Berechtigungen",
    enGB: "Change of Permissions",
    frFR: "Modification des autorisations",
    itIT: "Modifica delle autorizzazioni",
  },
  adminUser_toast_create: {
    deDE: "Admin User erstellt",
    enGB: "Admin User created",
    frFR: "Admin User créée",
    itIT: "Admin User creata",
  },
  adminUser_toast_update: {
    deDE: "Admin User aktualisiert",
    enGB: "Admin User updated",
    frFR: "Admin User mise à jour",
    itIT: "Admin User aggiornata",
  },
  adminUser_toast_success_invitation: {
    deDE: "Einladung wurde versendet !",
    enGB: "Invitation has been sent!",
    frFR: "L'invitation a été envoyée !",
    itIT: "Invito è stato inviato!",
  },
  adminUser_modal_invite_title: {
    deDE: "Einladen ?",
    enGB: "Invite ?",
    frFR: "Inviter ?",
    itIT: "Invitare ?",
  },
  adminUser_modal_invite_content: {
    deDE: "Bist du dir sicher, dass du die Einladung versenden möchtest ?",
    enGB: "Are you sure you want to send the invitation?",
    frFR: "Es-tu sûr(e) de vouloir envoyer l'invitation ?",
    itIT: "Sei sicuro di voler inviare l'invito?",
  },
  adminUser_popover_invitation_not_send_yet: {
    deDE: "Noch nicht eingeladen",
    enGB: "Not invited yet",
    frFR: "Pas encore invité",
    itIT: "Non ancora invitato",
  },
  adminUser_popover_invitation_not_accepted_yet: {
    deDE: "Noch nicht akzeptiert",
    enGB: "Not yet accepted",
    frFR: "Pas encore accepté",
    itIT: "Non ancora accettato",
  },
  adminUser_popover_invitation_send: {
    deDE: "Gesendet",
    enGB: "Sent",
    frFR: "Envoyé",
    itIT: "Inviato",
  },
  adminUser_popover_invitation_accepted: {
    deDE: "Akzeptiert",
    enGB: "Accepted",
    frFR: "Accepté",
    itIT: "Accettato",
  },
  adminUser_popover_invitation_title: {
    deDE: "Einladung",
    enGB: "Invitation",
    frFR: "Invitation",
    itIT: "Invito",
  },

  // news
  news_modal_delete_body: {
    deDE: "Bist du dir sicher, das du den News Eintrag löschen möchtest ?",
    enGB: "Are you sure you want to delete the news entry?",
    frFR: "Es-tu sûr de vouloir supprimer l'entrée des actualités ?",
    itIT: "Sei sicuro di voler eliminare l'articolo di notizie?",
  },

  news_header_subtitle_selection: {
    deDE: "Auswahl oder Hinzufügen einer News",
    enGB: "Selecting or Adding a News",
    frFR: "Sélectionner ou Ajouter une Actualité",
    itIT: "Seleziona o Aggiungi una Notizia",
  },
  news_header_subtitle_add: {
    deDE: "Hinzufügen eines neuen News Beitrages",
    enGB: "Adding a new news article",
    frFR: "Ajout d'un nouvel article d'actualité",
    itIT: "Aggiunta di un nuovo articolo di notizie",
  },
  news_header_subtitle_edit: {
    deDE: "Bearbeiten der Nachrichten",
    enGB: "Editing the news",
    frFR: "Édition des actualités",
    itIT: "Modifica delle notizie",
  },

  news_toast_delete: {
    deDE: "News Eintrag gelöscht",
    enGB: "News entry deleted",
    frFR: "Entrée de nouvelles supprimée",
    itIT: "Voce di notizie cancellata",
  },
  news_toast_created: {
    deDE: "Neuer News Eintrag erstellt",
    enGB: "New news entry created",
    frFR: "Nouvelle entrée d'actualités créée",
    itIT: "Nuova voce di notizie creata",
  },
  news_toast_updated: {
    deDE: "News Eintrag geupdated",
    enGB: "News entry updated",
    frFR: "Mise à jour de l'entrée des actualités",
    itIT: "Aggiornato l'articolo di notizie",
  },

  news_label_published_on: {
    deDE: "Veröffentlichen am",
    enGB: "Publish on",
    frFR: "Publié le",
    itIT: "Pubblicato il",
  },

  news_error_title: {
    deDE: "Bitte gib einen Titel ein",
    enGB: "Please enter a title",
    frFR: "Veuillez entrer un titre",
    itIT: "Inserisci un titolo, per favore",
  },
  news_error_content: {
    deDE: "Du musst etwas unter News eintragen",
    enGB: "You must enter something under News",
    frFR: "Tu dois entrer quelque chose sous News",
    itIT: "Devi inserire qualcosa sotto News",
  },
  // attribute
  attribute_tab_desciption: {
    deDE: "Beschreibung",
    enGB: "Description",
    frFR: "Description",
    itIT: "Descrizione",
  },
  attribute_tab_preview: {
    deDE: "Vorschau",
    enGB: "Preview",
    frFR: "Aperçu",
    itIT: "Anteprima",
  },
  attribute_tab_pictures: {
    deDE: "Bilder",
    enGB: "Pictures",
    frFR: "Images",
    itIT: "immagini",
  },
  attribute_modal_title: {
    deDE: "Änderung von Attributen",
    enGB: "Modification of attributes",
    frFR: "Modification des attributs",
    itIT: "Modifica degli attributi",
  },
  attributeTop_modal_title: {
    deDE: "Änderung von Top-Attributen",
    enGB: "Modification of top-attributes",
    frFR: "Modification des attributs supérieurs",
    itIT: "Modifica degli attributi principali",
  },
  attribute_price_modal_title: {
    deDE: "Änderung von Preisen",
    enGB: "Price change",
    frFR: "Modification des prix",
    itIT: "Modifica dei prezzi",
  },
  attribute_modal_delete_body: {
    deDE: "Bist du dir sicher, dass du das Attribute löschen möchtest ?",
    enGB: "Are you sure you want to delete the attribute?",
    frFR: "Es-tu sûr de vouloir supprimer l'attribut ?",
    itIT: "Sei sicuro di voler eliminare l'attributo?",
  },
  attribute_error_text_no_name: {
    deDE: "Du musst für mindestens eine Übersetzung einen Text eingeben",
    enGB: "You must enter a text for at least one translation",
    frFR: "Vous devez entrer un texte pour au moins une traduction",
    itIT: "Devi inserire un testo per almeno una traduzione",
  },
  attribute_modal_title_add: {
    deDE: "Hinzufügen eines neuen Attributes",
    enGB: "Adding a new attribute",
    frFR: "Ajout d'un nouvel attribut",
    itIT: "Aggiunta di un nuovo attributo",
  },
  attribute_modal_title_edit: {
    deDE: "Editieren des Attributes",
    enGB: "Editing the Attribute",
    frFR: "Édition de l'attribut",
    itIT: "Modifica dell'attributo",
  },
  attribute_toast_deleted: {
    deDE: "Attribut wurde gelöscht",
    enGB: "Attribute has been deleted",
    frFR: "L'attribut a été supprimé",
    itIT: "L'attributo è stato eliminato",
  },
  attribute_toast_update: {
    deDE: "Attribute wurde aktualisiert",
    enGB: "Attribute has been updated",
    frFR: "L'attribut a été mis à jour",
    itIT: "L'attributo è stato aggiornato",
  },

  attribute_toast_created: {
    deDE: "Attribut wurde erstellt",
    enGB: "Attribute has been created",
    frFR: "L'attribut a été créé",
    itIT: "L'attributo è stato creato",
  },
  // apartment
  partment_picture_handler_modal_done: {
    deDE: "Geschafft, jetzt nur noch uploaden!",
    enGB: "Done, now just upload!",
    frFR: "C'est fait, il ne reste plus qu'à télécharger !",
    itIT: "Fatto, ora basta caricare!",
  },
  apartment_picture_handler_modal_label_rotate: {
    deDE: "Rotieren",
    enGB: "Rotate",
    frFR: "Tourner",
    itIT: "Ruotare",
  },
  apartment_picture_handler_modal_label_zoom: {
    all: "Zoom",
  },
  apartment_picture_handler_modal_title: {
    deDE: "Passe dein Bild an",
    enGB: "Adjust your image",
    frFR: "Ajustez votre image",
    itIT: "Regola la tua immagine",
  },
  apartment_header_subtitle_selection: {
    deDE: "Auswahl oder Hinzufügen einer Wohnung",
    enGB: "Selecting or Adding an Apartment",
    frFR: "Sélectionner ou Ajouter un Appartement",
    itIT: "Seleziona o Aggiungi un Appartamento",
  },
  apartment_header_subtitle_add: {
    deDE: "Hinzufügen einer neuen Wohnung",
    enGB: "Adding a new apartment",
    frFR: "Ajout d'un nouvel appartement",
    itIT: "Aggiunta di un nuovo appartamento",
  },
  apartment_header_subtitle_edit: {
    deDE: "Bearbeiten der Wohnung",
    enGB: "Editing the apartment",
    frFR: "Édition de l'appartement",
    itIT: "Modifica dell'appartamento",
  },
  apartment_header_subtitle_view: {
    deDE: "Betrachtung der Wohnung",
    enGB: "Consideration of the apartment",
    frFR: "Examen de l'appartement",
    itIT: "Esame dell'appartamento",
  },

  apartment_picture_main_picture: {
    deDE: "Hauptbild",
    enGB: "Main Image",
    frFR: "Image principale",
    itIT: "Immagine principale",
  },
  apartment_label_size: {
    deDE: "Größe",
    enGB: "Size",
    frFR: "Taille",
    itIT: "Dimensione",
  },
  apartment_label_allowed_number_of_people: {
    deDE: "Personen",
    enGB: "Persons",
    frFR: "Personnes",
    itIT: "Persone",
  },
  apartment_label_allowed_number_of_pets: {
    deDE: "Haustiere",
    enGB: "Pets",
    frFR: "Animaux de compagnie",
    itIT: "Animali domestici",
  },
  apartment_label_sleeping_places: {
    deDE: "Betten",
    enGB: "Beds",
    frFR: "Lits",
    itIT: "Letti",
  },
  apartment_label_bath_rooms: {
    deDE: "Bäder",
    enGB: "Baths",
    frFR: "Bains",
    itIT: "Bagni",
  },
  apartment_label_equipment: {
    deDE: "Ausstattung",
    enGB: "Equipment",
    frFR: "Équipement",
    itIT: "Attrezzatura",
  },
  apartment_label_more_enquiries: {
    deDE: "Anfragen",
    enGB: "Enquiries",
    frFR: "Demandes",
    itIT: "Richieste",
  },
  apartment_label_more_info: {
    deDE: "Mehr Info",
    enGB: "More info",
    frFR: "Plus d'infos",
    itIT: "Ulteriori informazioni",
  },
  apartment_label_per_night: {
    deDE: "Pro Nacht",
    enGB: "Per night",
    frFR: "Par nuit",
    itIT: "Per notte",
  },
  apartment_label_base_price: {
    deDE: "Basispreis",
    enGB: "Base Price",
    frFR: "Prix de base",
    itIT: "Prezzo base",
  },
  apartment_label_tax: {
    deDE: "Steuer",
    enGB: "tax",
    frFR: "impôt",
    itIT: "tassa",
  },
  apartment_label_total: {
    deDE: "Total",
    enGB: "Total",
    frFR: "Total",
    itIT: "Totale",
  },
  apartment_modal_delete_title: {
    deDE: "Löschen ?",
    enGB: "Delete ?",
    frFR: "Psupprimer ?",
    itIT: "Eliminare ?",
  },
  apartment_modal_delete_body: {
    deDE: "Bist du dir sicher, dass du die Wohnung löschen möchtest?",
    enGB: "Are you sure you want to delete the apartment?",
    frFR: "Es-tu sûr(e) de vouloir supprimer l'appartement ?",
    itIT: "Sei sicuro/a di voler eliminare l'appartamento?",
  },
  apartment_toast_create: {
    deDE: "Wohnung wurde erstellt",
    enGB: "Apartment was created",
    frFR: "L'appartement a été créé",
    itIT: "L'appartamento è stato creato",
  },
  apartment_toast_update: {
    deDE: "Wohnung wurde aktualisiert",
    enGB: "Apartment has been updated",
    frFR: "L'appartement a été mis à jour",
    itIT: "L'appartamento è stato aggiornato",
  },
  apartment_toast_deleted: {
    deDE: "Wohnung wurde gelöscht",
    enGB: "Apartment has been deleted",
    frFR: "L'appartement a été supprimé",
    itIT: "L'appartamento è stato eliminato",
  },
  apartment_error_name: {
    deDE: "Bitte gib eine Namen ein",
    enGB: "Please enter a name",
    frFR: "Veuillez entrer un nom",
    itIT: "Per favore inserisci un nome",
  },
  apartment_error_real_eastate: {
    deDE: "Bitte wähle eine Immobilie aus",
    enGB: "Please choose a property",
    frFR: "Veuillez choisir une propriété",
    itIT: "Per favore, scegli una proprietà",
  },
  apartment_error_apartment: {
    deDE: "Bitte wähle den Besitzer der Wohnung aus",
    enGB: "Please select the owner of the apartment",
    frFR: "Veuillez sélectionner le propriétaire de l'appartement",
    itIT: "Per favore seleziona il proprietario dell'appartamento",
  },
  apartment_picture_translation_modal_title: {
    deDE: "Bearbeitung des Bildes",
    enGB: "Editing of the image",
    frFR: "Traitement de l'image",
    itIT: "Modifica dell'immagine",
  },
  apartment_picture_translation_modal_description: {
    deDE: "Gib eine Beschreibung zu dem Bild ein",
    enGB: "Provide a description for the image",
    frFR: "Fournir une description pour l'image",
    itIT: "Fornisci una descrizione per l'immagine",
  },

  // modal
  modal_btn_discard: {
    deDE: "Verwerfen",
    enGB: "Discard",
    frFR: "Rejeter",
    itIT: "Scartare",
  },
  modal_btn_delete: {
    deDE: "Löschen ?",
    enGB: "Delete ?",
    frFR: "Psupprimer ?",
    itIT: "Eliminare ?",
  },
  modal_btn_abort: {
    deDE: "Abbrechen",
    enGB: "Cancel",
    frFR: "Annuler",
    itIT: "Annulla",
  },
  modal_btn_ok: {
    all: "Ok",
  },
  modal_btn_upload: {
    all: "Upload",
  },
  modal_btn_lets_go: {
    deDE: "Los gehts!",
    enGB: "Here we go!",
    frFR: "C'est parti !",
    itIT: "Ecco cominciamo!",
  },
  modal_label_inActiveAttributes: {
    deDE: "Inaktiv",
    enGB: "Inactive",
    frFR: "Inactif",
    itIT: "Inattivo",
  },
  modal_label_activeAttributes: {
    deDE: "Aktiv",
    enGB: "Active",
    frFR: "Actif",
    itIT: "Attivo",
  },

  realestate_modal_btn_delete_body: {
    deDE: "Bist du dir sicher, das du die Immobilie löschen möchtest ?",
    enGB: "Are you sure you want to delete the property?",
    frFR: "Es-tu sûr(e) de vouloir supprimer la propriété ?",
    itIT: "Sei sicuro/a di voler eliminare la proprietà?",
  },

  // booking
  booking_header_subtitle_selection: {
    deDE: "Auswahl oder Hinzufügen einer Buchung",
    enGB: "Selecting or Adding a Booking",
    frFR: "Sélectionner ou Ajouter une Réservation",
    itIT: "Selezione o Aggiunta di una Prenotazione",
  },
  booking_booking_item_blocked_by: {
    deDE: "Blockiert von",
    enGB: "Blocked by",
    frFR: "Bloqué par",
    itIT: "Bloccato da",
  },
  booking_booking_item_guest: {
    deDE: "Gäste",
    enGB: "Guests",
    frFR: "Invités",
    itIT: "Ospiti",
  },
  booking_booking_item_days: {
    deDE: "Tage",
    enGB: "Days",
    frFR: "Jours",
    itIT: "Giorni",
  },
  booking_modal_tab_overview: {
    deDE: "Übersicht",
    enGB: "Overview",
    frFR: "Aperçu",
    itIT: "Panoramica",
  },
  booking_modal_tab_message: {
    deDE: "Nachrichten",
    enGB: "Messages",
    frFR: "Messages",
    itIT: "Messaggi",
  },
  booking_modal_add: {
    deDE: "Hinzufügen einer neuen Buchung",
    enGB: "Add a new booking",
    frFR: "Ajouter une nouvelle réservation",
    itIT: "Aggiungi una nuova prenotazione",
  },
  booking_modal_edit: {
    deDE: "Bearbeiten der Buchung",
    enGB: "Edit booking",
    frFR: "Modifier la réservation",
    itIT: "Modifica della prenotazione",
  },
  booking_modal_message_write_msg: {
    deDE: "Schreibe eine Nachricht...",
    enGB: "Write a message...",
    frFR: "Écrire un message...",
    itIT: "Scrivi un messaggio...",
  },
  booking_modal_message_send: {
    deDE: "Senden",
    enGB: "Send",
    frFR: "Envoyer",
    itIT: "Inviare",
  },
  booking_modal_status: {
    deDE: "Status",
    enGB: "Status",
    frFR: "Statut",
    itIT: "Stato",
  },
  booking_modal_from: {
    deDE: "Von",
    enGB: "From",
    frFR: "De",
    itIT: "Da",
  },
  booking_modal_to: {
    deDE: "Bis",
    enGB: "Until",
    frFR: "Jusqu'à",
    itIT: "Fino a",
  },
  booking_modal_guest: {
    deDE: "Gäste",
    enGB: "Guests",
    frFR: "Invités",
    itIT: "Ospiti",
  },
  booking_timepicker_today: {
    deDE: "Heute",
    enGB: "Today",
    frFR: "Aujourd'hui",
    itIT: "Oggi",
  },

  booking_state_available: {
    deDE: "Verfügbar",
    enGB: "Available",
    frFR: "Disponible",
    itIT: "Disponibile",
  },
  booking_state_reserved: {
    deDE: "Reserviert",
    enGB: "Reserved",
    frFR: "Réservé",
    itIT: "Riservato",
  },
  booking_state_confirmed: {
    deDE: "Bestätigt",
    enGB: "Confirmed",
    frFR: "Confirmé",
    itIT: "Confermato",
  },
  booking_state_canceled: {
    deDE: "Abgebrochen",
    enGB: "Canceled",
    frFR: "Annulé",
    itIT: "Annullato",
  },

  booking_state_completed: {
    deDE: "Abgeschlossen",
    enGB: "Completed",
    frFR: "Terminé",
    itIT: "Completato",
  },
  booking_state_blocked: {
    deDE: "Blockiert",
    enGB: "Blocked",
    frFR: "Bloqué",
    itIT: "Bloccato",
  },

  // pwd reset
  pwdReset_invite_title: {
    deDE: "Hallo, schön, dass du da bist!",
    enGB: "Hello, nice that you're here!",
    frFR: "Bonjour, content que tu sois là !",
    itIT: "Ciao, felice che tu sia qui!",
  },
  pwdReset_reset_title: {
    deDE: "Passwort zurücksetzen!",
    enGB: "Reset password!",
    frFR: "Réinitialiser le mot de passe !",
    itIT: "Reimposta la password!",
  },
  pwdReset_content: {
    deDE: "Bitte gib dein neues Passwort ein, mit dem du dich in Zukunft einloggen möchtest.",
    enGB: "Please enter your new password, which you want to use for future logins.",
    frFR: "Veuillez entrer votre nouveau mot de passe, que vous souhaitez utiliser pour les connexions futures.",
    itIT: "Inserisci la tua nuova password, che desideri utilizzare per i futuri accessi.",
  },
  pwdReset_placeholder_new_pwd: {
    deDE: "Neues Passwort",
    enGB: "New Password",
    frFR: "Nouveau mot de passe",
    itIT: "Nuova password",
  },
  pwdReset_placeholder_repeat_new_pwd: {
    deDE: "Neues Passwort wiederholen",
    enGB: "Repeat new password",
    frFR: "Répéter le nouveau mot de passe",
    itIT: "Ripeti la nuova password",
  },

  pwdReset_error_pwd_not_valid: {
    deDE: "Das neue Passwort muss mindestens 6 Zeichen lang sein",
    enGB: "The new password must be at least 6 characters long",
    frFR: "Le nouveau mot de passe doit comporter au moins 6 caractères",
    itIT: "La nuova password deve essere lunga almeno 6 caratteri",
  },
  pwdReset_error_pwds_not_equal: {
    deDE: "Leider stimmen die Passwörter nicht überein!",
    enGB: "Unfortunately, the passwords do not match!",
    frFR: "Malheureusement, les mots de passe ne correspondent pas !",
    itIT: "Purtroppo le password non corrispondono!",
  },

  // toast errors
  toast_error_not_enaugh_pictures: {
    deDE: "Es müssen mindestens 6 Bilder zu der Wohnung hinterlegt werden!",
    enGB: "At least 6 pictures must be uploaded for the apartment!",
    frFR: "Il faut au moins 6 photos pour l'appartement!",
    itIT: "Devono essere caricate almeno 6 foto per l'appartamento!",
  },
  toast_error_record_not_exists: {
    deDE: "konnte nicht gefunden werden",
    enGB: "could not be found",
    frFR: "n'a pas pu être trouvé",
    itIT: "non può essere trovato",
  },
  toast_error_cant_save: {
    deDE: "konnte nicht gespeichert werden, bitte versuchen Sie es erneut",
    enGB: "could not be saved, please try again",
    frFR: "n'a pas pu être enregistré, veuillez réessayer",
    itIT: "non può essere salvato, si prega di riprovare",
  },
  toast_error_cant_update: {
    deDE: "konnte nicht geupdated werden, bitte versuchen Sie es erneut",
    enGB: "could not be updated, please try again",
    frFR: "n'a pas pu être mis à jour, veuillez réessayer",
    itIT: "non può essere aggiornato, riprova",
  },
  toast_error_cant_delete: {
    deDE: "konnte nicht gelöscht werden, bitte versuchen Sie es erneut",
    enGB: "could not be deleted, please try again",
    frFR: "n'a pas pu être supprimé, veuillez réessayer",
    itIT: "non può essere eliminato, si prega di riprovare",
  },
  booking_toast_error_place_booking_not_allowed: {
    deDE: "Die Buchung kann nicht getätigt werden, da sie sich mit einer anderen überschneidet",
    enGB: "The booking cannot be made as it overlaps with another one",
    frFR: "La réservation ne peut pas être effectuée car elle se chevauche avec une autre",
    itIT: "La prenotazione non può essere effettuata poiché si sovrappone a un'altra",
  },
  realestate_tost_error_has_linked_apartments: {
    deDE: "Die Immobilie kann nicht gelöscht werden, da noch Wohnungen existieren, die mit ihr verknüpft sind.",
    enGB: "The property cannot be deleted as there are still apartments linked to it.",
    frFR: "La propriété ne peut pas être supprimée car des appartements y sont encore liés.",
    itIT: "L'immobile non può essere eliminato in quanto ci sono ancora appartamenti ad esso collegati.",
  },
  saison_toast_error_already_exists: {
    deDE: "Es existiert bereits eine solche Saison für das angegebene Jahr",
    enGB: "Such a season already exists for the specified year",
    frFR: "Une telle saison existe déjà pour l'année spécifiée",
    itIT: "Una stagione simile esiste già per l'anno specificato",
  },
  adminUser_toast_error_not_allowed_deletion: {
    deDE: "Es ist nicht erlaubt, diesen AdminUser zu löschen",
    enGB: "It is not allowed to delete this AdminUser",
    frFR: "Il n'est pas autorisé de supprimer cet AdminUser",
    itIT: "Non è consentito eliminare questo AdminUser",
  },
  adminUser_toast_error_cant_send_invite: {
    deDE: "Es war nicht möglich, die Einladung zu verschicken",
    enGB: "It was not possible to send the invitation",
    frFR: "Il n'était pas possible d'envoyer l'invitation",
    itIT: "Non è stato possibile inviare l'invito",
  },
  adminUser_toast_error_pwd_not_equal: {
    deDE: "Die eingegebenen Passwörter sind nicht gleich",
    enGB: "The entered passwords do not match",
    frFR: "Les mots de passe saisis ne correspondent pas",
    itIT: "Le password inserite non corrispondono",
  },
  adminUser_toast_error_pwd_update_not_possible: {
    deDE: "Das Updaten des Passwortes war nicht möglich",
    enGB: "Updating the password was not possible",
    frFR: "La mise à jour du mot de passe n'était pas possible",
    itIT: "L'aggiornamento della password non è stato possibile",
  },
  authentication_toast_error_login_not_allowed: {
    deDE: "Die eingegebene Email oder das Passwort sind nicht korrekt",
    enGB: "The entered email or password is not correct",
    frFR: "L'e-mail ou le mot de passe saisi n'est pas correct",
    itIT: "L'email o la password inserite non sono corrette",
  },
  authentication_toast_error_cant_send_email: {
    deDE: "Es ist etwas bei dem Versand der Email schief gelaufen, bitte versuche es zu einem späteren Zeitpunkt erneut",
    enGB: "There was an issue with sending the email, please try again later",
    frFR: "Il y a eu un problème lors de l'envoi de l'e-mail, veuillez réessayer plus tard",
    itIT: "C'è stato un problema con l'invio dell'email, riprova più tardi",
  },
  authentication_toast_error_cant_trigger_pwd_reset: {
    deDE: "Etwas ist schief gelaufen",
    enGB: "Something went wrong",
    frFR: "Quelque chose s'est mal passé",
    itIT: "Qualcosa è andato storto",
  },

  // settings
  settings_notification_title: {
    deDE: "Achtung",
    enGB: "Attention",
    frFR: "Attention",
    itIT: "Attenzione",
  },
};

export default translations;
