import { Button, Card, Col, Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewItemProps } from "./type";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { SaisonType } from "../../../../infrastructure/enums";
import { SaisonEntry } from "../../../../infrastructure/types";
import Utils from "../../../../infrastructure/utils";
import DeleteIcon from "../../../Icons/DeleteIcon";

const typeTpIdentifier = new Map([
  [0, "saison_type_peak"],
  [1, "saison_type_high"],
  [2, "saison_type_middle"],
  [3, "saison_type_low"],
]);

function ListViewItem(props: ListViewItemProps) {
  const { id, onClicked, onDeleteClicked, year, saisonEntries } = props;
  const { translate } = TranslationHandler();

  const transformTypeToName = (type: SaisonType): string => {
    const identifier = typeTpIdentifier.get(type) as string;

    return translate(identifier);
  };

  const buildEntry = (pos: number, saisonEntry: SaisonEntry): JSX.Element => {
    const { type, fromDate, toDate } = saisonEntry;

    const entry: JSX.Element = (
      <Row key={pos}>
        <Col>{transformTypeToName(type)}</Col>
        <Col>
          {Utils.formatDateForSaison(fromDate)} - {Utils.formatDateForSaison(toDate)}
        </Col>
      </Row>
    );

    return entry;
  };

  const buildEntries = (): JSX.Element[] => {
    const entries: JSX.Element[] = [];
    let counter = 0;
    for (const saisonEntry of saisonEntries) {
      const entry = buildEntry(counter, saisonEntry);
      entries.push(entry);

      counter++;
    }

    return entries;
  };

  return (
    <Col xs={4}>
      <Card className={styles.card}>
        <Card.Header>
          <h5>{year}</h5>
        </Card.Header>
        <Card.Body onClick={() => onClicked(id)}>
          <Container>{buildEntries()}</Container>
        </Card.Body>
        <Card.Footer className={styles.cardFooter} onClick={() => onDeleteClicked(id)}>
          <Row className={styles.clear} style={{ marginRight: "1em" }}>
            <Col className={styles.clear}>
              <Button className={styles.btn} size="sm" type="button" variant="outline-white">
                <DeleteIcon className={styles.deleteIcon} />
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
}

export default ListViewItem;
