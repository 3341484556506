import { Button, Col, Row } from "react-bootstrap";
import styles from "./component.module.css";

import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";

import ApiClient from "../../../interface/client/apiClient";
import { Translation } from "../../../infrastructure/types";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import Utils from "../../../infrastructure/utils";

type CompValue = {
  showOverlay: boolean;
  isLoading: boolean;

  message: Translation | null;
};

const HTMLRenderer: React.FC<{ html: string }> = ({ html }) => {
  if (!html) return <></>;

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

function NotificationOverlay() {
  if (!Utils.isLoggedIn()) return <></>;

  const [compValue, setCompValue] = useState<CompValue>({ showOverlay: false, message: null, isLoading: true });
  const { translateObj, translate } = TranslationHandler();

  useEffect(() => {
    const loadNotification = async () => {
      if (!compValue.isLoading) return;

      const { error, data } = await ApiClient().settingsNotificationMessage();
      if (error === null && data !== null) {
        setCompValue({ message: data, isLoading: false, showOverlay: true });
      }
    };

    loadNotification();
  });

  if (!compValue.showOverlay) return <></>;

  return (
    <>
      <div className={styles.blur} />
      <Container fluid className={styles.notificationOverlay}>
        <Row>
          <Col>
            <h3 className={styles.title}>{translate("settings_notification_title")}</h3>
            <HTMLRenderer html={translateObj(compValue.message as Translation)} />
          </Col>
          <Button
            style={{ marginTop: "0.5em" }}
            size="sm"
            variant="outline-dark"
            className={"float-end"}
            onClick={() => setCompValue({ ...compValue, showOverlay: false })}
          >
            Ok
          </Button>
        </Row>
      </Container>
    </>
  );
}

export default NotificationOverlay;
