// icon:bx-message-detail | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

function MessageIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M20 2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h3v3.767L13.277 18H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14h-7.277L9 18.233V16H4V4h16v12z" />
      <path d="M7 7h10v2H7zm0 4h7v2H7z" />
    </svg>
  );
}

export default MessageIcon;
