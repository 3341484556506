import { Button, Col, Form, Row } from "react-bootstrap";
import { SaisonEntry } from "../../../../../infrastructure/types";
import SaisonTypePicker from "../../SaisonTypePicker/SaisonTypePicker";
import Utils from "../../../../../infrastructure/utils";
import styles from "./component.module.css";
import { useEffect, useState } from "react";
import { SaisonType } from "../../../../../infrastructure/enums";
import DeleteIcon from "../../../../Icons/DeleteIcon";
import { TranslationHandler } from "../../../../Utils/TranslationProvider";

type SaisonEntryItemProps = {
  pos: number;
  hasConflictError: string | null;
  saisonEntry: SaisonEntry;

  isNew?: boolean;

  remove(pos: number): void;
  dataHasChanged(pos: number, saisonEntry: SaisonEntry): void;
};

type FormValues = {
  type: SaisonType;
  fromDate: Date;
  toDate: Date;
};

function SaisonEntryItem(props: SaisonEntryItemProps) {
  const { translate } = TranslationHandler();
  const { pos, isNew, hasConflictError, saisonEntry, dataHasChanged, remove } = props;

  const [formValues, setFormValues] = useState<FormValues>({ fromDate: new Date(), toDate: new Date(), type: SaisonType.LOW });

  const handleSaisonTypeHasChanged = (saisonType: number) => {
    setFormValues({ ...formValues, type: saisonType });
    dataHasChanged(pos, { ...formValues, type: saisonType });
  };

  const handleDateHasChanged = (event: any, fieldName: "fromDate" | "toDate") => {
    const value = new Date(event.target.value);
    let fromDate = formValues.fromDate;
    let toDate = formValues.toDate;

    switch (fieldName) {
      case "fromDate":
        const isFromDateSwitchNeeded = toDate < value;
        fromDate = isFromDateSwitchNeeded ? fromDate : value;
        toDate = isFromDateSwitchNeeded ? value : toDate;
        break;

      case "toDate":
        const isToDateSwitchNeeded = fromDate > value;
        fromDate = isToDateSwitchNeeded ? value : fromDate;
        toDate = isToDateSwitchNeeded ? toDate : value;
        break;
    }

    dataHasChanged(pos, { fromDate, toDate, type: formValues.type });

    setFormValues({ ...formValues, fromDate, toDate });
  };

  useEffect(() => {
    const usedFormValues: FormValues = {
      fromDate: saisonEntry.fromDate,
      toDate: saisonEntry.toDate,
      type: saisonEntry.type,
    };

    setFormValues(usedFormValues);
  }, [saisonEntry]);

  const newIndicator = isNew ? styles.newIndicator : "";

  return (
    <div key={pos}>
      <Row key={pos} className={`${styles.pullDown} ${newIndicator}`}>
        <Col xs={3}>
          <SaisonTypePicker key={pos} onChanged={handleSaisonTypeHasChanged} preSelectType={formValues.type} pos={pos} />
        </Col>
        <Col xs={1} className={styles.pullDown}>
          {translate("saison_label_from")}
        </Col>
        <Col xs={3}>
          <Form.Control
            key={pos}
            type="date"
            value={Utils.formatDateForDatePicker(formValues.fromDate)}
            onChange={(e: any) => handleDateHasChanged(e, "fromDate")}
          />
        </Col>
        <Col xs={1} className={styles.pullDown}>
          {translate("saison_label_to")}
        </Col>
        <Col xs={3}>
          <Form.Control
            key={pos}
            type="date"
            value={Utils.formatDateForDatePicker(formValues.toDate)}
            onChange={(e: any) => handleDateHasChanged(e, "toDate")}
          />
        </Col>
        <Col>
          <Button className={styles.btn} onClick={(e: any) => remove(pos)} size="sm" variant="outline-white">
            <DeleteIcon className={styles.deleteIcon} />
          </Button>
        </Col>
      </Row>
      {hasConflictError && hasConflictError}
    </div>
  );
}

export default SaisonEntryItem;
