import { Col, Container, Row } from "react-bootstrap";
import ToastContainerFactory from "../Utils/ToastContainerFactory/ToastContainerFactory";
import HorizontalCalendar from "./HorizontalCalendar/HorizontalCalendar";
import { TranslationHandler } from "../Utils/TranslationProvider";

import sharedStyles from "../component.shared.module.css";
import Utils from "../../infrastructure/utils";

function BookingView() {
  if (!Utils.isLoggedIn()) return <></>;

  const { translate } = TranslationHandler();

  return (
    <>
      <ToastContainerFactory />

      <Container fluid className={sharedStyles.component}>
        <div className={sharedStyles.headLine}>
          <div className={sharedStyles.pullRightHeadlineContent}>
            <Row>
              <Col>
                <h2>{translate("component_title_booking")} </h2>
              </Col>
            </Row>
            <Row>
              <Col className={sharedStyles.pullUpHeadlineContent}>{translate("booking_header_subtitle_selection")}</Col>
            </Row>
          </div>
        </div>

        {/* {usedComponent} */}
        <HorizontalCalendar />
      </Container>
    </>
  );
}

export default BookingView;
