import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { ChangeEvent, useEffect, useState } from "react";

type FormValues = {
  usedValue: string;
};
type TimePickerProps = {
  selectValue: string;
  range: number;
  disabled: boolean,

  valueChanged: (value: string) => void;
};

function buildItem(pos: number, value: string): JSX.Element {
  return (
    <option key={pos} value={value}>
      {value}
    </option>
  );
}

function generateItems(range: number): JSX.Element[] {
  const items: JSX.Element[] = [];
  for (let i = 0; i <= range; i++) {
    const value = i < 10 ? `0${i}` : `${i}`;
    const item = buildItem(i, value);
    items.push(item);
  }

  return items;
}

function TimePicker(props: TimePickerProps) {
  const [formValues, setFormValues] = useState<FormValues>({ usedValue: "00" });

  const handleValueChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    setFormValues({ ...formValues, usedValue: value });
    props.valueChanged(value);
  };

  useEffect(() => {
    const formValues: FormValues = { usedValue: props.selectValue };

    setFormValues(formValues);
  }, [props.selectValue, props.range]);

  return (
    <Form.Select disabled={props.disabled} value={formValues.usedValue} onChange={handleValueChanged} aria-label="timePicker">
      {generateItems(props.range)}
    </Form.Select>
  );
}

export default TimePicker;
