import { RequestPermission } from "../../../infrastructure/enums";
import Utils from "../../../infrastructure/utils";
import IdentityProvider from "../../Utils/IdentityProvider";
import SideMenuItemAdminUser from "./Items/SideMenuItemAdminUser";
import SideMenuItemApartment from "./Items/SideMenuItemApartment";
import SideMenuItemBooking from "./Items/SideMenuItemBooking";
import SideMenuItemNews from "./Items/SideMenuItemNews";
import SideMenuItemRealestate from "./Items/SideMenuItemRealEstate";
import SideMenuItemSettings from "./Items/SideMenuItemSettings";
import styles from "./component.module.css";

import { Nav } from "react-bootstrap";

type Props = {
  hasChanged: Function;
};

function SideMenu(props: Props) {
  const activeSideBarItem = Utils.getSelectedSideBarItemToLocalStorage();
  const {
    BOOKING_VIEW,
    BOOKING_EDIT,
    APARTMENT_VIEW,
    APARTMENT_EDIT,
    APARTMENT_DELETE,
    REAL_ESTATE_VIEW,
    REAL_ESTATE_EDIT,
    REAL_ESTATE_DELETE,
    NEWS_VIEW,
    NEWS_EDIT,
    NEWS_DELETE,
    SETTINGS_VIEW,
    SETTINGS_EDIT,
    SETTINGS_DELETE,
    ADMIN_USER_VIEW,
    ADMIN_USER_EDIT,
    ADMIN_USER_DELETE,
  } = RequestPermission;

  const isSidebarActive = (menuKey: "booking" | "realestate" | "apartment" | "news" | "adminUser" | "settings") => {
    return menuKey === activeSideBarItem;
  };

  const onSelectHandler = (eventKey: string | null) => props.hasChanged(eventKey);

  console.log(Utils.getSelectedSideBarItemToLocalStorage());

  return (
    <Nav className={styles.customNav} onSelect={onSelectHandler}>
      <IdentityProvider permissions={[BOOKING_VIEW, BOOKING_EDIT]}>
        <SideMenuItemBooking isActive={isSidebarActive("booking")} />
      </IdentityProvider>

      <IdentityProvider permissions={[APARTMENT_VIEW]}>
        <SideMenuItemApartment isActive={isSidebarActive("apartment")} />
      </IdentityProvider>

      <IdentityProvider permissions={[REAL_ESTATE_VIEW, REAL_ESTATE_EDIT, REAL_ESTATE_DELETE]}>
        <SideMenuItemRealestate isActive={isSidebarActive("realestate")} />
      </IdentityProvider>

      <IdentityProvider permissions={[NEWS_VIEW, NEWS_EDIT, NEWS_DELETE]}>
        <SideMenuItemNews isActive={isSidebarActive("news")} />
      </IdentityProvider>

      <IdentityProvider permissions={[ADMIN_USER_VIEW, ADMIN_USER_EDIT, ADMIN_USER_DELETE]}>
        <SideMenuItemAdminUser isActive={isSidebarActive("adminUser")} />
      </IdentityProvider>

      <IdentityProvider permissions={[SETTINGS_VIEW, SETTINGS_EDIT, SETTINGS_DELETE]}>
        <SideMenuItemSettings isActive={isSidebarActive("settings")} />
      </IdentityProvider>
    </Nav>
  );
}

export default SideMenu;
