import styles from "./component.module.css";

import { Nav } from "react-bootstrap";
import { SideMenuItemProps } from "./types";

import { TranslationHandler } from "../../../Utils/TranslationProvider";

function SideMenuItemAdminUser(props: SideMenuItemProps) {
  const { isActive } = props;
  const { translate } = TranslationHandler();
  
  const styleIsActive = isActive ? styles.isActiveNavLink : "";

  return (
    <Nav.Item className={styles.siteMenuItem}>
      <Nav.Link eventKey="adminUser" className={`${styles.navLink} ${styleIsActive}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person" viewBox="0 0 25 25">
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
        </svg>
        {translate("sideMenu_AdminUser")}
      </Nav.Link>
    </Nav.Item>
  );
}

export default SideMenuItemAdminUser;
