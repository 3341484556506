import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { HistoryAddItemProps } from "./type";
import { TranslationHandler } from "../../Utils/TranslationProvider";

function HistoryAddItem(props: HistoryAddItemProps) {
  const { translate } = TranslationHandler();
  const { onClicked } = props;

  return (
    <Card className={`${styles.cardAddPicture}`} onClick={() => onClicked()}>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col xs={1}>
              <Card.Img className={styles.cardAddPictureImg} src="/AddPicture.png" />
            </Col>
            <Col>
              <h5 className={styles.pullDown}>{translate("label_add")}</h5>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default HistoryAddItem;
