import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import styles from "./component.module.css";

import FavoritIcon from "../../../../Icons/FavoritIcon";
import DeleteIcon from "../../../../Icons/DeleteIcon";
import { useEffect, useState } from "react";
import { Translation, UUID } from "../../../../../infrastructure/types";

import ApiClient from "../../../../../interface/client/apiClient";
import TranslationIcon from "../../../../Icons/TranslationIcon";
import { PuffLoader } from "react-spinners";
import { TranslationHandler } from "../../../../Utils/TranslationProvider";
import TranslationModal from "./TranslationModal/TranslationModal";

type PictureItemProps = {
  pictureID: UUID;
  isMainPicture: boolean;

  handleFavoriteOnClicked: (pictureID: UUID) => void;
  handleRemoveOnClicked: (pictureID: UUID) => void;
};

function PictureCard(props: PictureItemProps) {
  const { translate, translateObj } = TranslationHandler();
  const { pictureID, isMainPicture, handleFavoriteOnClicked, handleRemoveOnClicked } = props;

  const [usedImg, setUsedImg] = useState(<Card.Img style={{ opacity: 0.05 }} className={styles.img} src={"/FeWoServ_logo.png"} />);
  const [description, setDescription] = useState<Translation>({});
  const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadImage = async () => {
      const pictureResponse = await ApiClient().pictureGet(pictureID);
      if (pictureResponse.error === null) {
        const { raw, description } = pictureResponse.data;

        setUsedImg(<Card.Img className={styles.img} src={raw} />);
        setDescription(description);
        setIsLoading(false);
      }
    };

    if (pictureID) loadImage();
  }, [pictureID]);

  const usedClasses = [styles.card];

  let favoriteIcon = <FavoritIcon.Disabled onClick={() => handleFavoriteOnClicked(pictureID)} />;

  if (isMainPicture) {
    usedClasses.push(styles.mainPicture);
    favoriteIcon = <FavoritIcon.Enabled onClick={() => handleFavoriteOnClicked(pictureID)} />;
  }

  const handlePictureDescriptionUpdated = async (updatedPictureDescription: Translation) => {
    await ApiClient().pictureUpdate(pictureID, updatedPictureDescription);

    setDescription(updatedPictureDescription);
    setShowTranslationModal(false);
  };

  return (
    <>
      {showTranslationModal && (
        <TranslationModal
          handleAborted={() => setShowTranslationModal(false)}
          handleTranslationUpdated={handlePictureDescriptionUpdated}
          translation={description}
          showModal={showTranslationModal}
        />
      )}

      <Card key={pictureID} className={usedClasses.join(" ")}>
        {isMainPicture && (
          <Badge bg="dark" text="light" className={`${styles.adjustBadge} float-end`}>
            {translate("apartment_picture_main_picture")}
          </Badge>
        )}
        <Card.Header className={styles.headerHidden}>
          <Container>
            <Row className={`${styles.iconCollection} float-end`}>
              <Col>
                <Row>{favoriteIcon}</Row>
                <Row>
                  <TranslationIcon onClick={() => setShowTranslationModal(true)} />
                </Row>
                <Row>
                  <DeleteIcon onClick={() => handleRemoveOnClicked(pictureID)} />
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <div className={styles.spinnerContainer}>{isLoading && <PuffLoader speedMultiplier={0.5} color="rgba(85, 90, 89, 0.41)" />}</div>
        <Card.Body style={{ padding: 0 }}>{usedImg}</Card.Body>
        <Card.Footer className={styles.mainPictureFooter}>
          <Card.Subtitle>{translateObj(description)}</Card.Subtitle>
        </Card.Footer>
      </Card>
    </>
  );
}

export default PictureCard;
