import { createContext, useState, useContext, ReactNode } from "react";
import { Locale } from "../../../infrastructure/enums";
import translations from "../../../infrastructure/translations";
import { Translation } from "../../../infrastructure/types";
import Utils from "../../../infrastructure/utils";

interface TranslationContextProps {
  translate: (key: string, externalLocale?: Locale) => string;
  translateObj: (obj: Translation, externalLocale?: Locale) => string;
  setLocale: (locale: Locale) => void;
  locale: Locale;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

type TranslationProviderProps = { children: ReactNode };

const noTranslationFound = (identifier: string): string => `NTY: ${identifier}`;
const fallbackLocale = Locale.deDE;

export function TranslationProvider(props: TranslationProviderProps) {
  const defaultLocale = Utils.getLocale();
  const [locale, setLocale] = useState<Locale>(defaultLocale); // Default language is german

  const getTranslation = (identifier: string, externalLocale?: Locale): string => {
    const foundRecord = translations[identifier] as Translation;
    if (!foundRecord) return noTranslationFound(identifier);

    return extractTranslation(foundRecord, externalLocale);
  };

  const extractTranslation = (obj: Translation, externalLocale?: Locale): string => {
    let foundTranslation: string | undefined = obj[externalLocale ?? locale] as string;
    if (foundTranslation) return foundTranslation;

    foundTranslation = obj[fallbackLocale] as string;
    if (foundTranslation) return foundTranslation;

    foundTranslation = obj["all"] as string;
    if (foundTranslation) return foundTranslation;

    return noTranslationFound("no local matched");
  };

  return (
    <TranslationContext.Provider value={{ translate: getTranslation, translateObj: extractTranslation, setLocale, locale }}>
      {props.children}
    </TranslationContext.Provider>
  );
}

export function TranslationHandler() {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error("t must be used within a TranslationProvider");
  }
  return context;
}
