import { useEffect, useState } from "react";

import ApiClient from "../../../../interface/client/apiClient";

import styles from "./component.module.css";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { toastErrorWithCorrelationID } from "../../../Utils/ToastContainerFactory/ToastContainerFactory";
import { ProcessLog, UUIDOrNull } from "../../../../infrastructure/types";
import { Col, Container, Row } from "react-bootstrap";
import Utils from "../../../../infrastructure/utils";

type ProcessLogProps = {
  userID: UUIDOrNull;
};

type CompValues = {
  processLogs: ProcessLog[];
};

function ProcessLogView(props: ProcessLogProps) {
  const { userID } = props;

  const { translate } = TranslationHandler();
  const [compValues, setCompValues] = useState<CompValues>({ processLogs: [] });

  useEffect(() => {
    const loadProcessLog = async () => {
      if (!userID) return;

      const { error, errorTRKey, data, correlationID } = await ApiClient().processLog(userID);
      if (error !== null) {
        toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
        return;
      }

      setCompValues({ processLogs: data });
    };

    loadProcessLog();
  }, [props.userID]);

  const buildHeaderItem = (): JSX.Element => {
    return (
      <Row className={styles.pushDown10EM}>
        <Col xs={2}>
          <strong>Created</strong>
        </Col>
        {/* <Col>ID</Col>
        <Col>RecordID</Col> */}
        <Col xs={2}>
          <strong>Domain</strong>
        </Col>
        <Col xs={2}>
          <strong>Action</strong>
        </Col>
        <Col>
          <strong>Value</strong>
        </Col>
      </Row>
    );
  };

  const buildItem = (processLog: ProcessLog, pos: number): JSX.Element => {
    const { action, created, domain, id, recordID, value } = processLog;

    const isUneven = pos % 2 !== 0;
    let bgClass = isUneven ? styles.uneven : "";

    return (
      <Row className={bgClass} key={pos}>
        <Col xs={2}>{Utils.formatDate(created.time)}</Col>
        {/* <Col>{id}</Col>
        <Col>{recordID}</Col> */}
        <Col xs={2}>{domain}</Col>
        <Col xs={2}>{action}</Col>
        <Col>{value}</Col>
      </Row>
    );
  };

  const buildItems = (): JSX.Element[] => {
    const { processLogs } = compValues;
    const items: JSX.Element[] = [];

    let loop = 0;
    for (const processLog of processLogs) {
      const item = buildItem(processLog, loop);
      items.push(item);

      loop++;
    }

    return items;
  };

  return (
    <Container fluid style={{ marginBottom: "1.0em" }}>
      {buildHeaderItem()}
      {buildItems()}
    </Container>
  );
}

export default ProcessLogView;
