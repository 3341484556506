import { ChangeEvent, useState } from "react";

import { Col, Container, Form, Row } from "react-bootstrap";

import sharedStyles from "../../component.shared.module.css";
import styles from "./component.module.css";
import { MenuProps } from "./type";
import Utils from "../../../infrastructure/utils";
import { TranslationHandler } from "../../Utils/TranslationProvider";

function Menu(props: MenuProps) {
  const { translate } = TranslationHandler();

  const [searchTerm, setSearchTerm] = useState<string>("");

  let timeout: NodeJS.Timeout;
  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    timeout = Utils.delayCall(() => {
      setSearchTerm(event.target.value);
      props.onSearchTyped(event.target.value);
    }, timeout);
  };

  return (
    <Container fluid className={sharedStyles.menu}>
      <Form>
        <Row className="align-items-center">
          <Col>
            <Form.Control
              className={styles.removeBorder}
              id="inlineFormInput"
              onChange={handleSearchOnChange}
              placeholder={translate("menu_search_input")}
              defaultValue={searchTerm}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default Menu;
