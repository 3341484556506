import { Col, Container, Row } from "react-bootstrap";
import styles from "./component.module.css";

import EditView from "./EditView/EditView";
import { Mode } from "../../interface/client/enums";
import ToastContainerFactory, { toastErrorWithCorrelationID, toastSuccess } from "../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../Utils/TranslationProvider";
import { useEffect, useState } from "react";
import ApiClient from "../../interface/client/apiClient";
import { News, Translation, UUID } from "../../infrastructure/types";
import ListView from "./ListView/ListView";
import Utils from "../../infrastructure/utils";

type FormValues = {
  isLoading: boolean;
  news: News[];
  mode: Mode;
  search: string;
  clickedNews: News | null;
};

function NewsView() {
  if (!Utils.isLoggedIn()) return <></>;

  const { translate, translateObj } = TranslationHandler();

  const [formValues, setFormValues] = useState<FormValues>({ search: "", mode: Mode.DEFAULT, isLoading: true, news: [], clickedNews: null });

  useEffect(() => {
    const preloadData = async () => {
      // content was already loaded
      if (!formValues.isLoading) return;

      const preparedFormValues: Partial<FormValues> = {};

      const { data, error, correlationID, errorTRKey } = await ApiClient().newsGetMany(formValues.search);
      if (error === null) {
        preparedFormValues.news = data;
      } else {
        toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
      }

      preparedFormValues.isLoading = false;

      setFormValues(preparedFormValues as FormValues);
    };

    preloadData();
  });

  const handleChildClicked = (news: News) => {
    Utils.scrollToTop();

    setFormValues({ ...formValues, mode: Mode.EDIT, clickedNews: news });
  };

  const handleAddClicked = () => {
    setFormValues({ ...formValues, mode: Mode.ADD });
  };

  const handleAbortClicked = () => {
    setFormValues({ ...formValues, mode: Mode.DEFAULT, clickedNews: null, isLoading: true });
  };

  const handleDeleteClicked = async (newsID: UUID) => {
    const { correlationID, error, errorTRKey } = await ApiClient().newsDelete(newsID);
    if (error !== null) {
      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
      return;
    }

    toastSuccess(translate("news_toast_delete"));
    handleAbortClicked();
  };

  const handleSaveClicked = async (news: News) => {
    const { active, content, id, publishAt, title } = news;
    switch (formValues.mode) {
      case Mode.ADD:
        const addResponse = await ApiClient().newsCreate(title, content, publishAt);
        if (addResponse.error !== null) {
          toastErrorWithCorrelationID(translate(addResponse.errorTRKey), addResponse.correlationID);

          return;
        }

        toastSuccess(translate("news_toast_created"));
        break;

      case Mode.EDIT:
        const editResponse = await ApiClient().newsUpdate(id, title, content, publishAt, active);
        if (editResponse.error !== null) {
          toastErrorWithCorrelationID(translate(editResponse.errorTRKey), editResponse.correlationID);

          return;
        }

        toastSuccess(translate("news_toast_updated"));
        break;
    }
  };

  let usedComponent = (
    <ListView
      news={formValues.news}
      addClicked={handleAddClicked}
      childClicked={handleChildClicked}
      onSearchTyped={(search: string) => setFormValues({ ...formValues, search, isLoading: true })}
    />
  );

  let subTitle = translate("news_header_subtitle_selection");

  switch (formValues.mode) {
    case Mode.ADD:
      usedComponent = (
        <EditView deleteClicked={handleDeleteClicked} abortClicked={handleAbortClicked} savedClicked={handleSaveClicked} news={null} mode={Mode.ADD} />
      );
      subTitle = translate("news_header_subtitle_add");
      break;

    case Mode.EDIT:
      usedComponent = (
        <EditView
          deleteClicked={handleDeleteClicked}
          abortClicked={handleAbortClicked}
          savedClicked={handleSaveClicked}
          news={formValues.clickedNews}
          mode={Mode.EDIT}
        />
      );
      subTitle = `${translate("news_header_subtitle_edit")} ${translateObj(formValues.clickedNews?.title as Translation)}`;

      break;
  }
  return (
    <>
      <ToastContainerFactory />

      <Container fluid className={styles.component}>
        <div className={styles.headLine}>
          <div className={styles.pullRightHeadlineContent}>
            <Row>
              <Col>
                <h2>{translate("component_title_news")} </h2>
              </Col>
            </Row>
            <Row>
              <Col className={styles.pullUpHeadlineContent}>{subTitle}</Col>
            </Row>
          </div>
        </div>

        {usedComponent}
      </Container>
    </>
  );
}

export default NewsView;
