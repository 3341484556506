import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewAddItemProps } from "./type";
import { TranslationHandler } from "../../../Utils/TranslationProvider";

function ListViewAddItem(props: ListViewAddItemProps) {
  const { onClicked } = props;
  const { translate } = TranslationHandler();

  return (
    <Col xs={4}>
      <Card key="0" className={styles.cardAddPicture} onClick={onClicked}>
        <Card.Header className={styles.cardHeader}>
          <Container>
            <Row>
              <Col xs={1}>
                <Card.Img className={styles.picture} src="/AddPicture.png" />
              </Col>
              <Col>{translate("label_add")}</Col>
            </Row>
          </Container>
        </Card.Header>
      </Card>
    </Col>
  );
}

export default ListViewAddItem;
