import { Locale } from "../../infrastructure/enums";
import { ProcessLog, ReadOnlyApartment, SaisonEntry, SaisonPrice, Translation, UUID, UUIDOrNull } from "../../infrastructure/types";
import Utils from "../../infrastructure/utils";
import ApiClientCore, { Request } from "./lib/core";
import {
  LoginResponse,
  AdminUserGetResponse,
  DeletedResponse,
  RealEstatePostResponse,
  RealEstateGetManyResponse,
  RealEstateGetResponse,
  PictureResponse,
  SaisonGetResponse,
  SaisonGetManyResponse,
  SaisonPostResponse,
  AttributeGetResponse,
  AttributeGetManyResponse,
  ApartmentGetManyResponse,
  ApartmentResponse,
  AdminUserGetRealEstateOwnerResponse,
  AdminUserGetManyResponse,
  PermissionResponse,
  AttributeCreateResponse,
  NewsGetResponse,
  NewsGetManyResponse,
  NewsPostResponse,
  NewsPatchResponse,
  BookingGetResponse,
  BookingPostResponse,
  BookingPatchResponse,
  ForgotPwdResponse,
  ResetPwdResponse,
} from "./types";

let instance: ApiClient;
export default function init(url?: string): ApiClient {
  if (instance === undefined) {
    instance = new ApiClient(url as string);
  }

  return instance;
}

class ApiClient extends ApiClientCore {
  public constructor(requestUrl: string) {
    super(requestUrl);
  }

  //////////////////////////////////////////
  // Authentication
  //////////////////////////////////////////
  public async login(email: string, password: string): Promise<Request<LoginResponse>> {
    const payload = { email, password };
    return this.doRequest<LoginResponse>("POST", "/login", payload);
  }

  public async forgotPwd(email: string): Promise<Request<ForgotPwdResponse>> {
    const payload = { email };
    return this.doRequest<ForgotPwdResponse>("POST", "/forgotPwd", payload);
  }

  public async resetPwd(pwd: string, repeatedPwd: string, token: string): Promise<Request<ResetPwdResponse>> {
    const payload = { pwd, repeatedPwd, token };
    return this.doRequest<ResetPwdResponse>("POST", "/resetPwd", payload);
  }

  //////////////////////////////////////////
  // AdminUser
  //////////////////////////////////////////
  public async adminUserGet(id: string): Promise<Request<AdminUserGetResponse>> {
    return this.doRequest<AdminUserGetResponse>("GET", `/adminUsers/${id}`, null);
  }

  public async adminUserCreate(email: string, firstName: string, lastName: string, type: number, locale: Locale): Promise<Request<AdminUserGetResponse>> {
    const payload = {
      email,
      firstName,
      lastName,
      type,
      locale,
    };

    return this.doRequest<AdminUserGetResponse>("POST", `/adminUsers`, payload);
  }

  public async adminUserUpdate(
    recordID: UUID,
    isActive?: boolean,
    firstName?: string,
    lastName?: string,
    type?: number,
    permissions?: string[],
    locale?: Locale
  ): Promise<Request<AdminUserGetResponse>> {
    const payload = {} as any;
    if (isActive != null) payload["isActive"] = isActive;
    if (firstName) payload["firstName"] = firstName;
    if (lastName) payload["lastName"] = lastName;
    if (type) payload["type"] = type;
    if (permissions) payload["permissions"] = permissions;
    if (locale) payload["locale"] = locale;

    return this.doRequest<AdminUserGetResponse>("PATCH", `/adminUsers/${recordID}`, payload);
  }

  public async adminUserSendInvitation(recordID: UUIDOrNull): Promise<Request<AdminUserGetResponse>> {
    return this.doRequest<AdminUserGetResponse>("PATCH", `/adminUsers/${recordID}/invite`, null);
  }

  public async adminUserUpdatePasswords(
    recordID: UUIDOrNull,
    oldPassword: string,
    newPassword: string,
    newComparePassword: string
  ): Promise<Request<AdminUserGetResponse>> {
    const payload = {
      passwordUpdate: { oldPassword, newPassword, newComparePassword },
    };

    return this.doRequest<AdminUserGetResponse>("PATCH", `/adminUsers/${recordID}`, payload);
  }

  public async adminUserGetMe(): Promise<Request<AdminUserGetResponse>> {
    return this.doRequest<AdminUserGetResponse>("GET", "/adminUsers/me", null);
  }

  public async adminUserGetRealEstateOwner(): Promise<Request<AdminUserGetRealEstateOwnerResponse[]>> {
    return this.doRequest<AdminUserGetResponse[]>("GET", "/adminUsers?order=desc&field=id&type=2&skip=0&limit=25", null);
  }

  public async adminUserGetMany(name?: string, adminUserType?: number | null): Promise<Request<AdminUserGetManyResponse[]>> {
    let url = "/adminUsers?order=desc&field=id&skip=0&limit=25";
    if (adminUserType != null) url += `&type=${adminUserType}`;
    if (name) url += `&name=${name}`;

    return this.doRequest<AdminUserGetResponse[]>("GET", url, null);
  }

  public async adminUserDelete(id: string): Promise<Request<DeletedResponse>> {
    return this.doRequest<DeletedResponse>("DELETE", `/adminUsers/${id}`, null);
  }

  //////////////////////////////////////////
  // Attribute
  //////////////////////////////////////////
  public async attributeGet(recordID: string): Promise<Request<AttributeGetResponse>> {
    const url = `/attributes/${recordID}`;

    return this.doRequest<AttributeGetResponse>("GET", url, null);
  }

  public async attributeDelete(recordID: UUID): Promise<Request<DeletedResponse>> {
    const url = `/attributes/${recordID}`;

    return this.doRequest<DeletedResponse>("DELETE", url, null);
  }

  public async attributeGetMany(name?: string | null): Promise<Request<AttributeGetManyResponse[]>> {
    let url = `/attributes?order=asc&field=name&skip=0&limit=100`;
    if (name) url += `&name=${name}`;

    return this.doRequest<AttributeGetResponse[]>("GET", url, null);
  }

  public async attributeUpdate(recordID: string, name?: Translation, svg?: string): Promise<Request<AttributeGetResponse>> {
    const url = `/attributes/${recordID}`;

    const payload = {} as any;
    if (name !== undefined) payload["name"] = name;
    if (svg !== undefined) payload["svg"] = svg;

    return this.doRequest<AttributeGetResponse>("PATCH", url, payload);
  }

  public async attributeCreate(name: Translation, svg?: string): Promise<Request<AttributeCreateResponse>> {
    const url = `/attributes`;

    const payload = { name } as any;
    if (svg) payload["svg"] = svg;

    return this.doRequest<AttributeCreateResponse>("POST", url, payload);
  }

  //////////////////////////////////////////
  // Apartment
  //////////////////////////////////////////
  public async apartmentGet(recordID: string): Promise<Request<ApartmentResponse>> {
    const url = `/apartments/${recordID}`;

    return this.doRequest<ApartmentResponse>("GET", url, null);
  }

  public async apartmentGetReadOnly(recordID: string): Promise<Request<ReadOnlyApartment>> {
    const url = `/apartments/${recordID}/ro`;

    return this.doRequest<ReadOnlyApartment>("GET", url, null);
  }

  public async apartmentDelete(recordID: string): Promise<Request<DeletedResponse>> {
    const url = `/apartments/${recordID}`;

    return this.doRequest<DeletedResponse>("DELETE", url, null);
  }

  public async apartmentGetMany(name?: string): Promise<Request<ApartmentGetManyResponse[]>> {
    let url = `/apartments?order=desc&field=name&skip=0&limit=25`;
    if (name) url += `&name=${name}`;

    return this.doRequest<ApartmentGetManyResponse[]>("GET", url, null);
  }

  public async apartmentCreate(
    ownerID: UUID,
    realEstateID: UUID,
    name: string,
    allowedNumberOfPeople: number,
    allowedNumberOfPets: number,
    roomSize: number,
    bathRooms: number,
    sleepingPlaces: number,
    description?: Translation,
    saisonPrice?: SaisonPrice,
    attributeIDs?: UUID[],
    topAttributeIDs?: UUID[],
    pictureIDs?: UUID[] | null
  ): Promise<Request<ApartmentResponse>> {
    const payload = {
      ownerID,
      realEstateID,
      name,
      description,
      saisonPrice,
      attributeIDs,
      topAttributeIDs,
      pictureIDs,
      allowedNumberOfPeople,
      allowedNumberOfPets,
      bathRooms,
      roomSize,
      sleepingPlaces,
    };

    return this.doRequest<ApartmentResponse>("POST", `/apartments`, payload);
  }

  public async apartmentUpdate(
    recordID: UUID,
    pictureIDs?: UUID[] | null,
    isActive?: boolean | null,
    ownerID?: UUID,
    realEstateID?: UUID,
    name?: string,
    allowedNumberOfPeople?: number,
    allowedNumberOfPets?: number,
    roomSize?: number,
    bathRooms?: number,
    sleepingPlaces?: number,
    description?: Translation,
    saisonPrice?: SaisonPrice,
    attributeIDs?: UUID[],
    topAttributeIDs?: UUID[]
  ): Promise<Request<RealEstatePostResponse>> {
    const payload = {} as any;
    if (isActive !== null) payload["isActive"] = isActive;
    if (name) payload["name"] = name;
    if (description) payload["description"] = description;
    if (ownerID) payload["ownerID"] = ownerID;
    if (realEstateID) payload["realEstateID"] = realEstateID;
    if (pictureIDs) payload["pictureIDs"] = pictureIDs;
    if (saisonPrice) payload["saisonPrice"] = saisonPrice;
    if (attributeIDs) payload["attributeIDs"] = attributeIDs;
    if (topAttributeIDs) payload["topAttributeIDs"] = topAttributeIDs;
    if (allowedNumberOfPeople !== undefined) payload["allowedNumberOfPeople"] = allowedNumberOfPeople;
    if (allowedNumberOfPets !== undefined) payload["allowedNumberOfPets"] = allowedNumberOfPets;
    if (roomSize !== undefined) payload["roomSize"] = roomSize;
    if (sleepingPlaces !== undefined) payload["sleepingPlaces"] = sleepingPlaces;
    if (bathRooms !== undefined) payload["bathRooms"] = bathRooms;

    return this.doRequest<RealEstatePostResponse>("PATCH", `/apartments/${recordID}`, payload);
  }

  //////////////////////////////////////////
  // RealEstate
  //////////////////////////////////////////
  public async realEstateGet(recordID: string): Promise<Request<RealEstateGetResponse>> {
    const url = `/realEstates/${recordID}`;

    return this.doRequest<RealEstateGetResponse>("GET", url, null);
  }

  public async realEstateDelete(recordID: string): Promise<Request<DeletedResponse>> {
    const url = `/realEstates/${recordID}`;

    return this.doRequest<DeletedResponse>("DELETE", url, null);
  }

  public async realEstateGetMany(name?: string): Promise<Request<RealEstateGetManyResponse[]>> {
    let url = `/realEstates?order=desc&field=name&skip=0&limit=25`;
    if (name) url += `&name=${name}`;

    return this.doRequest<RealEstateGetManyResponse[]>("GET", url, null);
  }

  public async realEstateCreate(name: string, description: Translation): Promise<Request<RealEstatePostResponse>> {
    const payload = { name, description };

    return this.doRequest<RealEstatePostResponse>("POST", `/realEstates`, payload);
  }

  public async realEstateUpdate(recordID: string, pictureID?: UUIDOrNull, name?: string, description?: Translation): Promise<Request<RealEstatePostResponse>> {
    const payload = {} as any;
    if (name) payload["name"] = name;
    if (description) payload["description"] = description;
    if (pictureID) payload["pictureID"] = pictureID;

    return this.doRequest<RealEstatePostResponse>("PATCH", `/realEstates/${recordID}`, payload);
  }

  //////////////////////////////////////////
  // Picture
  //////////////////////////////////////////
  public async pictureUpload(file: File, description: Translation, isOrigin?: boolean, recordID?: UUID): Promise<Request<PictureResponse[]>> {
    return this.upload(file, description, `/pictures/upload`, isOrigin, recordID);
  }

  public async pictureGet(recordID: string): Promise<Request<PictureResponse>> {
    const url = `/pictures/${recordID}?variant=middle`;

    return this.doRequest<PictureResponse>("GET", url, null);
  }

  public async pictureGetThumbnail(recordID: string): Promise<Request<PictureResponse>> {
    const url = `/pictures/${recordID}?variant=small`;

    return this.doRequest<PictureResponse>("GET", url, null);
  }

  public async pictureUpdate(recordID: string, description: Translation): Promise<Request<PictureResponse>> {
    const url = `/pictures/${recordID}?variant=middle`;

    return this.doRequest<PictureResponse>("PATCH", url, { description });
  }

  //////////////////////////////////////////
  // Saison
  //////////////////////////////////////////
  public async saisonGet(recordID: string): Promise<Request<SaisonGetResponse>> {
    const url = `/saisons/${recordID}`;

    return this.doRequest<SaisonGetResponse>("GET", url, null);
  }

  public async saisonDelete(recordID: string): Promise<Request<DeletedResponse>> {
    const url = `/saisons/${recordID}`;

    return this.doRequest<DeletedResponse>("DELETE", url, null);
  }

  public async saisonGetMany(year?: string): Promise<Request<SaisonGetManyResponse[]>> {
    let url = `/saisons?order=desc&field=id&skip=0&limit=25`;
    if (year) url += `&year=${year}`;

    return this.doRequest<SaisonGetManyResponse[]>("GET", url, null);
  }

  public async saisonCreate(year: number, entries: SaisonEntry[]): Promise<Request<SaisonPostResponse>> {
    const payload = { year, entries };

    return this.doRequest<SaisonPostResponse>("POST", `/saisons`, payload);
  }

  public async saisonUpdate(recordID: string, year?: number, entries?: SaisonEntry[]): Promise<Request<SaisonPostResponse>> {
    const payload = {} as any;
    if (year) payload["year"] = year;
    if (entries) payload["entries"] = entries;

    return this.doRequest<SaisonPostResponse>("PATCH", `/saisons/${recordID}`, payload);
  }

  //////////////////////////////////////////
  // News
  //////////////////////////////////////////
  public async newsGet(recordID: UUID): Promise<Request<NewsGetResponse>> {
    const url = `/news/${recordID}`;

    return this.doRequest<NewsGetResponse>("GET", url, null);
  }

  public async newsDelete(recordID: UUID): Promise<Request<DeletedResponse>> {
    const url = `/news/${recordID}`;

    return this.doRequest<DeletedResponse>("DELETE", url, null);
  }

  public async newsGetMany(title?: string): Promise<Request<NewsGetManyResponse[]>> {
    let url = `/news?order=desc&field=id&skip=0&limit=25`;
    if (title != null) url += `&title=${title}`;

    return this.doRequest<NewsGetManyResponse[]>("GET", url, null);
  }

  public async newsCreate(title: Translation, content: Translation, publishAt: Date): Promise<Request<NewsPostResponse>> {
    const payload = { title, content, publishAt };

    return this.doRequest<NewsPostResponse>("POST", `/news`, payload);
  }

  public async newsUpdate(
    recordID: string,
    title?: Translation,
    content?: Translation,
    publishAt?: Date,
    active?: boolean
  ): Promise<Request<NewsPatchResponse>> {
    const payload = {} as any;
    if (active !== null) payload["active"] = active;
    if (title) payload["title"] = title;
    if (content) payload["content"] = content;
    if (publishAt) payload["publishAt"] = publishAt;

    return this.doRequest<NewsPatchResponse>("PATCH", `/news/${recordID}`, payload);
  }

  //////////////////////////////////////////
  // Permission
  //////////////////////////////////////////
  public async permissionGetMany(): Promise<Request<PermissionResponse[]>> {
    let url = `/permissions?order=desc&field=id&skip=0&limit=25`;

    return this.doRequest<PermissionResponse[]>("GET", url, null);
  }

  //////////////////////////////////////////
  // Booking
  //////////////////////////////////////////
  public async bookingGet(recordID: UUID): Promise<Request<BookingGetResponse>> {
    const url = `/bookings/${recordID}`;

    return this.doRequest<BookingGetResponse>("GET", url, null);
  }

  public async bookingDelete(recordID: UUID): Promise<Request<DeletedResponse>> {
    const url = `/bookings/${recordID}`;

    return this.doRequest<DeletedResponse>("DELETE", url, null);
  }

  public async bookingBookingOverview(date: Date): Promise<Request<BookingGetResponse[]>> {
    const usedDate = Utils.formatDateForDatePicker(date);
    let url = `/bookings?date=${usedDate}`;

    return this.doRequest<BookingGetResponse[]>("GET", url, null);
  }

  public async bookingCreate(
    apartmentID: UUID,
    status: number,
    fromDate: Date,
    toDate: Date,
    adultAmount: number,
    childAmount: number,
    petAmount: number
  ): Promise<Request<BookingPostResponse>> {
    const payload = {
      apartmentID,
      status,
      fromDate,
      toDate,
      adultAmount,
      childAmount,
      petAmount,
    };

    return this.doRequest<BookingPostResponse>("POST", `/bookings`, payload);
  }

  public async bookingUpdate(
    recordID: string,
    status?: number,
    fromDate?: Date,
    toDate?: Date,
    adultAmount?: number,
    childAmount?: number,
    petAmount?: number
  ): Promise<Request<BookingPatchResponse>> {
    const payload = {} as any;
    if (status != undefined) payload["status"] = status;
    if (fromDate) payload["fromDate"] = fromDate;
    if (toDate) payload["toDate"] = toDate;
    if (adultAmount) payload["adultAmount"] = adultAmount;
    if (childAmount) payload["childAmount"] = childAmount;
    if (petAmount) payload["petAmount"] = petAmount;

    return this.doRequest<BookingPatchResponse>("PATCH", `/bookings/${recordID}`, payload);
  }

  public async bookingAddMessage(recordID: string, text: string): Promise<Request<BookingPatchResponse>> {
    const payload = { text } as any;

    return this.doRequest<BookingPatchResponse>("PATCH", `/bookings/${recordID}/message`, payload);
  }

  //////////////////////////////////////////
  // Settings
  //////////////////////////////////////////
  public async settingsNotificationMessage(): Promise<Request<Translation | null>> {
    return this.doRequest<Translation | null>("GET", `/settings/notification`, null);
  }

  //////////////////////////////////////////
  // ProcessLogs
  //////////////////////////////////////////
  public async processLog(userID: UUID): Promise<Request<ProcessLog[]>> {
    return this.doRequest<ProcessLog[]>("GET", `/processLog/${userID}`, null);
  }
}
