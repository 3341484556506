import styles from "./component.module.css";
import { ReadOnlyApartmentAttribute } from "../../../../infrastructure/types";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import Utils from "../../../../infrastructure/utils";
import { Locale } from "../../../../infrastructure/enums";

export type VerticalAttriuteViewProps = {
  attributes: ReadOnlyApartmentAttribute[];
  activeLocale: Locale;
};

function VerticalAttriuteViewView(props: VerticalAttriuteViewProps) {
  const { translateObj } = TranslationHandler();
  const { attributes, activeLocale } = props;

  const buildAttributeItemSvgs = (): JSX.Element[] => {
    const items: JSX.Element[] = [];
    if (!Array.isArray(attributes)) return items;

    const attrChunks = Utils.chunk(attributes, 4);
    let posCounter = 0;
    for (const attributes of attrChunks) {
      const columnItems: JSX.Element[] = [];

      for (const attribute of attributes) {
        const { svg, name } = attribute;

        const item = (
          <Col key={posCounter} style={{ display: "flex" }}>
            <div className={styles.svg} dangerouslySetInnerHTML={{ __html: svg }} /> {translateObj(name, activeLocale)}
          </Col>
        );
        posCounter++;

        columnItems.push(item);
      }

      items.push(<Row key={posCounter}>{columnItems}</Row>);
    }

    return items;
  };

  return (
    <Container fluid style={{ padding: 0, paddingTop: "0.5em" }}>
      {buildAttributeItemSvgs()}
    </Container>
  );
}

export default VerticalAttriuteViewView;
