import { Button, Col, Form, Row } from "react-bootstrap";
import { SaisonEntry } from "../../../../../infrastructure/types";
import SaisonTypePicker from "../../SaisonTypePicker/SaisonTypePicker";
import Utils from "../../../../../infrastructure/utils";
import styles from "./component.module.css";
import { useEffect, useState } from "react";
import { SaisonType } from "../../../../../infrastructure/enums";
import AddIcon from "../../../../Icons/AddIcon";
import { TranslationHandler } from "../../../../Utils/TranslationProvider";

type SaisonEntryAddItemProps = {
  hasConflictWithError: string | null;
  onAddClicked(newSaisonEntry: SaisonEntry): void;
};

type FormValues = {
  type: SaisonType;
  fromDate: Date;
  toDate: Date;
};

function SaisonEntryAddItem(props: SaisonEntryAddItemProps) {
  const { translate } = TranslationHandler();
  const { hasConflictWithError, onAddClicked } = props;

  const [formValues, setFormValues] = useState<FormValues>({ fromDate: new Date(), toDate: new Date(), type: SaisonType.LOW });

  const handleDateHasChanged = (event: any, fieldName: "fromDate" | "toDate") => {
    const value = new Date(event.target.value);
    let fromDate = formValues.fromDate;
    let toDate = formValues.toDate;

    switch (fieldName) {
      case "fromDate":
        const isFromDateSwitchNeeded = toDate < value;
        fromDate = isFromDateSwitchNeeded ? fromDate : value;
        toDate = isFromDateSwitchNeeded ? value : toDate;
        break;

      case "toDate":
        const isToDateSwitchNeeded = fromDate > value;
        fromDate = isToDateSwitchNeeded ? value : fromDate;
        toDate = isToDateSwitchNeeded ? toDate : value;
        break;
    }

    // transform date to be ensure that the change detaction works as accpected
    const usedFromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 3);
    const usedToDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 3);

    setFormValues({ ...formValues, fromDate: usedFromDate, toDate: usedToDate });
  };

  const handleAddClicked = (event: any) => {
    event.preventDefault();

    const { fromDate, toDate, type } = formValues;

    const newSaisonEntry: SaisonEntry = { type, fromDate, toDate };
    onAddClicked(newSaisonEntry);
  };

  useEffect(() => {
    const usedFormValues: FormValues = {
      fromDate: new Date(),
      toDate: new Date(),
      type: SaisonType.LOW,
    };

    setFormValues(usedFormValues);
  }, []);

  return (
    <div key={"saisonEntryAddItem"}>
      <Row className={styles.pullDown}>
        <Col xs={3}>
          <SaisonTypePicker
            pos={-1}
            onChanged={(saisonType: number) => setFormValues({ ...formValues, type: Number(saisonType) })}
            preSelectType={formValues.type}
          />
        </Col>
        <Col xs={1} className={styles.pullDown}>
          {translate("saison_label_from")}
        </Col>
        <Col xs={3}>
          <Form.Control type="date" value={Utils.formatDateForDatePicker(formValues.fromDate)} onChange={(e: any) => handleDateHasChanged(e, "fromDate")} />
        </Col>
        <Col xs={1} className={styles.pullDown}>
          {translate("saison_label_to")}
        </Col>
        <Col xs={3}>
          <Form.Control type="date" value={Utils.formatDateForDatePicker(formValues.toDate)} onChange={(e: any) => handleDateHasChanged(e, "toDate")} />
        </Col>
        <Col>
          <Button className={styles.btn} onClick={(e: any) => handleAddClicked(e)} size="sm" variant="outline-white">
            <AddIcon />
          </Button>
        </Col>
      </Row>
      {hasConflictWithError && hasConflictWithError}
    </div>
  );
}

export default SaisonEntryAddItem;
