// icon:arrows_left | Linea Iconset https://linea.io/ | Benjamin Sigidi
import styles from "./component.module.css";
import * as React from "react";

function ArrowLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={`${styles.deleteIcon}`} viewBox="0 0 64 64" fill="currentColor" height="1em" width="1em" {...props}>
      <path fill="none" stroke="currentColor" strokeLinejoin="bevel" strokeMiterlimit={10} strokeWidth={2} d="M37 15L20 32l17 17" />
    </svg>
  );
}

export default ArrowLeftIcon;
