import { Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import HistoryItem from "./HistoryItem";
import { News, UUID } from "../../../infrastructure/types";
import { useEffect, useState } from "react";
import HistoryAddItem from "./HistoryAddItem";
import Menu from "./Menu/Menu";

type HistoryProps = {
  news: News[];

  addClicked(): void;
  childClicked(news: News): void;
  onSearchTyped(search: string): void;
};

type FormValues = {
  news: News[];
};

function ListView(props: HistoryProps) {
  const { news, childClicked, addClicked, onSearchTyped } = props;
  const [formValues, setFormValues] = useState<FormValues>({ news });

  const buildEntries = (newsList: News[]): JSX.Element[] => {
    const entries: JSX.Element[] = [<HistoryAddItem key="historyAddItem" onClicked={addClicked} />];
    for (const news of newsList) {
      const entry = <HistoryItem key={news.id} news={news} onClicked={(news: News) => childClicked(news)} />;
      entries.push(entry);
    }

    return entries;
  };

  useEffect(() => {
    setFormValues({ news });
  }, [news]);

  return (
    <Container fluid>
      <Row className={`${styles.container} g-4`}>
        <Menu onSearchTyped={onSearchTyped} />
      </Row>

      <Row className={`${styles.container}`}>{buildEntries(formValues.news)}</Row>
    </Container>
  );
}

export default ListView;
