// icon:people-sharp | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from "react";

function IconPeopleSharp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M224 184 A72 72 0 0 1 152 256 A72 72 0 0 1 80 184 A72 72 0 0 1 224 184 z" />
      <path d="M234 296c-28.16-14.3-59.24-20-82-20-44.58 0-136 27.34-136 82v42h150v-16.07c0-19 8-38.05 22-53.93 11.17-12.68 26.81-24.45 46-34z" />
      <path d="M340 288c-52.07 0-156 32.16-156 96v48h312v-48c0-63.84-103.93-96-156-96z" />
      <path d="M428 168 A88 88 0 0 1 340 256 A88 88 0 0 1 252 168 A88 88 0 0 1 428 168 z" />
    </svg>
  );
}

export default IconPeopleSharp;
