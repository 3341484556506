import { BookingMessage } from "../../../../../infrastructure/types";

import styles from "./component.module.css";
import { Col, Row } from "react-bootstrap";
import Utils from "../../../../../infrastructure/utils";

type MessagesProps = {
  messages: BookingMessage[];
};

const SendFromUserItem = (props: { message: BookingMessage }) => {
  const { message } = props;

  return (
    <>
      <Col xs={5} className={styles.messageFromUser}>
        <div>{Utils.formatDate(message.timestamp)}</div>
        <hr className={styles.messageDate} />
        {message.text}
      </Col>
      <Col xs={1} />
      <Col xs={6}></Col>
    </>
  );
};

const SendFromUsItem = (props: { message: BookingMessage }) => {
  const { message } = props;

  return (
    <>
      <Col xs={6}></Col>
      <Col xs={1} />
      <Col xs={5} className={styles.messageFromUs}>
        <div>{Utils.formatDate(message.timestamp)}</div>
        <hr className={styles.messageDate} />
        {message.text}
      </Col>
    </>
  );
};

function Messages(props: MessagesProps) {
  const { messages } = props;

  return (
    <Row className={styles.pullDown25em}>
      {messages.map((message, index) =>
        message.sendFromUser ? <SendFromUserItem key={index} message={message} /> : <SendFromUsItem key={index} message={message} />
      )}
    </Row>
  );
}

export default Messages;
