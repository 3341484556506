import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { UUID } from "../../../../../infrastructure/types";
import EditModal from "./EditModal/EditModal";

import styles from "./component.module.css";
import { TranslationHandler } from "../../../../Utils/TranslationProvider";

interface ImageUploaderProps {
  disabled: boolean;

  uploadedPictureIDs: (pictureIDs: UUID[]) => void;
}

function ImageUploader(props: ImageUploaderProps) {
  const { uploadedPictureIDs, disabled } = props;
  const { translate } = TranslationHandler();

  const [imageStringsToCrop, setImageStringsToCrop] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleUploadedPictureIDs = (pictureIDs: UUID[]) => {
    if (pictureIDs === null) return;

    uploadedPictureIDs(pictureIDs);
    setShowModal(false);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const blobUrls: string[] = [];
    for (const file of acceptedFiles) if (file) blobUrls.push(URL.createObjectURL(file));

    setImageStringsToCrop(blobUrls);
    setShowModal(true);
  }, []);

  const { getRootProps, open } = useDropzone({
    onDrop,
    maxSize: 5000000,
    noClick: false,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });

  const usedDropzoneProps = disabled ? {} : getRootProps();
  const usedTRKey = disabled ? "realestate_picture_handler_disabled" : "realestate_picture_handler_drag_and_drop";

  return (
    <>
      {imageStringsToCrop.length !== 0 && (
        <EditModal show={showModal} close={() => setShowModal(false)} imageStringsToCrop={imageStringsToCrop} uploadedPictureIDs={handleUploadedPictureIDs} />
      )}

      {/*  getRootProps initiate the dropzone */}
      <div {...usedDropzoneProps} style={{ textAlign: "center", cursor: "pointer" }} onClick={() => open()}>
        <div className={styles.noImage} style={{ height: "3.7em" }}>
          <strong style={{ position: "relative", top: "1em" }}>{translate(usedTRKey)}</strong>
        </div>
      </div>
    </>
  );
}

export default ImageUploader;
