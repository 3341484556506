import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { NameToValue, Translation, UUID } from "../../../../infrastructure/types";

import styles from "./component.module.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import SwitchIcon from "../../../Icons/SwitchIcon";

type AttributeModalProps = {
  showModal: boolean;
  modalTitleTranslationKey: string;

  attributeIDs: UUID[];
  attributeMap: NameToValue<Translation>;

  handleAssumeItems: (activeAttributeIDs: UUID[]) => void;
  close: () => void;
};

function AttributeModal(props: AttributeModalProps) {
  const { handleAssumeItems, close, modalTitleTranslationKey, showModal, attributeIDs, attributeMap } = props;
  const { translateObj, translate } = TranslationHandler();

  const [show, setShow] = useState<boolean>(false);

  const [usedActiveAttributeIDsCollection, setUsedActiveAttributeIDsCollection] = useState<Set<UUID>>(new Set());
  const [usedInActivedAttributeIDsCollection, setUsedInActiveAttributeIDsCollection] = useState<Set<UUID>>(new Set());

  const [changedActiveAttrIDsCache, setChangedAttrIDsCache] = useState<Set<UUID>>(new Set());
  const [changedInActiveAttrIDsCache, setChangedInAttrIDsCache] = useState<Set<UUID>>(new Set());

  const ref = useRef<Array<HTMLInputElement | null>>([]);

  const handleCheckBoxOnChanged = (wasEnabled: boolean, itemID: UUID) => {
    // we are only interested in truthy changes
    if (!wasEnabled) return;

    const cachedActiveAttrCollection = new Set(changedActiveAttrIDsCache);
    const cachedInActiveAttrCollection = new Set(changedInActiveAttrIDsCache);

    const wasActiveAttribute = changedActiveAttrIDsCache.has(itemID);

    if (!wasActiveAttribute) {
      cachedActiveAttrCollection.add(itemID);
      cachedInActiveAttrCollection.delete(itemID);
    } else {
      cachedActiveAttrCollection.delete(itemID);
      cachedInActiveAttrCollection.add(itemID);
    }

    setChangedAttrIDsCache(cachedActiveAttrCollection);
    setChangedInAttrIDsCache(cachedInActiveAttrCollection);
  };

  const handleMoveItems = () => {
    // clear checkboxes
    for (const checkboxRef of ref.current) {
      if (!checkboxRef) continue;

      checkboxRef.checked = false;
    }

    ref.current = Array(ref.current.length).fill(null);
    setUsedActiveAttributeIDsCollection(changedActiveAttrIDsCache);
    setUsedInActiveAttributeIDsCollection(changedInActiveAttrIDsCache);
  };

  const handleAssumeSelectedItems = () => {
    handleAssumeItems([...usedActiveAttributeIDsCollection]);
    close();
  };

  const buildAttributeItem = (attributeID: UUID, name: Translation, position: number): JSX.Element => {
    return (
      <Form.Check
        type="checkbox"
        key={attributeID}
        id={attributeID}
        ref={(el: HTMLInputElement) => (ref.current[position] = el)}
        label={translateObj(name)}
        onChange={(e) => handleCheckBoxOnChanged(e.target.checked, attributeID)}
      />
    );
  };

  const buildAttributeItems = (attributeMap: NameToValue<Translation>, attributeIDsCollection: Set<UUID>): JSX.Element[] => {
    const checkBoxItems: JSX.Element[] = [];
    let positionCounter = 0;

    for (const [attributeID, name] of attributeMap) {
      const shouldBeAdded = attributeIDsCollection.has(attributeID);
      if (!shouldBeAdded) continue;

      positionCounter++;

      const checkBoxItem = buildAttributeItem(attributeID, name, positionCounter);
      checkBoxItems.push(checkBoxItem);
    }

    return checkBoxItems;
  };

  const prepareAttributeOverview = (attributeMap: NameToValue<Translation>, attributeIDs: UUID[]): void => {
    const inActiveIDsCollection: Set<UUID> = new Set();
    const activeIDsCollection: Set<UUID> = new Set();

    for (const attributeID of attributeMap.keys()) {
      const isActive = attributeIDs.includes(attributeID);
      if (isActive) {
        activeIDsCollection.add(attributeID);
      } else {
        inActiveIDsCollection.add(attributeID);
      }
    }

    setUsedActiveAttributeIDsCollection(activeIDsCollection);
    setUsedInActiveAttributeIDsCollection(inActiveIDsCollection);

    setChangedAttrIDsCache(activeIDsCollection);
    setChangedInAttrIDsCache(inActiveIDsCollection);
  };

  useEffect(() => {
    setShow(showModal);
    prepareAttributeOverview(attributeMap, attributeIDs);
  }, [showModal, attributeIDs, attributeMap]);

  return (
    <Modal show={show} onHide={close} aria-labelledby="contained-modal-title-vcenter" size="lg" centered keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{translate(modalTitleTranslationKey)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className={`${styles.pushDown} d-flex justify-content-center`}>
            <Col xs={5} className={styles.pullLeft}>
              <h3>{translate("modal_label_inActiveAttributes")}</h3>
            </Col>
            <Col xs={1} />
            <Col xs={5} className={styles.pullLeft}>
              <h3>{translate("modal_label_activeAttributes")}</h3>
            </Col>
          </Row>
          <Form>
            <Row className="d-flex justify-content-center">
              <Col className={styles.content} xs={5}>
                {buildAttributeItems(attributeMap, usedInActivedAttributeIDsCollection)}
              </Col>
              <Col className={styles.pushRight} xs={1}>
                <Button className={styles.btnSwitch} size="sm" variant="outline-dark" onClick={handleMoveItems}>
                  <SwitchIcon />
                </Button>
              </Col>
              <Col className={styles.content} xs={5}>
                {buildAttributeItems(attributeMap, usedActiveAttributeIDsCollection)}
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {translate("modal_btn_abort")}
        </Button>
        <Button variant="primary" onClick={handleAssumeSelectedItems}>
          {translate("modal_btn_ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AttributeModal;
