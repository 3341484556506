// icon:house-flag | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from "react";

function IconHouseFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 640 512" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M480 0c-17.7 0-32 14.3-32 32v480h64V192h112c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H512c0-17.7-14.3-32-32-32zm-64 159L276.8 39.7c-12-10.3-29.7-10.3-41.7 0l-224 192C1 240.4-2.7 254.5 2 267.1S18.6 288 32 288h32v192c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32v-96c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v96c0 17.7 14.3 32 32 32h64.9-1V159z" />
    </svg>
  );
}

export default IconHouseFlag;
