import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios";
import Utils from "../../../infrastructure/utils";
import { Translation, UUID } from "../../../infrastructure/types";
import { errorResponseCollection } from "../errorResponseCollection";

// Axios response interceptor
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // Transform ISO string dates back to Date objects in the response
    if (response.data && typeof response.data === "object") {
      transformDatesInObject(response.data);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Recursive function to transform ISO string dates to Date objects
function transformDatesInObject(obj: any) {
  for (const key in obj) {
    if (obj[key] instanceof Object) {
      transformDatesInObject(obj[key]);
    } else if (typeof obj[key] === "string" && isISODateString(obj[key])) {
      obj[key] = new Date(obj[key]);
    }
  }
}

// Function to check if a string is an ISO date string
function isISODateString(value: string) {
  // https://regex101.com/r/iYLT1U/1
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  return isoDateRegex.test(value);
}

function getErrorTranslationKey<P>(error: AxiosError<AError<P>>): string {
  const defaultErrorKey = "default_error";

  const errorData = error?.response?.data;
  if (!errorData) return defaultErrorKey;

  const identifier = `${errorData.domain}.${errorData.error}`;
  const translationKey = errorResponseCollection.get(identifier) ?? defaultErrorKey;

  return translationKey;
}

export type AError<T> = {
  data: T;
  domain: string;
  error: string;
  timestamp: string;
  correlationID: string;
};

export type Request<Response> = {
  data: Response;
  headers?: RawAxiosResponseHeaders | AxiosResponseHeaders;
  error: AxiosError<AError<Response>>;
  errorTRKey: string;
  timestamp: string;
  correlationID: string;
};

export default class ApiClientCore {
  private requestUrl: string;

  public async doRequest<P>(method: string, path: string, payload: any): Promise<Request<P>> {
    const headers: any = { "Content-Type": "application/json" };

    const token = Utils.getTokenFromCookie();
    if (token) headers["Authorization"] = `Bearer ${token}`;

    const config: AxiosRequestConfig = {
      method,
      maxBodyLength: Infinity,
      url: this.requestUrl + path,
      headers,
    };

    const hasPayload = payload != null;
    if (hasPayload) config.data = payload;

    let responseData = {
      data: null,
      headers: null,
      error: null,
      errorTRKey: null,
      timestamp: 0,
      correlationID: "<UNKNOWN>",
    } as unknown as Request<P>;

    try {
      const response: AxiosResponse = await axios.request(config);
      const { data, headers } = response;
      responseData.data = data.data;
      responseData.headers = headers;
      responseData.correlationID = data.correlationID;
    } catch (error) {
      const usedError = error as AxiosError<AError<P>>;

      if (usedError.status === 401) {
        console.error("You are not logged in (any more)");
        Utils.setAsLogout();

        return responseData;
      }

      responseData.correlationID = usedError?.response?.data?.correlationID as string;
      responseData.error = usedError;
      responseData.errorTRKey = getErrorTranslationKey<P>(usedError);
    }

    return responseData;
  }

  public async upload<P>(file: File, description: Translation, path: string, isOrigin?: boolean, recordID?: UUID): Promise<Request<P>> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", JSON.stringify(description));
    formData.append("isOrigin", isOrigin ? "true" : "false");
    formData.append("recordID", recordID ? recordID : "");

    const token = Utils.getTokenFromCookie();
    const headers: any = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    const config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: this.requestUrl + path,
      headers,
      data: formData,
    };

    let responseData = {
      data: null,
      error: "",
      timestamp: 0,
    } as unknown as Request<P>;

    try {
      const response: AxiosResponse = await axios.request(config);
      responseData = response.data;
    } catch (error) {
      const usedError = error as AxiosError<AError<P>>;

      if (usedError.status === 401) Utils.setAsLogout();

      responseData.correlationID = usedError?.response?.data?.correlationID as string;
      responseData.error = usedError;
      responseData.errorTRKey = getErrorTranslationKey<P>(usedError);
    }

    return responseData;
  }

  public constructor(requestUrl: string) {
    this.requestUrl = requestUrl;
  }
}
