import Cookies from "js-cookie";
import isEqual from "deep-equal";

import { JwtPayload, jwtDecode } from "jwt-decode";
import Identity from "./identity";
import { Locale } from "./enums";
import { UUID, UUIDOrNull } from "./types";

const SelectedSiteBarEntry = "SSB";
const CookieTokenName = "T";
const IdentityName = "I";

type CustomJWTPayload = JwtPayload & { permissions: string[] };

class Utils {
  public static isLoggedIn(): boolean {
    const token = Utils.getTokenFromCookie();
    if (!token) return false;

    const { exp } = jwtDecode(token);
    const expireDate = new Date((exp as number) * 1000).getTime();
    const currentTime = new Date().getTime();

    const isTokenExpired = expireDate >= currentTime;
    return isTokenExpired;
  }

  public static updateIdentity(token: string, firstName: string, lastName: string, adminType: number): void {
    const userName = `${firstName} ${lastName}`;
    Utils.setIdentityToLocalStorage(token, userName, adminType);
  }

  public static setAsLoggedIn(token: string, userName?: string): Identity {
    const identity = Utils.setIdentityToLocalStorage(token, userName);
    Utils.setTokenCookie(token);

    return identity;
  }

  public static setAsLogout(): void {
    Utils.removeIndentityFromLocalStorage();
    Utils.removeTokenCookie();
  }

  public static getIdentityFromLocalStorage(): Identity | null {
    const storageEntry = localStorage.getItem(IdentityName);
    if (!storageEntry) return null;

    // Retrieve the identity object from localStorage
    return Identity.buildFrom(storageEntry);
  }

  public static getLocale(): Locale {
    const identity = Utils.getIdentityFromLocalStorage();

    return identity?.getLocale() ?? Locale.enGB;
  }

  public static getActiveUserIDFromToken(): UUIDOrNull {
    const token = Utils.getTokenFromCookie();
    if (!token) return null;

    const { sub } = jwtDecode(token) as CustomJWTPayload;

    return sub as UUID;
  }

  private static setTokenCookie(token: string): void {
    const { exp } = jwtDecode(token);
    const expireDate = new Date((exp as number) * 1000);

    Cookies.set(CookieTokenName, token, { expires: expireDate });
  }

  public static getTokenFromCookie(): string | undefined {
    return Cookies.get(CookieTokenName);
  }

  private static removeTokenCookie(): void {
    Cookies.remove(CookieTokenName);
  }

  private static setIdentityToLocalStorage(token: string, userName?: string, adminType?: number): Identity {
    const { permissions } = jwtDecode(token) as CustomJWTPayload;
    const isRealEstateOwner = adminType === 2;

    const identity = new Identity({ permissions, userName, isREOwner: isRealEstateOwner });

    // Save the identity object to localStorage
    localStorage.setItem(IdentityName, JSON.stringify(identity));

    return identity;
  }

  private static removeIndentityFromLocalStorage(): void {
    localStorage.removeItem(IdentityName);
  }

  public static setSelectedSideBarItemToLocalStorage(selectedItemName: string): void {
    localStorage.setItem(SelectedSiteBarEntry, selectedItemName);
  }

  public static getSelectedSideBarItemToLocalStorage(): string {
    const storageEntry = localStorage.getItem(SelectedSiteBarEntry);
    const defaultSelection = "booking";

    const isNotValidEntry = storageEntry === null || storageEntry === undefined || storageEntry === "";

    return isNotValidEntry ? defaultSelection : storageEntry;
  }

  public static delayCall(func: Function, timeout: NodeJS.Timeout, delayInMs = 300): NodeJS.Timeout {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func();
    }, delayInMs);

    return timeout;
  }

  public static async sleep(sleepInMs = 300): Promise<void> {
    new Promise((resolve) => setTimeout(resolve, sleepInMs));
  }

  public static formatDate(date: Date | undefined): string {
    if (date === undefined) return "N/A";

    const formatedDate = new Date(date);

    const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
    const _date = formatedDate.toLocaleDateString("de-DE", options);

    const _timestamp = formatedDate.toLocaleTimeString();

    const transformed = `${_date} ${_timestamp}`;
    return transformed;
  }

  public static formatDateForSaison(date: Date | undefined): string {
    if (date === undefined) return "N/A";

    const formatedDate = new Date(date);

    const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
    const _date = formatedDate.toLocaleDateString("de-DE", options).substring(0, 6);

    const transformed = `${_date}`;
    return transformed;
  }

  public static formatDateForDatePicker(dateToFormat: Date): string {
    const isUnvalidDate = dateToFormat === undefined;
    if (isUnvalidDate) dateToFormat = new Date();

    const usedDate = new Date(dateToFormat);

    const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
    const _date = usedDate.toLocaleDateString("de-DE", options);

    const dateSplit = _date.split(".");

    const transformed = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
    return transformed;
  }

  public static formatDateForTimePicker(dateToFormat: Date, selector: "minute" | "hour"): string {
    const isDate = typeof dateToFormat !== "string";
    if (!isDate) dateToFormat = new Date();

    const isoDate = dateToFormat.toLocaleTimeString();

    // 21:09:54
    let transformed = "00";
    switch (selector) {
      case "minute":
        transformed = isoDate.substring(3, 5);
        break;

      case "hour":
        transformed = isoDate.substring(0, 2);
        break;
    }

    return transformed;
  }

  public static buildDateFrom(baseDate: string, hours: string, minutes: string): Date {
    // Ensure the input values are valid numbers
    const parsedHours = parseInt(hours, 10);
    const parsedMinutes = parseInt(minutes, 10);

    // Check if the parsed values are valid numbers
    if (isNaN(parsedHours) || isNaN(parsedMinutes)) {
      throw new Error("Invalid input for hours or minutes");
    }

    // Create a new Date object with the same date as the baseDate
    const newDate = new Date(baseDate);

    // Set the hours and minutes of the new Date object
    newDate.setHours(parsedHours);
    newDate.setMinutes(parsedMinutes);

    return newDate;
  }

  public static onlyNumericsAllowed(input: string): boolean {
    const isInputAllowed = /^[0-9]+(\.[0-9]*)?$/.test(input) || input === "";
    return isInputAllowed;
  }

  public static roundNumber(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  public static isValidEmail(email: string): boolean {
    const emailRegex: RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailRegex.test(email);
  }

  public static scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' or 'smooth' for the scrolling behavior
    });
  }

  public static areObjEqual(objA: any, objB: any): boolean {
    // dont know why, but the lib works but we need to invert the result
    return !isEqual(objA, objB);
  }

  public static chunk(array: any, chunkSize: number): any {
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }

    return result;
  }
}

export default Utils;
