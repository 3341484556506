import { Button, Col, Container, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "./component.module.css";
import ApiClient from "../../../../interface/client/apiClient";
import { MinimalApartment } from "../../../../infrastructure/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type ApartmentOverviewProps = {
  apartment: MinimalApartment;
};

function ApartmentOverview(props: ApartmentOverviewProps) {
  const Navigate = useNavigate();

  const { id, name, pictureIDs } = props.apartment;
  const mainPictureID = pictureIDs[0];

  const [usedImg, setUsedImg] = useState<string>("/FeWoServ_logo.png");

  useEffect(() => {
    const loadPicture = async () => {
      const { data, error } = await ApiClient().pictureGetThumbnail(mainPictureID);
      if (error === null) setUsedImg(data.raw);
    };

    loadPicture();
  }, [pictureIDs]);

  const needATrim = name.length >= 18;
  const usedName = needATrim ? `${name.substring(0, 18)} ...` : name;

  const jumpToApartment = () => Navigate(`/apartment/${id}`);

  return (
    <OverlayTrigger
      key={"apartmentName"}
      placement={"right"}
      overlay={
        <Tooltip id={`tooltip-apartmentName`}>
          <strong>{name}</strong>
        </Tooltip>
      }
    >
      <Container fluid style={{ padding: 0, cursor: "pointer" }} onClick={() => jumpToApartment()}>
        <Row>
          <Col xs={3}>
            <Image style={{ height: "3em", width: "5em", objectFit: "cover", border: "1px solid white", borderRadius: "20px 0 0 20px" }} src={usedImg} />
          </Col>
          <Col style={{ marginLeft: "0.7em", marginTop: "0.5em" }}>
            <Button size="sm" variant="white" className={styles.nameOverlayBtn}>
              {usedName}
            </Button>
          </Col>
        </Row>
      </Container>
    </OverlayTrigger>
  );
}

export default ApartmentOverview;
