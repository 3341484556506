import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Utils from "../../../infrastructure/utils";

import styles from "./component.module.css";
import HeaderItem from "./HeaderItem/HeaderItem";
import ArrowLeftIcon from "../../Icons/ArrowLeftIcon";
import ArrowRightIcon from "../../Icons/ArrowRightIcon";
import BookingItem, { ViewPortDates } from "./BookingItem/BookingItem";
import { BookingMessage, BookingSummary, MinimalApartment, Saison, UUID, UUIDOrNull } from "../../../infrastructure/types";
import EditModal from "./EditModal/EditModal";
import { Mode } from "../../../interface/client/enums";
import EmptyItem from "./EmptyItem/EmptyItem";
import ApiClient from "../../../interface/client/apiClient";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import BookingItemDisabled from "./BookingItem/BookingItemDisabled";
import { toastErrorWithCorrelationID } from "../../Utils/ToastContainerFactory/ToastContainerFactory";
import ApartmentOverview from "./ApartmentOverview/ApartmentOverview";
import RefreshIcon from "../../Icons/RefreshIcon";
import { BookingStatus } from "../../../infrastructure/enums";

type BookingCollection = Map<UUID, Map<string, BookingSummary>>;
type ApartmentCollection = Map<UUID, MinimalApartment>;
type BookingOverviewCollection = Map<UUID, Map<UUID, BookingSummary>>;

const monthMap = new Map([
  [0, "january"],
  [1, "february"],
  [2, "march"],
  [3, "april"],
  [4, "may"],
  [5, "june"],
  [6, "july"],
  [7, "august"],
  [8, "september"],
  [9, "october"],
  [10, "november"],
  [11, "december"],
]);

const oneDayMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

type ComponentValues = {
  loading: boolean;
  fromDate: Date | null;
  dates: Date[];
  showModal: boolean;
  mode: Mode;
  usedBookingSummary: BookingSummary | null;
  usedApartmentID: UUIDOrNull;

  saisonCollection: Pick<Saison, "entries">;
  apartmentCollection: ApartmentCollection;
  bookingOverviewCollection: BookingOverviewCollection;

  bookingCollection: BookingCollection;
};

const HorizontalCalendar: React.FC = () => {
  const { translate } = TranslationHandler();
  const renderForApartmentOwner = Utils.getIdentityFromLocalStorage()?.isRealEstateOwner() as boolean;

  const [compValues, setCompValues] = useState<ComponentValues>({
    showModal: false,
    mode: Mode.DEFAULT,
    usedBookingSummary: null,
    fromDate: null,
    dates: [],
    loading: true,
    bookingCollection: new Map(),
    apartmentCollection: new Map(),
    usedApartmentID: null,
    saisonCollection: { entries: [] },
    bookingOverviewCollection: new Map(),
  });

  useEffect(() => {
    const loadData = async () => {
      if (!compValues.loading) return;

      // apartments
      const apartmentCollection: ApartmentCollection = compValues.apartmentCollection.size === 0 ? compValues.apartmentCollection : new Map();
      if (apartmentCollection.size === 0) {
        const apartmentRequest = await ApiClient().apartmentGetMany();
        if (apartmentRequest.error === null) {
          for (const apartment of apartmentRequest.data) apartmentCollection.set(apartment.id, apartment);
        } else {
          toastErrorWithCorrelationID(translate(apartmentRequest.errorTRKey), apartmentRequest.correlationID);
        }
      }

      // saison
      const saisonCollection: Pick<Saison, "entries"> = compValues.saisonCollection;
      if (saisonCollection.entries.length === 0) {
        const saisonRequest = await ApiClient().saisonGetMany();
        if (saisonRequest.error === null) {
          saisonCollection.entries = [];
          for (const saison of saisonRequest.data) for (const entry of saison.entries) saisonCollection.entries.push(entry);
        } else {
          toastErrorWithCorrelationID(translate(saisonRequest.errorTRKey), saisonRequest.correlationID);
        }
      }

      // bookingOverview
      let bookingOverviewCollection: BookingOverviewCollection = compValues.bookingOverviewCollection ?? new Map();
      const bookingOverviewRequest = await ApiClient().bookingBookingOverview(new Date());
      if (bookingOverviewRequest.error === null) {
        for (const bookingOverview of bookingOverviewRequest.data) {
          const { apartmentID, summaries } = bookingOverview;

          if (!bookingOverviewCollection.get(apartmentID)) bookingOverviewCollection.set(apartmentID, new Map());

          for (const bookingSummary of summaries) {
            const { bookingID } = bookingSummary;
            bookingOverviewCollection.get(apartmentID)?.set(bookingID, bookingSummary);
          }

          setCompValues({ ...compValues, bookingOverviewCollection, loading: false });
        }
      } else {
        toastErrorWithCorrelationID(translate(bookingOverviewRequest.errorTRKey), bookingOverviewRequest.correlationID);
      }

      const currentDate = compValues.fromDate ? compValues.fromDate : new Date();
      const dates = generateNextDates(currentDate);

      const bookingCollection: BookingCollection = new Map();
      for (const [apartmentID, apartment] of compValues.apartmentCollection) {
        if (!bookingCollection.has(apartmentID)) bookingCollection.set(apartmentID, new Map());

        const bookingSummaries = compValues.bookingOverviewCollection.get(apartmentID);
        if (!bookingSummaries) continue;

        for (const [_, bookingSummary] of bookingSummaries) {
          const identifier = Utils.formatDateForDatePicker(bookingSummary.fromDate);
          bookingCollection.get(apartmentID)?.set(identifier, bookingSummary);
        }
      }

      const usedCompValues: ComponentValues = {
        showModal: false,
        mode: Mode.DEFAULT,
        usedBookingSummary: null,
        dates,
        fromDate: currentDate,
        loading: false,
        usedApartmentID: null,
        saisonCollection,
        apartmentCollection,
        bookingOverviewCollection,
        bookingCollection,
      };
      setCompValues(usedCompValues);
    };

    loadData();
  }, [compValues]);

  const generateNextDates = (usedDate: Date): Date[] => {
    let usedStartingDate = usedDate;

    const nextDate = new Date(usedDate.getFullYear(), usedDate.getMonth(), usedDate.getDate(), 3);
    const futureDate = new Date(usedStartingDate.getFullYear(), usedStartingDate.getMonth(), usedStartingDate.getDate() + 19, 3);

    const dateMap: Date[] = [];

    while (nextDate <= futureDate) {
      dateMap.push(new Date(nextDate));
      nextDate.setDate(nextDate.getDate() + 1);
    }

    return dateMap;
  };

  const buildMonthOverview = (): string => {
    if (!compValues.fromDate) return "N/A";

    const month = compValues.fromDate.getMonth();
    const year = compValues.fromDate.getFullYear();

    const output = `${translate(monthMap.get(month) as string)} ${year}`;
    return output;
  };

  const buildViewPortDates = (): ViewPortDates => {
    const toDate = new Date(compValues.fromDate as Date);
    toDate.setDate(toDate.getDate() + 19);

    return { fromDate: compValues.fromDate as Date, toDate };
  };

  const handleTodayClicked = () => {
    const today = new Date();
    const dates = generateNextDates(today);

    setCompValues({ ...compValues, dates, fromDate: today });
  };

  const handleModifyDateClicked = (direction: "prev" | "next") => {
    const dateToModify = new Date(compValues.fromDate as Date);

    const offset = direction === "prev" ? -7 : 7;
    dateToModify.setDate(dateToModify.getDate() + offset);

    const dates = generateNextDates(dateToModify);

    setCompValues({ ...compValues, dates, fromDate: dateToModify });
  };

  const handleDateSelected = (selectedDateString: string) => {
    const selectedDate = new Date(selectedDateString);
    const dates = generateNextDates(selectedDate);

    setCompValues({ ...compValues, dates, fromDate: selectedDate });
  };

  const handleBookingItemClicked = (date: Date, apartmentID: UUID) => {
    const dummyBookingSummary: BookingSummary = {
      bookingID: "",
      bookingNumber: 0,
      fromDate: date,
      guestInfo: { adultAmount: 1, childAmount: 0, petAmount: 0 },
      priceSummary: { total: 0 },
      status: renderForApartmentOwner ? 6 : 0,
      stayDays: 0,
      toDate: date,
      userName: "",
      messages: [],
    };

    const identifier = Utils.formatDateForDatePicker(date);

    const bookingSummary = compValues.bookingCollection.get(apartmentID)?.get(identifier) ?? null;

    const usedMode = bookingSummary ? Mode.EDIT : Mode.ADD;
    const usedBookingSummary = bookingSummary ?? dummyBookingSummary;

    setCompValues({ ...compValues, mode: usedMode, usedBookingSummary, usedApartmentID: apartmentID, showModal: true });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // //
  const checkIsInThePast = (dateToCompare: Date): boolean => {
    const currentDate = new Date();
    const compDate = new Date(dateToCompare);

    currentDate.setHours(0, 0, 0, 0);
    compDate.setHours(0, 0, 0, 0);

    return compDate.getTime() < currentDate.getTime();
  };

  const calculateDaysBetweenDates = (startDate: Date, endDate: Date): number => {
    // Calculate the time difference in milliseconds
    const endDateTimestamp = endDate.getTime();
    const startDateTimestamp = startDate.getTime();

    let timeDifference = Math.abs(endDateTimestamp - startDateTimestamp);
    if (endDateTimestamp > startDateTimestamp) {
      timeDifference = Math.abs(startDateTimestamp - endDateTimestamp);
    }

    // Convert the time difference to days
    const daysDifference = Math.round(timeDifference / oneDayMilliseconds);

    return daysDifference + 1;
  };

  const areDatesEqual = (dateA: Date, dateB: Date): boolean => {
    const currentDate = new Date(dateA);
    const compDate = new Date(dateB);

    currentDate.setHours(0, 0, 0, 0);
    compDate.setHours(0, 0, 0, 0);

    return compDate.getTime() === currentDate.getTime();
  };

  const hasBooking = (dateToCompare: Date, bookingSummary: BookingSummary, viewPortDates: ViewPortDates) => {
    let isFirstDate = false;
    let daysLeft = bookingSummary.stayDays;

    const isInLeftSideInterval = dateToCompare >= bookingSummary.fromDate;
    const isInRightSideInterval = dateToCompare <= bookingSummary.toDate;
    let isBooked = isInLeftSideInterval && isInRightSideInterval;
    if (isBooked) {
      // check if the current date is the first date in the view port
      const isFirstDateInViewPort = areDatesEqual(dateToCompare, viewPortDates.fromDate);
      if (isFirstDateInViewPort) {
        daysLeft = calculateDaysBetweenDates(dateToCompare, bookingSummary.toDate);
        return { isBooked, isFirstDate: true, daysLeft };
      }

      isFirstDate = areDatesEqual(bookingSummary.fromDate, dateToCompare);
    }

    return { isBooked, isFirstDate, daysLeft };
  };

  const buildBookingOverview = (apartmentID: UUID) => {
    const viewportDates = buildViewPortDates();
    const bookingSummaries: Map<UUID, BookingSummary> = compValues.bookingOverviewCollection.get(apartmentID) ?? new Map();

    // we use a map to identify that a item already was added
    const items: Map<Date, JSX.Element> = new Map();
    for (const date of compValues.dates) {
      const isInPast = checkIsInThePast(date);

      let usedItem = (
        <EmptyItem apartmentID={apartmentID} key={Utils.formatDateForDatePicker(date)} date={date} isInPast={isInPast} onClicked={handleBookingItemClicked} />
      );

      for (const bookingSummary of bookingSummaries.values()) {
        const { isBooked, isFirstDate, daysLeft } = hasBooking(date, bookingSummary, viewportDates);

        if (isBooked && isFirstDate) {
          const isNotFinishedYet = bookingSummary.toDate <= new Date();

          usedItem = (
            <BookingItem
              apartmentID={apartmentID}
              key={Utils.formatDateForDatePicker(date)}
              date={date}
              isInPast={isInPast}
              isNotFinishedYet={isNotFinishedYet}
              daysLeft={daysLeft}
              bookingSummary={bookingSummary}
              onClicked={handleBookingItemClicked}
            />
          );

          if (renderForApartmentOwner && bookingSummary.status !== BookingStatus.BLOCKED_BY_ADMIN) {
            usedItem = (
              <BookingItemDisabled
                apartmentID={apartmentID}
                key={Utils.formatDateForDatePicker(date)}
                date={date}
                isInPast={isInPast}
                isNotFinishedYet={isNotFinishedYet}
                daysLeft={daysLeft}
                bookingSummary={bookingSummary}
                onClicked={handleBookingItemClicked}
              />
            );
          }
        }
      }

      const wasDateAlreadyAdded = items.has(date);
      if (wasDateAlreadyAdded) continue;

      items.set(date, usedItem);
    }

    return [...items.values()];
  };

  const handleModalClose = () => {
    setCompValues({ ...compValues, showModal: false, mode: Mode.DEFAULT, usedBookingSummary: null });
  };

  const handleOnMessageAdded = async (bookingID: UUID, bookingMessage: BookingMessage) => {
    const response = await ApiClient().bookingAddMessage(bookingID, bookingMessage.text);
    if (response.error !== null) {
      toastErrorWithCorrelationID(translate(response.errorTRKey), response.correlationID);

      return;
    }

    const { bookingOverviewCollection, usedApartmentID } = compValues;

    const cache = new Map(bookingOverviewCollection);

    cache
      .get(usedApartmentID as UUID)
      ?.get(bookingID)
      ?.messages.push(bookingMessage);
    setCompValues({ ...compValues, bookingOverviewCollection: cache });
  };

  const handleOnAssume = async (bookingSummary: BookingSummary) => {
    const { usedApartmentID } = compValues;
    const { bookingID, fromDate, guestInfo, status, toDate } = bookingSummary;

    if (compValues.mode === Mode.ADD) {
      const response = await ApiClient().bookingCreate(
        usedApartmentID as UUID,
        status,
        fromDate,
        toDate,
        guestInfo.adultAmount,
        guestInfo.childAmount,
        guestInfo.petAmount
      );
      if (response.error != null) {
        toastErrorWithCorrelationID(translate(response.errorTRKey), response.correlationID);
      }
    } else {
      const response = await ApiClient().bookingUpdate(bookingID, status, fromDate, toDate, guestInfo.adultAmount, guestInfo.childAmount, guestInfo.petAmount);
      if (response.error != null) {
        toastErrorWithCorrelationID(translate(response.errorTRKey), response.correlationID);
      }

      // if the booking was canceled, we will drop it from the overview collection
      if (status === 3) {
        const cache = new Map(compValues.bookingOverviewCollection);
        cache.get(usedApartmentID as UUID)?.delete(bookingID);

        setCompValues({ ...compValues, bookingOverviewCollection: cache });
      }
    }

    setCompValues({ ...compValues, showModal: false, mode: Mode.DEFAULT, usedBookingSummary: null, loading: true });
  };

  return (
    <>
      <EditModal
        renderForApartmentOwner={renderForApartmentOwner}
        bookingSummary={compValues.usedBookingSummary}
        apartmentID={compValues.usedApartmentID}
        close={handleModalClose}
        modalTitleTranslationKey={compValues.mode === Mode.EDIT ? "booking_modal_edit" : "booking_modal_add"}
        mode={compValues.mode}
        onAssume={handleOnAssume}
        onMessageAdded={handleOnMessageAdded}
        showModal={compValues.showModal}
        key={"editModalBooking"}
      />

      <Container fluid>
        {/* menu + Current month + year */}
        <Row>
          <Col xs={2} />

          <Col xs={3}>
            <InputGroup className="mb-3">
              <Button variant="outline-dark" className={styles.button} onClick={() => handleModifyDateClicked("prev")}>
                <ArrowLeftIcon className={styles.arrowLeft} />
              </Button>
              <Button variant="outline-dark" size="sm" onClick={() => handleTodayClicked()}>
                {translate("booking_timepicker_today")}
              </Button>
              <Form.Control
                className={styles.datePicker}
                type="date"
                value={Utils.formatDateForDatePicker(compValues.fromDate as Date)}
                onChange={(e: any) => handleDateSelected(e.target.value)}
                aria-label="Text input with checkbox"
              />
              <Button variant="outline-dark" size="sm" onClick={() => setCompValues({ ...compValues, loading: true })}>
                <RefreshIcon />
              </Button>
              <Button variant="outline-dark" className={styles.button} onClick={() => handleModifyDateClicked("next")}>
                <ArrowRightIcon className={styles.arrowRight} />
              </Button>
            </InputGroup>
          </Col>

          <h5 className={styles.year}>{buildMonthOverview()}</h5>
          <Col xs={1} />
        </Row>

        {/* calendar days overview */}
        <Row className={`${styles.pushDown} ${styles.disableWrap}`}>
          <Col xs={2}></Col>

          <Col className={styles.scrollContainer}>
            {compValues.dates.map((date, index) => (
              <HeaderItem key={Utils.formatDateForDatePicker(date)} date={date} saison={compValues.saisonCollection} />
            ))}
          </Col>
        </Row>

        {/* items */}
        {compValues.apartmentCollection &&
          [...compValues.apartmentCollection].map(([apartmentID, apartment]) => (
            <Row key={apartmentID} className={`${styles.disableWrap}`} style={{ paddingBottom: "0.3em" }}>
              <Col key={apartmentID} className={styles.title} xs={2}>
                <ApartmentOverview apartment={apartment} />
              </Col>
              <Col className={styles.scrollContainer}>{buildBookingOverview(apartmentID)}</Col>
            </Row>
          ))}
      </Container>
    </>
  );
};

export default HorizontalCalendar;
