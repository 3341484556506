import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import styles from "./component.module.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { NameToValue, Translation, UUID } from "../../../../infrastructure/types";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import SwitchIcon from "../../../Icons/SwitchIcon";

type ItemSelecterModalProps = {
  showModal: boolean;
  modalTitleTranslationKey: string;

  itemIDs: UUID[];
  itemMap: NameToValue<Translation>;

  handleAssumeItems: (activeItemIDs: UUID[]) => void;
  close: () => void;
};

function ItemSelecterModal(props: ItemSelecterModalProps) {
  const { handleAssumeItems, close, modalTitleTranslationKey, showModal, itemIDs, itemMap } = props;
  const { translateObj, translate } = TranslationHandler();

  const [show, setShow] = useState<boolean>(false);

  const [usedActiveItemIDsCollection, setUsedActiveItemIDsCollection] = useState<Set<UUID>>(new Set());
  const [usedInActivedItemIDsCollection, setUsedInActiveItemIDsCollection] = useState<Set<UUID>>(new Set());

  const [changedActiveItemIDsCache, setChangedItemIDsCache] = useState<Set<UUID>>(new Set());
  const [changedInActiveItemIDsCache, setChangedInItemIDsCache] = useState<Set<UUID>>(new Set());

  const ref = useRef<Array<HTMLInputElement | null>>([]);

  const handleCheckBoxOnChanged = (wasEnabled: boolean, itemID: UUID) => {
    // we are only interested in truthy changes
    if (!wasEnabled) return;

    const cachedActiveItemCollection = new Set(changedActiveItemIDsCache);
    const cachedInActiveItemCollection = new Set(changedInActiveItemIDsCache);

    const wasActiveItem = changedActiveItemIDsCache.has(itemID);

    if (!wasActiveItem) {
      cachedActiveItemCollection.add(itemID);
      cachedInActiveItemCollection.delete(itemID);
    } else {
      cachedActiveItemCollection.delete(itemID);
      cachedInActiveItemCollection.add(itemID);
    }

    setChangedItemIDsCache(cachedActiveItemCollection);
    setChangedInItemIDsCache(cachedInActiveItemCollection);
  };

  const handleMoveItems = () => {
    // clear checkboxes
    for (const checkboxRef of ref.current) {
      if (!checkboxRef) continue;

      checkboxRef.checked = false;
    }

    ref.current = Array(ref.current.length).fill(null);
    setUsedActiveItemIDsCollection(changedActiveItemIDsCache);
    setUsedInActiveItemIDsCollection(changedInActiveItemIDsCache);
  };

  const handleAssumeSelectedItems = () => {
    handleAssumeItems([...usedActiveItemIDsCollection]);
    close();
  };

  const buildItem = (recordID: UUID, name: Translation, position: number): JSX.Element => {
    return (
      <Form.Check
        type="checkbox"
        key={recordID}
        id={recordID}
        ref={(el: HTMLInputElement) => (ref.current[position] = el)}
        label={translateObj(name)}
        onChange={(e) => handleCheckBoxOnChanged(e.target.checked, recordID)}
      />
    );
  };

  const buildItems = (itemMap: NameToValue<Translation>, itemIDsCollection: Set<UUID>): JSX.Element[] => {
    const checkBoxItems: JSX.Element[] = [];
    let positionCounter = 0;

    for (const [recordID, name] of itemMap) {
      const shouldBeAdded = itemIDsCollection.has(recordID);
      if (!shouldBeAdded) continue;

      positionCounter++;

      const checkBoxItem = buildItem(recordID, name, positionCounter);
      checkBoxItems.push(checkBoxItem);
    }

    return checkBoxItems;
  };

  const prepareItemOverview = (itemMap: NameToValue<Translation>, itemIDs: UUID[]): void => {
    const inActiveIDsCollection: Set<UUID> = new Set();
    const activeIDsCollection: Set<UUID> = new Set();

    for (const recordID of itemMap.keys()) {
      const isActive = itemIDs.includes(recordID);
      if (isActive) {
        activeIDsCollection.add(recordID);
      } else {
        inActiveIDsCollection.add(recordID);
      }
    }

    setUsedActiveItemIDsCollection(activeIDsCollection);
    setUsedInActiveItemIDsCollection(inActiveIDsCollection);

    setChangedItemIDsCache(activeIDsCollection);
    setChangedInItemIDsCache(inActiveIDsCollection);
  };

  useEffect(() => {
    setShow(showModal);
    prepareItemOverview(itemMap, itemIDs);
  }, [showModal, itemIDs, itemMap]);

  return (
    <Modal show={show} onHide={close} aria-labelledby="contained-modal-title-vcenter" size="lg" centered keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{translate(modalTitleTranslationKey)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className={`${styles.pushDown} d-flex justify-content-center`}>
            <Col xs={5} className={styles.pullLeft}>
              <h3>{translate("modal_label_inActiveAttributes")}</h3>
            </Col>
            <Col xs={1} />
            <Col xs={5} className={styles.pullLeft}>
              <h3>{translate("modal_label_activeAttributes")}</h3>
            </Col>
          </Row>
          <Form>
            <Row className="d-flex justify-content-center">
              <Col className={styles.content} xs={5}>
                {buildItems(itemMap, usedInActivedItemIDsCollection)}
              </Col>
              <Col className={styles.pushRight} xs={1}>
                <Button className={styles.btnSwitch} size="sm" variant="outline-dark" onClick={handleMoveItems}>
                  <SwitchIcon />
                </Button>
              </Col>
              <Col className={styles.content} xs={5}>
                {buildItems(itemMap, usedActiveItemIDsCollection)}
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {translate("modal_btn_abort")}
        </Button>
        <Button variant="primary" onClick={handleAssumeSelectedItems}>
          {translate("modal_btn_ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ItemSelecterModal;
