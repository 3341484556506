import styles from "./component.module.css";
import sharedStyles from "../component.shared.module.css";

import { useNavigate } from "react-router-dom";
import ApiClient from "../../interface/client/apiClient";
import { Button, Card, Form, Image } from "react-bootstrap";
import Utils from "../../infrastructure/utils";
import { useEffect, useState } from "react";
import ToastContainerFactory, { toastError } from "../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../Utils/TranslationProvider";
import { Locale } from "../../infrastructure/enums";

type FormValues = {
  email: string;
  password: string;
};

type ErrorValues = Partial<FormValues>;

function LoginView() {
  const Navigate = useNavigate();
  const {setLocale, translate} = TranslationHandler()

  const defaultFormValues: FormValues = { email: "", password: "" };
  const [formValue, setFormValue] = useState<FormValues>(defaultFormValues);
  const [InputErrors, setInputErrors] = useState<ErrorValues>({});

  const setField = (field: keyof FormValues, value: string) => {
    setFormValue({ ...formValue, [field]: value });

    // remove entry from InputErros, if exists
    if (!!InputErrors[field]) setInputErrors({ ...InputErrors, [field]: null });
  };
  const getFormValue = (field: keyof FormValues) => {
    return formValue[field];
  };
  const hasError = (field: keyof FormValues) => {
    return InputErrors[field] != null;
  };
  const getError = (field: keyof FormValues) => {
    return InputErrors[field];
  };

  const validateForm = () => {
    const foundErrors: ErrorValues = {};

    const hasEmailError = formValue.email.length < 4;
    if (hasEmailError) foundErrors.email = "Bitte gib eine Email ein";

    const hasPwdError = formValue.password.length < 4;
    if (hasPwdError) foundErrors.password = "Bitte gib dein Passwort ein";

    setInputErrors(foundErrors);

    const isValid = Object.keys(foundErrors).length === 0;
    return isValid;
  };

  useEffect(() => {
    setLocale(Locale.deDE)

    const isLoggedIn = Utils.isLoggedIn();
    if (isLoggedIn) Navigate("/");
  });

  const handlePwdForgotten = () => Navigate("/pwdForgotten");

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) return;

    const { email, password } = formValue;
    const loginResponse = await ApiClient().login(email, password);
    if (loginResponse.error == null) {
      const { token } = loginResponse.data;
      Utils.setAsLoggedIn(token);

      const getAdminUserGetMeResponse = await ApiClient().adminUserGetMe();
      if (getAdminUserGetMeResponse.error !== null) Utils.setAsLogout();

      Utils.updateIdentity(
        loginResponse.data.token,
        getAdminUserGetMeResponse.data.firstName,
        getAdminUserGetMeResponse.data.lastName,
        getAdminUserGetMeResponse.data.type
      );

      Navigate("/");
      return;
    }

    toastError(translate(loginResponse.errorTRKey));
  };

  return (
    <>
      <ToastContainerFactory />

      <div className={sharedStyles.bg}>
        <Card className={sharedStyles.glas_card}>
          <Image src="/FeWoServ_logo.png" />
          <Card.Title className={styles.title}>
            <h3>Willkommen ! </h3>
            <br />
            <span>Bitte gib dein Passwort und Nutzernamen ein.</span>
          </Card.Title>
          <Card.Body className={styles.clearPadding}>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Email"
                  autoComplete="email"
                  onChange={(e) => setField("email", e.target.value)}
                  value={getFormValue("email")}
                  isInvalid={hasError("email")}
                  className={sharedStyles.glas_form}
                />
                <Form.Control.Feedback type="invalid">{getError("email")}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Passwort"
                  autoComplete="password"
                  onChange={(e) => setField("password", e.target.value)}
                  value={getFormValue("password")}
                  isInvalid={hasError("password")}
                  className={sharedStyles.glas_form}
                />
                <Form.Control.Feedback type="invalid">{getError("password")}</Form.Control.Feedback>
              </Form.Group>

              <Button type="submit" size="sm" variant="outline-dark" className="float-end button">
                Los gehts !
              </Button>
            </Form>
          </Card.Body>
          <hr />
          <i className="text-end">
            <Button type="submit" variant="link" className="float-end button" onClick={handlePwdForgotten}>
              Password vergessen ?
            </Button>
          </i>
        </Card>
      </div>
    </>
  );
}

export default LoginView;
