import styles from "./component.module.css";

import { Nav } from "react-bootstrap";
import { SideMenuItemProps } from "./types";
import { PuffLoader } from "react-spinners";

import { TranslationHandler } from "../../../Utils/TranslationProvider";

function SideMenuItemBooking(props: SideMenuItemProps) {
  const { isActive } = props;
  const { translate } = TranslationHandler();

  const styleIsActive = isActive ? styles.isActiveNavLink : "";

  return (
    <Nav.Item className={`${styles.spacerTop}`}>
      <Nav.Link eventKey="booking" className={`${styles.navLink} ${styleIsActive}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-card-checklist" viewBox="0 0 25 25">
          <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
          <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
        </svg>
        {translate("sideMenu_Booking")}
      </Nav.Link>
      {/* <div className={styles.newBookingIndicator}>
        <div>12</div>
        <PuffLoader speedMultiplier={0.5} color="rgba(85, 90, 89, 0.41)" style={{ position: "absolute", top: "-0.94em", left: "-0.94em" }} />
      </div> */}
    </Nav.Item>
  );
}

export default SideMenuItemBooking;
