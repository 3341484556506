import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { Saison } from "../../../../infrastructure/types";

import styles from "./component.module.css";
import { Col, Form, Row } from "react-bootstrap";
import { Mode } from "../../../../interface/client/enums";
import SaisonEntryHandler from "./SaisonEntries/SaisonEntryHandler";
import { SECollection } from "./type";
import Utils from "../../../../infrastructure/utils";

type SaisonModalProps = {
  showModal: boolean;
  modalTitleTranslationKey: string;
  saison: Saison | null;
  mode: Mode;

  onAssume: (saison: Saison) => void;
  close: () => void;
};

type FormValues = {
  show: boolean;
  mode: Mode;

  year: number;
  isInvalid: boolean;
  usedSaisonEntryCollection: SECollection;
};

type RecordData = Pick<FormValues, "usedSaisonEntryCollection">;

function EditModal(props: SaisonModalProps) {
  const { close, onAssume, saison, modalTitleTranslationKey, showModal, mode } = props;
  const { translate } = TranslationHandler();

  const [formValues, setFormValues] = useState<FormValues>({
    usedSaisonEntryCollection: new Map(),
    mode: Mode.ADD,
    show: showModal,
    isInvalid: false,
    year: new Date().getFullYear(),
  });
  const [recordData, setRecordData] = useState<RecordData>({ usedSaisonEntryCollection: new Map() });
  const buildRecordDataFromFormValue = (_formData?: FormValues): RecordData => {
    const { usedSaisonEntryCollection } = _formData ? _formData : formValues;
    const recordData: RecordData = {
      usedSaisonEntryCollection,
    };

    return recordData;
  };
  const setFormValueField = (fieldName: keyof FormValues, value: any) => {
    setFormValues({ ...formValues, [fieldName]: value });
  };

  useEffect(() => {
    const usedSaisonEntryCollection: SECollection = new Map();

    if (saison?.entries) {
      let loopCounter = 0;
      for (const saisonEntry of saison?.entries) {
        usedSaisonEntryCollection.set(loopCounter, { ...saisonEntry, isNew: false });
        loopCounter++;
      }
    }

    const usedFormValues = { ...formValues, show: showModal, year: saison?.year ?? new Date().getFullYear(), mode, usedSaisonEntryCollection };
    setFormValues(usedFormValues);
    setRecordData(buildRecordDataFromFormValue(usedFormValues));
  }, [showModal, saison]);

  const handleOnAssume = () => {
    const { year, usedSaisonEntryCollection } = formValues;

    const cachedSaison = { ...saison } as Saison;
    cachedSaison.year = year;

    const entries = [];
    for (const entry of usedSaisonEntryCollection.values()) {
      delete entry.isNew;
      entries.push(entry);
    }
    cachedSaison.entries = entries;

    onAssume(cachedSaison);
  };

  const hasSomethingChanged = (): boolean => {
    const compData = buildRecordDataFromFormValue();
    return Utils.areObjEqual(compData, recordData);
  };

  const handleOnDataHasChanged = (isInvalid: boolean, changedSaisonEntries: SECollection) => {
    const cache = { ...formValues };
    cache.isInvalid = isInvalid;

    if (!isInvalid) cache.usedSaisonEntryCollection = changedSaisonEntries;
    setFormValues(cache);
  };

  const buildItem = (year: number): JSX.Element => {
    return (
      <option key={year} value={year}>
        {year}
      </option>
    );
  };

  const buildSelectItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 5;
    const endingYear = currentYear + 5;
    for (let year = startingYear; year < endingYear; year++) {
      const item = buildItem(Number(year));
      items.push(item);
    }

    return items;
  };

  return (
    <Modal show={formValues.show} onHide={close} size="lg" keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{translate(modalTitleTranslationKey)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className={styles.pushLeft}>
          <Col className={styles.pullDown} xs={1}>
            <b>{translate("saison_label_year")}</b>
          </Col>
          <Col>
            <Form.Select
              id={"saisonTypePicker" + new Date().getTime()}
              value={formValues.year}
              onChange={(e) => setFormValueField("year", e.target.value)}
              key={"selectSaison"}
              disabled={mode === Mode.EDIT}
            >
              {buildSelectItems()}
            </Form.Select>
          </Col>
        </Row>
        <hr />
        <SaisonEntryHandler
          selectedYear={formValues.year}
          saisonEntryCollection={formValues.usedSaisonEntryCollection}
          onDataHasChanged={handleOnDataHasChanged}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {translate("modal_btn_abort")}
        </Button>
        <Button disabled={formValues.isInvalid || !hasSomethingChanged()} variant="primary" onClick={handleOnAssume}>
          {translate("modal_btn_ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
