import { Button, Col, Container, Form, Row } from "react-bootstrap";
import styles from "./component.module.css";
import sharedStyles from "../../../component.shared.module.css";
import { MenuProps } from "../type";
import { Mode } from "../../../../interface/client/enums";
import { useState } from "react";
import DeleteModal from "./DeleteModal/DeleteModal";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import BackIcon from "../../../Icons/BackIcon";
import DoneIcon from "../../../Icons/DoneIcon";
import DeleteIcon from "../../../Icons/DeleteIcon";
import UnsavedChangesModal from "../../../UnsavedChangesModal/UnsavedChangesModal";

type CompValues = {
  showUnsavedChangesModal: boolean;
  showDeleteModal: boolean;
};

function Menu(props: MenuProps) {
  const { translate } = TranslationHandler();
  const [compValues, setCompValues] = useState<CompValues>({ showDeleteModal: false, showUnsavedChangesModal: false });

  const handleShowDeleteModal = (isActive: boolean) => {
    setCompValues({ ...compValues, showDeleteModal: isActive });
  };

  const handleSaveOnClick = (event: any) => {
    event.preventDefault();
    props.onSaveClicked();
  };

  const handleAbortOnClick = () => {
    if (props.disableSave) {
      setCompValues({ ...compValues, showUnsavedChangesModal: true });
      return;
    }

    props.onAbortClicked();
  };

  const handleUnsavedChangesOnAbortClicked = () => {
    setCompValues({ ...compValues, showUnsavedChangesModal: false });
  };

  const handleUnsavedChangesOnDiscardClicked = () => {
    setCompValues({ ...compValues, showUnsavedChangesModal: false });
    props.onAbortClicked();
  };

  const handleDeleteApprovedClicked = () => {
    handleShowDeleteModal(false);
    props.onDeleteClicked();
  };

  const isEditMode = props.mode === Mode.EDIT;

  return (
    <>
      <UnsavedChangesModal
        handleAborted={() => handleUnsavedChangesOnAbortClicked()}
        handleDiscard={() => handleUnsavedChangesOnDiscardClicked()}
        showModal={compValues.showUnsavedChangesModal}
        key={"UnsavedChangesModal"}
      />
      <DeleteModal
        handleDeleteApproved={() => handleDeleteApprovedClicked()}
        handleAborted={() => handleShowDeleteModal(false)}
        showModal={compValues.showDeleteModal}
      />
      <Container fluid className={sharedStyles.menu}>
        <Row className="align-items-center">
          <Col xs={6}>
            <Button size="sm" type="submit" variant="outline-dark" onClick={handleAbortOnClick}>
              <BackIcon className={styles.backBtn} />

              {translate("topmenu_btn_back")}
            </Button>
          </Col>
          <Col>
            <Button
              size="sm"
              type="button"
              variant="outline-dark"
              className={`${styles.spaceLeft} float-end`}
              onClick={handleSaveOnClick}
              disabled={!props.disableSave}
            >
              <DoneIcon className={styles.doneIcon} />
              {translate("topmenu_btn_save")}
            </Button>
            {isEditMode && (
              <Button size="sm" type="button" variant="outline-danger" className={`${styles.spaceLeft} float-end`} onClick={() => handleShowDeleteModal(true)}>
                <DeleteIcon className={styles.deleteIcon} />
                {translate("topmenu_btn_delete")}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Menu;
