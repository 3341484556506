import { Form } from "react-bootstrap";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { useEffect, useState } from "react";

type SaisonTypePickerProps = {
  pos: number;
  preSelectType: number;

  onChanged(saisonType: number): void;
};

type FormValues = {
  selectedSaisonType: number;
};

const typeToIdentifier = new Map([
  [0, "saison_type_peak"],
  [1, "saison_type_high"],
  [2, "saison_type_middle"],
  [3, "saison_type_low"],
]);

function SaisonTypePicker(props: SaisonTypePickerProps) {
  const { pos, preSelectType, onChanged } = props;
  const { translate } = TranslationHandler();

  const [formValues, setFormValues] = useState<FormValues>({ selectedSaisonType: preSelectType });

  useEffect(() => {
    setFormValues({ selectedSaisonType: preSelectType });
  }, [preSelectType]);

  const handleOnChange = (event: any) => {
    const usedSaisonType = event.target.value;
    setFormValues({ ...formValues, selectedSaisonType: usedSaisonType });
    onChanged(Number(usedSaisonType));
  };

  const buildItem = (type: number): JSX.Element => {
    const identifier = typeToIdentifier.get(type) as string;

    const translation = translate(identifier);

    return (
      <option key={type} value={type}>
        {translation}
      </option>
    );
  };

  const buildSelectItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    for (const saisonType of typeToIdentifier.keys()) {
      const item = buildItem(Number(saisonType));
      items.push(item);
    }

    return items;
  };

  return (
    <Form.Select id={pos.toString()} value={formValues.selectedSaisonType} onChange={handleOnChange} key={pos} aria-label="userAdminType select">
      {buildSelectItems()}
    </Form.Select>
  );
}

export default SaisonTypePicker;
