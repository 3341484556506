import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TranslationHandler } from "../../../../../Utils/TranslationProvider";
import Cropper, { Area } from "react-easy-crop";
import { Col, Container, Form, Image, ProgressBar, Row } from "react-bootstrap";
import getCroppedImg from "./core";

import styles from "./component.module.css";
import DoneIcon from "../../../../../Icons/DoneIcon";
import { UUID } from "../../../../../../infrastructure/types";

import ApiClient from "../../../../../../interface/client/apiClient";
import Utils from "../../../../../../infrastructure/utils";

type AspectDefenition = {
  representation: string;
  aspect: number;
};

type CropedImage = {
  hasCroped: boolean;
  blobUrl: string;
  originBlobUrl: string;
  croppedFile: File | null;
  croppedAreaPixels: Area | null;
};

type EditModalProps = {
  show: boolean;
  imageStringsToCrop: string[];

  uploadedPictureIDs: (pictureIDs: UUID[]) => void;
  close: () => void;
};

const aspectMap = new Map<number, AspectDefenition>([
  [0, { representation: "4/2", aspect: 4 / 2 }],
  [1, { representation: "4/3", aspect: 4 / 3 }],
]);

function EditModal(props: EditModalProps) {
  const { show, imageStringsToCrop, uploadedPictureIDs, close } = props;
  const { translate } = TranslationHandler();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspect, setAspect] = useState(aspectMap.get(0)?.aspect);

  const [activeImagePosition, setActiveImagePosition] = useState<number>(0);
  const [processStep, setProcessStep] = useState<number>(0);

  const [progress, setProgress] = useState<number | null>(null);
  const [croppedImages, setCroppedImages] = useState<CropedImage[][]>([]);

  const prepareCropedImages = () => {
    const croppedImages: CropedImage[][] = [];

    for (const blobUrl of imageStringsToCrop) {
      const cropedImage: CropedImage = {
        blobUrl: "",
        originBlobUrl: blobUrl,
        croppedFile: null,
        hasCroped: false,
        croppedAreaPixels: null,
      };

      // we push the item two times, because we modify it with two different apsects later on
      croppedImages.push([cropedImage, Object.assign({}, cropedImage)]);
    }

    return croppedImages;
  };

  useEffect(() => {
    clearSettings();
    setActiveImagePosition(0);
    setCroppedImages(prepareCropedImages());
  }, [imageStringsToCrop]);

  const uploadCroppedImages = async () => {
    const newPictureIDs: UUID[] = [];

    let progressStep = 0;
    const filesToUploadAmount = croppedImages.length;

    for (const croppedImage of croppedImages) {
      const [firstEntry, secondEntry] = croppedImage;

      progressStep++;

      // first uoload with resolution 4/2
      let request = await ApiClient().pictureUpload(firstEntry.croppedFile as File, {});
      if (request.error == null) {
        // second uoload with resolution 4/3
        request = await ApiClient().pictureUpload(secondEntry.croppedFile as File, {}, true, request.data[0].id);
        if (request.error == null) {
          const picture = request.data[0];
          newPictureIDs.push(picture.id);

          const percentage = Math.round((progressStep / filesToUploadAmount) * 100);
          setProgress(percentage);
        }
      }
    }

    await Utils.sleep(3000);

    uploadedPictureIDs(newPictureIDs);
    setProgress(null);
  };

  const updateCroppedItems = (props: { croppedAreaPixels?: Area; blobUrl?: string; croppedFile?: File }) => {
    const { blobUrl, croppedAreaPixels, croppedFile } = props;
    const cache = [...croppedImages];

    if (croppedAreaPixels) cache[activeImagePosition][processStep].croppedAreaPixels = croppedAreaPixels;
    if (blobUrl && croppedFile) {
      cache[activeImagePosition][processStep].blobUrl = blobUrl;
      cache[activeImagePosition][processStep].croppedFile = croppedFile;
      cache[activeImagePosition][processStep].hasCroped = true;
    }

    console.log(activeImagePosition, processStep);
    setCroppedImages(cache);
  };

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    updateCroppedItems({ croppedAreaPixels });
  };

  const hasEndReached = (): boolean => {
    return activeImagePosition === croppedImages.length;
  };

  const getActiveItemPositionForView = (): number => {
    if (hasEndReached()) return activeImagePosition;

    return activeImagePosition + 1;
  };

  const incActiveItemPosition = (): number => {
    if (hasEndReached()) return activeImagePosition;

    const nextActiveItemPosition = activeImagePosition + 1;
    setActiveImagePosition(nextActiveItemPosition);

    return nextActiveItemPosition;
  };

  const clearSettings = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
  };

  const cropImage = async () => {
    try {
      const result = await getCroppedImg(
        imageStringsToCrop[activeImagePosition],
        croppedImages[activeImagePosition][processStep].croppedAreaPixels as Area,
        rotation
      );
      if (!result) return;

      if (processStep === 1) {
        clearSettings();
        const nextActiveItemPosition = incActiveItemPosition();
        scrollToDivID(nextActiveItemPosition.toString());
      }

      updateCroppedItems(result);

      const usedAspect = processStep === 1 ? aspectMap.get(0) : aspectMap.get(1);
      setAspect(usedAspect?.aspect);
      setProcessStep(processStep === 0 ? 1 : 0);
    } catch (e) {
      console.error(e);
    }
  };

  const previewImages = (): JSX.Element[] => {
    const previewImages: JSX.Element[] = [];

    if (croppedImages.length === 0) return previewImages;

    for (let position = 0; position < imageStringsToCrop.length; position++) {
      const croppedImage = croppedImages[position][0];
      if (!cropImage) continue;

      const { originBlobUrl, hasCroped } = croppedImage;

      let iconOpacity = 0;
      if (hasCroped && processStep === 1) iconOpacity = 0.5;
      if (hasCroped && processStep === 0) iconOpacity = 1;

      const isActive = position === activeImagePosition ? styles.isActive : styles.grayedOut;
      const preview = (
        <div id={position.toString()} className={`${styles.scrollContainerChild}`}>
          <DoneIcon className={styles.doneIcon} style={{ opacity: iconOpacity }} />
          <Image className={`${isActive}`} src={originBlobUrl} style={{ height: "7em" }} />
        </div>
      );

      previewImages.push(preview);
    }

    return previewImages;
  };

  const scrollToDivID = (divID: string) => {
    const scrollToDiv = document.getElementById(divID);
    if (!scrollToDiv) return;

    scrollToDiv.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  };

  return (
    <Modal show={show} onHide={close} size="lg" keyboard={true} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {translate("apartment_picture_handler_modal_title")} {getActiveItemPositionForView()}/{imageStringsToCrop.length}
        </Modal.Title>
      </Modal.Header>
      <div className={`${styles.scrollContainer}`}>{previewImages()}</div>

      <Modal.Body style={{ height: "35em" }}>
        <Container fluid className={`${!hasEndReached() ? "" : styles.done}`}>
          <Row>
            <Col>
              {/* cropper */}
              {!hasEndReached() ? (
                <Cropper
                  image={imageStringsToCrop[activeImagePosition]}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                />
              ) : (
                <>
                  <h3>{translate("partment_picture_handler_modal_done")}</h3>
                  <ProgressBar className={styles.progressBar} now={progress as number} />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {!hasEndReached() && (
          <Container fluid>
            <Row style={{ marginBottom: "1em" }}>
              <Col>
                <strong>Aspect: {aspectMap.get(processStep)?.representation}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                {translate("apartment_picture_handler_modal_label_zoom")}
                <Form.Range min={1} max={10} value={zoom} onChange={(e) => setZoom(Number(e.target.value))} />
              </Col>
              <Col>
                {translate("apartment_picture_handler_modal_label_rotate")}
                <Form.Range min={0} max={360} value={rotation} onChange={(e) => setRotation(Number(e.target.value))} />
              </Col>
            </Row>
          </Container>
        )}

        <Button variant="outline-dark" onClick={close} disabled={!!progress}>
          {translate("modal_btn_abort")}
        </Button>
        {!hasEndReached() && (
          <Button variant="outline-dark" onClick={() => cropImage()}>
            {translate("modal_btn_ok")}
          </Button>
        )}

        {hasEndReached() && (
          <Button disabled={!!progress} variant="outline-dark" onClick={() => uploadCroppedImages()}>
            {translate("modal_btn_upload")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
