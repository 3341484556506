import React, { useRef, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./component.module.css";
import { UUID } from "../../../../infrastructure/types";

export type ViewPortDates = {
  fromDate: Date;
  toDate: Date;
};

type EmptyItemProps = {
  date: Date;
  apartmentID: UUID;
  isInPast: boolean;

  onClicked: (date: Date, apartmentID: UUID) => void;
};

type CompValues = {
  date: Date;
};

function EmptyItem(props: EmptyItemProps) {
  const [compValues, setCompValues] = useState<CompValues>({ date: new Date() });

  useEffect(() => {
    const compValues: CompValues = { date: new Date(props.date) };

    setCompValues(compValues);
  }, []);

  const handleOnClick = () => {
    props.onClicked(compValues.date, props.apartmentID);
  };

  const highlightBackgroundCollection = [styles.itemHeader];

  if (props.isInPast) highlightBackgroundCollection.push(styles.past);

  const higlightBackground = highlightBackgroundCollection.join(" ");

  return (
    <Container className={higlightBackground} onClick={() => handleOnClick()}>
      <Row style={{ width: "4em" }}>
        <Col></Col>
      </Row>
    </Container>
  );
}

export default EmptyItem;
