import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import { RealEstateLinkedApartment, UUID } from "../../../../infrastructure/types";
import { TranslationHandler } from "../../../Utils/TranslationProvider";

import styles from "./component.module.css";
import { useNavigate } from "react-router-dom";

type LinkedApartments = {
  linkedApartments: RealEstateLinkedApartment[];
};

function LinkedApartments(props: LinkedApartments) {
  const Navigate = useNavigate();

  const { translate } = TranslationHandler();
  const { linkedApartments } = props;

  if (Array.isArray(linkedApartments) && linkedApartments.length === 0) return <></>;

  const jumpToApartment = (apartmentID: UUID) => Navigate(`/apartment/${apartmentID}`);

  const buildItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    for (const linkedApartment of linkedApartments) {
      const { id, name } = linkedApartment;

      const item = (
        <li className={styles.listItem}>
          <Badge bg="white" text="black" className={styles.badge} onClick={() => jumpToApartment(id)}>
            {name}
          </Badge>
        </li>
      );

      items.push(item);
    }

    return items;
  };

  return (
    <>
      <Container fluid className={styles.container}>
        <Row>
          <Col>
            <Form.Label>
              <b>{translate("label_apartments")}</b>
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul style={{ padding: 0 }}>{buildItems()}</ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LinkedApartments;
