export const errorResponseCollection = new Map([
  //       ERROR CODE FROM BE                   TRANSLATION KEY
  ["AdminUser.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["AdminUser.ErrNotAllowedDeletion", "adminUser_toast_error_not_allowed_deletion"],
  ["AdminUser.ErrCantSave", "toast_error_cant_save"],
  ["AdminUser.ErrCantSendInvitation", "adminUser_toast_error_cant_send_invite"],
  ["AdminUser.ErrPwdNotEqual", "adminUser_toast_error_pwd_not_equal"],
  ["AdminUser.ErrUpdatingPwdNotPossible", "adminUser_toast_error_pwd_update_not_possible"],
  ["AdminUser.ErrCantUpdate", "toast_error_cant_update"],
  ["AdminUser.ErrCantUpdatePwd", "adminUser_toast_error_pwd_update_not_possible"],
  ["AdminUser.ErrCantDelete", "toast_error_cant_delete"],

  ["Apartment.ErrCantSave", "toast_error_cant_save"],
  ["Apartment.ErrCantDelete", "toast_error_cant_delete"],
  ["Apartment.ErrCantUpdate", "toast_error_cant_update"],
  ["Apartment.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["Apartment.ErrNotEnaughPictures", "toast_error_not_enaugh_pictures"],

  ["Attribute.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["Attribute.ErrCantUpdate", "toast_error_cant_update"],
  ["Attribute.ErrCantSave", "toast_error_cant_save"],
  ["Attribute.ErrCantDelete", "toast_error_cant_delete"],

  ["Authentication.ErrLoginNotAllowed", "authentication_toast_error_login_not_allowed"],
  ["Authentication.ErrCantSendEmail", "authentication_toast_error_cant_send_email"],
  ["Authentication.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["Authentication.ErrPwdResetTriggerNotPossible", "authentication_toast_error_cant_trigger_pwd_reset"],

  ["Booking.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["Booking.ErrPlacingBookingNotAllowed", "booking_toast_error_place_booking_not_allowed"],
  ["Booking.ErrCantUpdate", "toast_error_cant_update"],
  ["Booking.ErrCantSave", "toast_error_cant_save"],
  ["Booking.ErrCantDelete", "toast_error_cant_delete"],

  ["News.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["News.ErrCantUpdate", "toast_error_cant_update"],
  ["News.ErrCantSave", "toast_error_cant_save"],
  ["News.ErrCantDelete", "toast_error_cant_delete"],

  ["picture.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["picture.ErrCantUpdate", "toast_error_cant_update"],
  ["picture.ErrCantSave", "toast_error_cant_save"],
  ["picture.ErrCantDelete", "toast_error_cant_delete"],

  ["RealEstate.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["RealEstate.ErrHasLinkedApartments", "realestate_tost_error_has_linked_apartments"],
  ["RealEstate.ErrCantUpdate", "toast_error_cant_update"],
  ["RealEstate.ErrCantSave", "toast_error_cant_save"],
  ["RealEstate.ErrCantDelete", "toast_error_cant_delete"],

  ["Saison.ErrRecordNotExists", "toast_error_record_not_exists"],
  ["Saison.ErrSeasonAlreadyExists", "saison_toast_error_already_exists"],
  ["Saison.ErrCantUpdate", "toast_error_cant_update"],
  ["Saison.ErrCantSave", "toast_error_cant_save"],
  ["Saison.ErrCantDelete", "toast_error_cant_delete"],
]);
