import { Card } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewAddItemProps } from "./type";
import { TranslationHandler } from "../../Utils/TranslationProvider";

function ListViewAddItem(props: ListViewAddItemProps) {
  const { onClicked } = props;
  const { translate } = TranslationHandler();

  const handleOnClick = () => onClicked();
  const usedKey = new Date().getTime();

  return (
    <Card key={usedKey} className={styles.cardAddPicture} onClick={handleOnClick}>
      <Card.Body>
        <div className="text-center">
          <b>{translate("label_add")}</b>
        </div>
        <Card.Img className={styles.cardAddPictureImg} src="/AddPicture.png" />
      </Card.Body>
    </Card>
  );
}

export default ListViewAddItem;
