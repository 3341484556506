import { Button, Card, Col, Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewItemProps } from "./type";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import DeleteIcon from "../../../Icons/DeleteIcon";
import { Locale } from "../../../../infrastructure/enums";
import { Translation } from "../../../../infrastructure/types";
import SVGRenderer from "../../../Utils/SVGRenderer/SVGRenderer";
import { useState } from "react";

function allTranslationsAvailable(name: Translation): boolean {
  for (const locale of Object.keys(Locale)) {
    const isAvailable = name[locale] !== null && name[locale] !== "";
    if (!isAvailable) return false;
  }

  return true;
}

function ListViewItem(props: ListViewItemProps) {
  const { id, onClicked, onDeleteClicked, name, svg } = props;
  const { translateObj } = TranslationHandler();

  const [formValues, setFormValues] = useState({});

  const higlight = allTranslationsAvailable(name) ? "" : styles.cardHeadeAllTranslationsAvailable;

  return (
    <Col xs={4}>
      <Card className={styles.card}>
        <Card.Header className={`${styles.cardHeader}  ${higlight}`}>
          <Container>
            <Row>
              <Col xs={1}>
                <SVGRenderer svgString={svg} />
              </Col>
              <Col onClick={() => onClicked(id)} xs={10}>
                {translateObj(name)}
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <Row className={styles.clear} style={{ marginRight: "1em" }}>
            <Col className={styles.clear}>
              <Button className={styles.btn} size="sm" type="button" variant="outline-white" onClick={() => onDeleteClicked(id)}>
                <DeleteIcon className={styles.deleteIcon} />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default ListViewItem;
