import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "./component.module.css";
import Menu from "./Menu/Menu";
import { EditViewProps } from "./type";
import { useEffect, useState } from "react";
import ApiClient from "../../../interface/client/apiClient";
import { RealEstateLinkedApartment, Translation, UUID, UUIDOrNull } from "../../../infrastructure/types";
import { Mode } from "../../../interface/client/enums";
import { toastError, toastErrorWithCorrelationID, toastSuccess } from "../../Utils/ToastContainerFactory/ToastContainerFactory";
import DescriptionView from "./Description/Description";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import Utils from "../../../infrastructure/utils";
import LinkedApartments from "./LinkedApartments/LinkedApartments";
import ImageUploader from "./PictureHandler/ImageUploader";

type RecordData = {
  name: string;
  description: Translation;
};

type ErrorValues = { name?: string };

function EditView(props: EditViewProps) {
  const { translate } = TranslationHandler();

  const [recordData, setRecordData] = useState<RecordData>({ name: "", description: {} });

  const [description, setDescription] = useState<Translation>({});
  const [name, setName] = useState<string>("");
  const [pictureID, setPictureID] = useState<UUIDOrNull>(null);
  const [recordID, setRecordID] = useState<UUIDOrNull>(null);
  const [apartments, setApartments] = useState<RealEstateLinkedApartment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [inputErrors, setInputErrors] = useState<ErrorValues>({});

  const buildRecordDataFromFormValue = (_recordData?: RecordData): RecordData => {
    const updatedRecordData: RecordData = {
      name: _recordData ? _recordData.name : name,
      description: _recordData ? _recordData.description : description,
    };

    return updatedRecordData;
  };

  const hasError = (field: keyof ErrorValues) => inputErrors[field] != null;

  const validateForm = () => {
    const foundErrors: ErrorValues = {};

    let isNotValid = name.length < 4;
    if (isNotValid) foundErrors.name = translate("realestate_error_name");

    setInputErrors(foundErrors);

    const isValid = Object.keys(foundErrors).length === 0;
    return isValid;
  };

  const handleSetName = (name: string) => {
    setName(name);
    setInputErrors({ ...inputErrors, name: undefined });
  };

  const isEditMode = props.mode === Mode.EDIT;

  useEffect(() => {
    const loadData = async () => {
      if (!props.recordID) return;

      const realEstateResponse = await ApiClient().realEstateGet(props.recordID);
      if (realEstateResponse.error === null) {
        const { description, name, pictureID, apartments } = realEstateResponse.data;

        setRecordID(props.recordID);
        setPictureID(pictureID);
        setDescription(description);
        setName(name);
        setApartments(apartments);
        setIsLoading(false);

        setRecordData(buildRecordDataFromFormValue({ name, description }));
      }
    };

    const isLoadingAllowed = isEditMode && isLoading;
    if (isLoadingAllowed) loadData();
  });

  const handleSaveClicked = async () => {
    const isValid = validateForm();
    if (!isValid) return;

    switch (props.mode) {
      case Mode.ADD:
        const createResponse = await ApiClient().realEstateCreate(name, description);
        if (createResponse.error == null) {
          setRecordID(createResponse.data.id);
          setRecordData(buildRecordDataFromFormValue());
          props.hasNewRealEstateBeenAdded(createResponse.data);

          toastSuccess(translate("realestate_toast_create"), 2);
        } else {
          toastErrorWithCorrelationID(translate(createResponse.errorTRKey), createResponse.correlationID);
        }

        break;
      case Mode.EDIT:
        if (!props.recordID) return;

        const updateResponse = await ApiClient().realEstateUpdate(props.recordID, null, name, description);
        if (updateResponse.error === null) {
          setRecordData(buildRecordDataFromFormValue());

          toastSuccess(translate("realestate_toast_update"), 2);
        } else {
          toastErrorWithCorrelationID(translate(updateResponse.errorTRKey), updateResponse.correlationID);
        }

        break;
    }
  };

  const handleDeletClicked = async () => {
    if (!props.recordID) return;

    const response = await ApiClient().realEstateDelete(props.recordID);
    if (response.error === null) {
      toastSuccess(translate("realestate_toast_deleted"), 2);

      props.close();
    } else {
      toastError(translate(response.errorTRKey));
    }
  };

  const handleOnAbortClicked = () => {
    props.close();
  };

  const handlePictureUploaded = async (newPictureID: UUID) => {
    if (!recordID) return;

    const { error } = await ApiClient().realEstateUpdate(recordID, newPictureID);
    if (error === null) setPictureID(newPictureID);
  };

  const hasSomethingChanged = (): boolean => {
    const compData = buildRecordDataFromFormValue();
    return Utils.areObjEqual(compData, recordData);
  };

  return (
    <Container fluid>
      <Row className={`${styles.menu} g-4`}>
        <Menu
          mode={props.mode}
          onDeleteClicked={handleDeletClicked}
          onSaveClicked={handleSaveClicked}
          onAbortClicked={handleOnAbortClicked}
          disableSave={hasSomethingChanged()}
        />
      </Row>
      <Row className={`${styles.container} g-4`}>
        <Form>
          <Row className={styles.content}>
            <Col>
              <Container style={{ padding: 0 }}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>{translate("label_name")}</b>
                      </Form.Label>
                      <Form.Control type="text" value={name} isInvalid={hasError("name")} onChange={(e) => handleSetName(e.target.value)} />
                      <Form.Control.Feedback type="invalid">{inputErrors.name}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    {/* linked appartments */}
                    <LinkedApartments linkedApartments={apartments} />
                  </Col>
                </Row>
              </Container>

              {/* description */}
              {description && (
                <>
                  <Form.Label>
                    <b>{translate("label_description")}</b>
                  </Form.Label>

                  <DescriptionView description={description} onChanged={(description) => setDescription(description)} />
                </>
              )}
            </Col>
            <Col xs={4}>
              <Row>
                <Col className={styles.spacerBottom05}>
                  <b>{translate("label_picture")}</b>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ImageUploader disabled={!props.recordID} pictureUploaded={handlePictureUploaded} existingPictureID={pictureID as UUID} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </Container>
  );
}

export default EditView;
