// icon:bed | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from "react";

function BedICon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 640 512" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M32 32c17.7 0 32 14.3 32 32v256h224V160c0-17.7 14.3-32 32-32h224c53 0 96 43 96 96v224c0 17.7-14.3 32-32 32s-32-14.3-32-32v-32H64v32c0 17.7-14.3 32-32 32S0 465.7 0 448V64c0-17.7 14.3-32 32-32zm144 256c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80z" />
    </svg>
  );
}

export default BedICon;
