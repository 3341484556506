import { Container } from "react-bootstrap";
import styles from "./component.module.css";
import { useEffect, useState } from "react";
import ApiClient from "../../../interface/client/apiClient";

import ToastContainerFactory, { toastError, toastErrorWithCorrelationID, toastSuccess } from "../../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import ListView from "./ListView/ListView";
import { Saison, UUID, UUIDOrNull } from "../../../infrastructure/types";
import EditModal from "./EditModal/EditModal";
import { Mode } from "../../../interface/client/enums";
import DeleteModal from "./DeleteModal/DeleteModal";

type FormValues = {
  mode: Mode;
  search: string | undefined;
  saisons: Saison[];

  selectedSaison: Saison | null;
  saisonCollection: Map<UUID, Saison>;
  saisonToDeleteID: UUIDOrNull;

  shouldLoading: boolean;
  showModifyModal: boolean;
  showDeleteModal: boolean;
};

function Saisons() {
  const { translate } = TranslationHandler();

  const [formValues, setFormValues] = useState<FormValues>({
    mode: Mode.ADD,
    saisons: [],
    shouldLoading: true,
    search: undefined,
    showModifyModal: false,
    showDeleteModal: false,
    selectedSaison: null,
    saisonCollection: new Map(),
    saisonToDeleteID: null,
  });

  useEffect(() => {
    const preloadData = async () => {
      const { error, data, correlationID, errorTRKey } = await ApiClient().saisonGetMany(formValues.search);
      if (error === null) {
        if (!formValues.shouldLoading) return;

        const saisonCollection = new Map();
        for (const saison of data) saisonCollection.set(saison.id, saison);

        setFormValues({ ...formValues, saisons: data, saisonCollection, shouldLoading: false });

        return;
      }

      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
    };

    preloadData();
  });

  const handleAddClicked = () => {
    setFormValues({ ...formValues, showModifyModal: true, mode: Mode.ADD });
  };

  const handleChildClicked = (listViewChildID: UUID) => {
    const saison = formValues.saisonCollection.get(listViewChildID);
    if (!saison) return;

    setFormValues({ ...formValues, selectedSaison: saison, showModifyModal: true, mode: Mode.EDIT });
  };

  const handleDeleteApproved = async () => {
    const { saisonToDeleteID } = formValues;
    if (!saisonToDeleteID) return;

    const foundSaison = formValues.saisonCollection.get(saisonToDeleteID);
    if (!foundSaison) return;

    const cachedSaisonCollection = new Map(formValues.saisonCollection);
    cachedSaisonCollection.delete(saisonToDeleteID);

    const { correlationID, error, errorTRKey } = await ApiClient().saisonDelete(saisonToDeleteID);
    if (error === null) {
      setFormValues({
        ...formValues,
        saisonCollection: cachedSaisonCollection,
        saisonToDeleteID: null,
        selectedSaison: null,
        showDeleteModal: false,
        shouldLoading: true,
      });

      toastSuccess(translate("saison_toast_deleted"), 2);
    } else {
      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
    }
  };

  const handleChildDeleted = async (listViewChildID: UUID) => {
    setFormValues({ ...formValues, showDeleteModal: true, saisonToDeleteID: listViewChildID });
  };

  const handleOnModalClose = () => {
    setFormValues({ ...formValues, selectedSaison: null, showModifyModal: false });
  };

  const handleChildAssumed = async (saison: Saison) => {
    switch (formValues.mode) {
      case Mode.ADD:
        await createSaison(saison);
        break;
      case Mode.EDIT:
        await updateSaison(saison);
        break;
    }

    const foundSaison = formValues.saisonCollection.get(saison.id);
    if (!foundSaison) return;

    const cachedSaisonCollection = new Map(formValues.saisonCollection);
    cachedSaisonCollection.set(foundSaison.id, saison);

    setFormValues({ ...formValues, saisonCollection: cachedSaisonCollection, selectedSaison: null, showModifyModal: false, shouldLoading: true });
  };

  const handleOnSearch = (search: string): void => {
    setFormValues({ ...formValues, search, shouldLoading: true });
  };

  const createSaison = async (saison: Saison) => {
    const { year, entries } = saison;

    const { data, error, correlationID, errorTRKey } = await ApiClient().saisonCreate(year, entries);
    if (error === null) {
      const cachedSaisonCollection = new Map(formValues.saisonCollection);
      cachedSaisonCollection.set(data.id, data);

      setFormValues({ ...formValues, saisonCollection: cachedSaisonCollection, selectedSaison: null, showModifyModal: false, shouldLoading: true });
      toastSuccess(translate("saison_toast_created"), 2);
    } else {
      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
    }
  };

  const updateSaison = async (saison: Saison) => {
    const foundSaison = formValues.saisonCollection.get(saison.id);
    if (!foundSaison) return;

    const { year, entries } = saison;

    const cachedSaisonCollection = new Map(formValues.saisonCollection);
    cachedSaisonCollection.set(foundSaison.id, saison);

    const { correlationID, errorTRKey, error } = await ApiClient().saisonUpdate(foundSaison.id, year, entries);
    if (error === null) {
      setFormValues({ ...formValues, saisonCollection: cachedSaisonCollection, selectedSaison: null, showModifyModal: false, shouldLoading: true });

      toastSuccess(translate("saison_toast_updated"), 2);
    } else {
      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
    }
  };

  return (
    <>
      <ToastContainerFactory />
      <EditModal
        mode={formValues.mode}
        saison={formValues.selectedSaison}
        close={handleOnModalClose}
        modalTitleTranslationKey={formValues.mode === Mode.ADD ? "saison_modal_title_add" : "saison_modal_title_edit"}
        showModal={formValues.showModifyModal}
        onAssume={handleChildAssumed}
        key="editModal"
      />
      <DeleteModal
        handleAborted={() => setFormValues({ ...formValues, showDeleteModal: false })}
        handleDeleteApproved={handleDeleteApproved}
        showModal={formValues.showDeleteModal}
      />
      <Container fluid className={styles.component}>
        <ListView
          enableSaveBtn={true}
          items={formValues.saisons}
          onSaveClicked={() => {}}
          onChildDeletedClicked={handleChildDeleted}
          onChildClicked={handleChildClicked}
          onAddClicked={handleAddClicked}
          onSearch={handleOnSearch}
        />
      </Container>
    </>
  );
}

export default Saisons;
