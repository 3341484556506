import { ChangeEvent, useState } from "react";

import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import Utils from "../../../../infrastructure/utils";

type MenuProps = {
  onSearchTyped: (search: string) => void;
};

function Menu(props: MenuProps) {
  const { translate } = TranslationHandler();
  const { onSearchTyped } = props;

  const [searchTerm, setSearchTerm] = useState<string>("");

  let timeout: NodeJS.Timeout;
  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    timeout = Utils.delayCall(() => {
      setSearchTerm(event.target.value);
      onSearchTyped(event.target.value);
    }, timeout);
  };

  return (
    <Container fluid className={styles.menu}>
      <Form>
        <Row className="align-items-center">
          <Col>
            <Form.Control
              className={styles.removeBorder}
              id="inlineFormInput"
              onChange={handleSearchOnChange}
              placeholder={translate("menu_search_input")}
              defaultValue={searchTerm}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default Menu;
