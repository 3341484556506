import styles from "./component.module.css";

import { Nav } from "react-bootstrap";
import { SideMenuItemProps } from "./types";

import { TranslationHandler } from "../../../Utils/TranslationProvider";

function SideMenuItemRealestate(props: SideMenuItemProps) {
  const { isActive } = props;
  const { translate } = TranslationHandler();
  
  const styleIsActive = isActive ? styles.isActiveNavLink : "";

  return (
    <Nav.Item className={styles.siteMenuItem}>
      <Nav.Link eventKey="realestate" className={`${styles.navLink} ${styleIsActive}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-houses" viewBox="0 0 25 25">
          <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708L5.793 1Zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207l-4.5-4.5Z" />
        </svg>
        {translate("sideMenu_RealEstate")}
      </Nav.Link>
    </Nav.Item>
  );
}

export default SideMenuItemRealestate;
