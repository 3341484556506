import { RequestPermission } from "../../../infrastructure/enums";
import Utils from "../../../infrastructure/utils";

type PermissionProps = {
  permissions: RequestPermission[];
  children: string | JSX.Element | JSX.Element[];
};

function IdentityProvider(props: PermissionProps) {
  const identity = Utils.getIdentityFromLocalStorage();
  if (!identity) return <></>;

  const hasPermission = identity.hasPermission(props.permissions);
  const usedComponents = hasPermission ? props.children : <></>;

  return <>{usedComponents}</>;
}

export default IdentityProvider;
