import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TranslationHandler } from "../../../../Utils/TranslationProvider";
import Cropper, { Area } from "react-easy-crop";
import { Col, Container, Form, Row } from "react-bootstrap";
import getCroppedImg from "./core";

type EditModalProps = {
  show: boolean;
  imageStrToCrop: string;

  onImageCroped: (cropedImageResult: { blobUrl: string; croppedFile: File } | null) => void;
  close: () => void;
};

function EditModal(props: EditModalProps) {
  const { show, imageStrToCrop, onImageCroped, close } = props;
  const { translate } = TranslationHandler();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const result = await getCroppedImg(imageStrToCrop, croppedAreaPixels as Area, rotation);
      onImageCroped(result);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal show={show} onHide={close} size="lg" keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{translate("realestate_picture_handler_modal_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "35em" }}>
        <Container fluid>
          <Row>
            <Col>
              <Cropper
                image={imageStrToCrop}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={4 / 2}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container fluid>
          <Row>
            <Col>
              {translate("realestate_picture_handler_modal_label_zoom")}
              <Form.Range min={1} max={10} value={zoom} onChange={(e) => setZoom(Number(e.target.value))} />
            </Col>
            <Col>
              {translate("realestate_picture_handler_modal_label_rotate")}
              <Form.Range min={0} max={360} value={rotation} onChange={(e) => setRotation(Number(e.target.value))} />
            </Col>
          </Row>
        </Container>

        <Button variant="secondary" onClick={close}>
          {translate("modal_btn_abort")}
        </Button>
        <Button variant="primary" onClick={() => cropImage()} disabled={false}>
          {translate("modal_btn_ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
