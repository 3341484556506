import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { SaisonPrice } from "../../../../infrastructure/types";

import styles from "./component.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Locale, SaisonType } from "../../../../infrastructure/enums";

type PriceModalProps = {
  seasonPrice: SaisonPrice;
  activeSeasonType: SaisonType;
  activeLocale: Locale;
};

function PriceViewer(props: PriceModalProps) {
  const { seasonPrice, activeSeasonType, activeLocale } = props;
  const { translate } = TranslationHandler();

  const pickPrice = (): number => {
    const { HIGH, LOW, MIDDLE, PEAK } = SaisonType;
    let price = 0;

    switch (activeSeasonType) {
      case LOW:
        price = seasonPrice.lowPrice;
        break;
      case MIDDLE:
        price = seasonPrice.middlePrice;
        break;
      case HIGH:
        price = seasonPrice.highPrice;
        break;
      case PEAK:
        price = seasonPrice.peakPrice;
        break;
    }

    return price;
  };

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row>
        {/* Booking / price info */}
        <Col className="text-end" style={{ padding: 0 }}>
          <Container className={`${styles.booking}`}>
            <Row>
              <Col>{translate("apartment_label_per_night", activeLocale)}</Col>
            </Row>
            <Row>
              <Col>
                <h4 className={styles.price}>{pickPrice()} CHF</h4>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default PriceViewer;
