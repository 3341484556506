// icon:switch | Entypo http://entypo.com/ | Daniel Bruce
import styles from "./component.module.css";

function SwitchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 1000 1000" fill="currentColor" className={styles.icon} height="1em" width="1em" {...props}>
      <path d="M700 258v140H200v90L0 328l200-170v100h500m300 420L800 838v-90H300V608h500V508l200 170" />
    </svg>
  );
}

export default SwitchIcon;
