import { Col, Container, Row } from "react-bootstrap";
import { Saison, SaisonEntry } from "../../../../../infrastructure/types";
import styles from "./component.module.css";
import { useEffect, useState } from "react";
import SaisonEntryItem from "./SaisonEntryItem";
import SaisonEntryAddItem from "./SaisonEntryAddItem";
import { AdvancedSaisonEntry, SECollection } from "../type";
import { TranslationHandler } from "../../../../Utils/TranslationProvider";

type SaisonEntryHandlerProps = {
  saisonEntryCollection: SECollection;
  selectedYear: number;

  onDataHasChanged: (isInvalid: boolean, changedSaisonEntries: SECollection) => void;
};

type FormValues = {
  usedSaisonEntryCollection: SECollection;
};

type FormError = {
  hasConflictWithExistingEntryOnAdd: string | null;
  hasConflictWithExistingEntry: string | null;
};

function sortByFromDate(inputMap: Map<number, AdvancedSaisonEntry>): SECollection {
  // Convert the map entries to an array
  const entriesArray = Array.from(inputMap.entries());

  // Sort the array based on fromDate property
  entriesArray.sort((a, b) => {
    const dateA = new Date(a[1].fromDate).getTime();
    const dateB = new Date(b[1].fromDate).getTime();
    return dateA - dateB;
  });

  // Create a new map from the sorted array
  const sortedMap = new Map(entriesArray);

  return sortedMap;
}

function isSaisonEntryOverlaping(currentPos: number, entryToValidate: AdvancedSaisonEntry, saisonEntryCollection: SECollection): number | null {
  for (const [pos, saisonEntry] of saisonEntryCollection.entries()) {
    // skip same record
    if (pos === currentPos) continue;

    const fromNew = new Date(entryToValidate.fromDate).getTime();
    const toNew = new Date(entryToValidate.toDate).getTime();
    const fromExisting = new Date(saisonEntry.fromDate).getTime();
    const toExisting = new Date(saisonEntry.toDate).getTime();

    // Check for non-overlapping conditions
    if (fromNew > toExisting || toNew < fromExisting) {
      continue;
    } else {
      // Overlapping intervals found
      return pos;
    }
  }

  return null;
}

function SaisonEntryHandler(props: SaisonEntryHandlerProps) {
  const { translate } = TranslationHandler();
  const { saisonEntryCollection, selectedYear, onDataHasChanged } = props;

  const [formValues, setFormValues] = useState<FormValues>({ usedSaisonEntryCollection: new Map() });
  const [formErrors, setFormErrors] = useState<FormError>({ hasConflictWithExistingEntryOnAdd: null, hasConflictWithExistingEntry: null });

  const setFormValueField = (fieldName: keyof FormValues, value: any) => {
    const changedFormValues: FormValues = { ...formValues, [fieldName]: value };

    setFormValues(changedFormValues);
    setFormErrors({ hasConflictWithExistingEntryOnAdd: null, hasConflictWithExistingEntry: null });

    onDataHasChanged(false, changedFormValues.usedSaisonEntryCollection);
  };

  const setFormErrorField = (fieldName: keyof FormError, value: any) => {
    const updatedFormError = { ...formErrors, [fieldName]: value };
    setFormErrors(updatedFormError);
    onDataHasChanged(true, new Map());
  };

  const buildItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    const ordered = sortByFromDate(formValues.usedSaisonEntryCollection);

    for (const [pos, saisonEntry] of ordered) {
      const item = (
        <SaisonEntryItem
          isNew={saisonEntry.isNew}
          key={pos}
          hasConflictError={formErrors.hasConflictWithExistingEntry}
          pos={pos}
          saisonEntry={saisonEntry}
          remove={handleRemove}
          dataHasChanged={handleChildDataHasChanged}
        />
      );
      items.push(item);
    }

    return items;
  };

  const validate = (newSaisonEntry: AdvancedSaisonEntry, currentPos: number) => {
    const isYearValid = selectedYear == newSaisonEntry.fromDate.getFullYear() || selectedYear == newSaisonEntry.toDate.getFullYear();
    if (!isYearValid) {
      setFormErrorField("hasConflictWithExistingEntryOnAdd", "wrong year");
      return false;
    }

    const overlapingPos = isSaisonEntryOverlaping(currentPos, newSaisonEntry, formValues.usedSaisonEntryCollection);
    if (overlapingPos !== null) {
      setFormErrorField("hasConflictWithExistingEntryOnAdd", `overlapingPos with ${overlapingPos}`);
      return false;
    }

    return true;
  };

  const handleOnAddClicked = (newSaisonEntry: AdvancedSaisonEntry): void => {
    const nextEntryCount = formValues.usedSaisonEntryCollection.size;

    const isValid = validate(newSaisonEntry, nextEntryCount);
    if (!isValid) return;

    newSaisonEntry.isNew = true;

    const cache = new Map(formValues.usedSaisonEntryCollection);
    cache.set(nextEntryCount, newSaisonEntry);

    setFormValueField("usedSaisonEntryCollection", cache);
  };

  const handleRemove = (pos: number) => {
    const cache = new Map(formValues.usedSaisonEntryCollection);
    cache.delete(pos);

    setFormValueField("usedSaisonEntryCollection", cache);
  };

  const handleChildDataHasChanged = (pos: number, changedSaisonEntry: AdvancedSaisonEntry) => {
    const isValid = validate(changedSaisonEntry, pos);
    if (!isValid) return;

    const cache = new Map(formValues.usedSaisonEntryCollection);
    cache.set(pos, changedSaisonEntry);

    setFormValueField("usedSaisonEntryCollection", cache);
  };

  useEffect(() => {
    const usedSaisonEntryCollection: SECollection = new Map(saisonEntryCollection);

    setFormValues({ ...formValues, usedSaisonEntryCollection });
  }, [selectedYear, saisonEntryCollection]);

  return (
    <Container>
      <Row>
        <Col>
          <b>{translate("label_add")}</b>
          <SaisonEntryAddItem hasConflictWithError={formErrors.hasConflictWithExistingEntryOnAdd} key={"addSaisonEntry"} onAddClicked={handleOnAddClicked} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <b>{translate("saison_label_entries")}</b>
          {buildItems()}
        </Col>
      </Row>
    </Container>
  );
}

export default SaisonEntryHandler;
