import { Col, Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewProps } from "./type";
import ListViewItem from "./ListViewItem";
import { UUID } from "../../../../infrastructure/types";
import Menu from "../Menu/Menu";
import ListViewAddItem from "./ListViewAddItem";

function ListView(props: ListViewProps) {
  const { items, onChildClicked, onChildDeletedClicked, onAddClicked, onSearch, enableSaveBtn } = props;

  const listViewItems = [<ListViewAddItem key="add" onClicked={onAddClicked} />];
  for (const item of items) {
    const { id, name, svg } = item;
    const listViewItem = <ListViewItem svg={svg} name={name} key={id} id={id} onDeleteClicked={onChildDeletedClicked} onClicked={onChildClicked} />;
    listViewItems.push(listViewItem);
  }

  return (
    <Container fluid>
      <Row className={`${styles.container} g-4`}>
        <Menu onSearchTyped={onSearch} />
      </Row>

      <Row className={`${styles.container} g-4`}>
        <Container>
          <Row>{listViewItems}</Row>
        </Container>
      </Row>
    </Container>
  );
}

export default ListView;
