import React from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/index.css";

import App from "./components/App/App";

import initApiClient from "./interface/client/apiClient";
import { TranslationProvider } from "./components/Utils/TranslationProvider/TranslationProvider";

initApiClient(process.env.REACT_APP_BE_URL);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <TranslationProvider>
      <App />
    </TranslationProvider>
  </React.StrictMode>
);
