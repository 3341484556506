import styles from "./component.module.css";
import sharedStyles from "../component.shared.module.css";

import { useNavigate } from "react-router-dom";
import ApiClient from "../../interface/client/apiClient";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import Utils from "../../infrastructure/utils";
import { useEffect, useState } from "react";
import ToastContainerFactory, { toastErrorWithCorrelationID } from "../Utils/ToastContainerFactory/ToastContainerFactory";
import { useParams } from "react-router-dom";
import { TranslationHandler } from "../Utils/TranslationProvider";
import { Locale } from "../../infrastructure/enums";

type PasswordResetViewProps = {
  variante: "invite" | "reset";
};

type FormValues = {
  password: string;
  repeatedPassword: string;
};

type ErrorValues = Partial<FormValues>;

function PasswordResetView(props: PasswordResetViewProps) {
  const { variante } = props;
  const { tokenIdentifier, locale } = useParams();

  const { setLocale, translate } = TranslationHandler();

  const Navigate = useNavigate();

  const defaultFormValues: FormValues = { password: "", repeatedPassword: "" };
  const [formValue, setFormValue] = useState<FormValues>(defaultFormValues);
  const [InputErrors, setInputErrors] = useState<ErrorValues>({});

  let titleTr = translate("pwdReset_invite_title");
  let content = translate("pwdReset_content");

  if (variante === "reset") {
    titleTr = translate("pwdReset_reset_title");
  }

  useEffect(() => {
    setLocale(locale as Locale);
  });

  const setField = (field: keyof FormValues, value: string) => {
    setFormValue({ ...formValue, [field]: value });

    // remove entry from InputErros, if exists
    if (!!InputErrors[field]) setInputErrors({ ...InputErrors, [field]: null });
  };

  const hasError = (field: keyof FormValues) => {
    return InputErrors[field] != null;
  };
  const getError = (field: keyof FormValues) => {
    return InputErrors[field];
  };

  const validateForm = () => {
    const foundErrors: ErrorValues = {};

    let isNotValid = formValue.password.length < 6;
    if (isNotValid) foundErrors.password = translate("pwdReset_error_pwd_not_valid");

    isNotValid = formValue.repeatedPassword.length < 6;
    if (isNotValid) foundErrors.repeatedPassword = translate("pwdReset_error_pwd_not_valid");

    isNotValid = formValue.password !== formValue.repeatedPassword;
    if (isNotValid) foundErrors.repeatedPassword = translate("pwdReset_error_pwds_not_equal");

    setInputErrors(foundErrors);

    const isValid = Object.keys(foundErrors).length === 0;
    return isValid;
  };

  const handlePwdResetTriggered = async (event: any) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) return;

    const { password, repeatedPassword } = formValue;

    const { error, data, errorTRKey, correlationID } = await ApiClient().resetPwd(password, repeatedPassword, tokenIdentifier as string);
    if (error === null) {
      const { token } = data;
      Utils.setAsLoggedIn(token);

      const getAdminUserGetMeResponse = await ApiClient().adminUserGetMe();
      if (getAdminUserGetMeResponse.error !== null) Utils.setAsLogout();

      Utils.updateIdentity(token, getAdminUserGetMeResponse.data.firstName, getAdminUserGetMeResponse.data.lastName, getAdminUserGetMeResponse.data.type);

      Navigate("/");
      return;
    }

    toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
  };

  return (
    <>
      <ToastContainerFactory />

      <div className={sharedStyles.bg}>
        <Card className={`${sharedStyles.glas_card}`}>
          <Image src="/FeWoServ_logo.png" />
          <Card.Title className={`${styles.title} ${styles.pushDown1Em}`}>
            <h4>{titleTr}</h4>
            <br />
            <span>{content}</span>
          </Card.Title>
          <Card.Body className={styles.clear}>
            <Container fluid className={styles.clear}>
              <Row className={styles.pushDown1Em}>
                <Col>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder={translate("pwdReset_placeholder_new_pwd")}
                    autoComplete="password"
                    onChange={(e) => setField("password", e.target.value)}
                    value={formValue.password}
                    isInvalid={hasError("password")}
                    className={sharedStyles.glas_form}
                  />
                  <Form.Control.Feedback type="invalid">{getError("password")}</Form.Control.Feedback>
                </Col>
              </Row>
              <Row className={styles.pushDown1Em}>
                <Col>
                  <Form.Control
                    type="password"
                    name="passwordReset"
                    placeholder={translate("pwdReset_placeholder_repeat_new_pwd")}
                    autoComplete="password"
                    onChange={(e) => setField("repeatedPassword", e.target.value)}
                    value={formValue.repeatedPassword}
                    isInvalid={hasError("repeatedPassword")}
                    className={sharedStyles.glas_form}
                  />
                  <Form.Control.Feedback type="invalid">{getError("repeatedPassword")}</Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" size="sm" variant="outline-dark" className="float-end button" onClick={handlePwdResetTriggered}>
                    {translate("modal_btn_lets_go")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default PasswordResetView;
