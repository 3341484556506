import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import styles from "./component.module.css";
import sharedStyles from "../../component.shared.module.css";
import Menu from "./Menu/Menu";
import { EditViewProps } from "./type";
import Description from "./Description/Description";
import { News, Translation, UUID } from "../../../infrastructure/types";
import { useEffect, useState } from "react";
import { Mode } from "../../../interface/client/enums";
import Utils from "../../../infrastructure/utils";
import TimePicker from "./TimePicker/TimePicker";
import { TranslationHandler } from "../../Utils/TranslationProvider";

type FormValues = {
  title: Translation;
  content: Translation;
  publishDateAt: string;
  publishHourAt: string;
  publishMinuteAt: string;
  active: boolean;
};

type ErrorValues = { title?: string; content?: string };

type RecordData = Pick<FormValues, "title" | "content" | "publishDateAt" | "publishHourAt" | "publishMinuteAt" | "active">;

function EditView(props: EditViewProps) {
  const { abortClicked, deleteClicked, savedClicked, mode, news } = props;
  const { translate } = TranslationHandler();

  const currentDate = new Date();
  const defaultFormValues = {
    active: false,
    content: { deDE: "", enGB: "", frFR: "", itIT: "" },
    publishDateAt: Utils.formatDateForDatePicker(currentDate),
    publishHourAt: Utils.formatDateForTimePicker(currentDate, "hour"),
    publishMinuteAt: Utils.formatDateForTimePicker(currentDate, "minute"),
    title: { deDE: "", enGB: "", frFR: "", itIT: "" },
  };

  const [recordData, setRecordData] = useState<RecordData>(defaultFormValues);
  const [errorValues, setErrorValues] = useState<ErrorValues>({});

  const [formValues, setFormValues] = useState<FormValues>(defaultFormValues);
  const setFieldValue = (fieldName: keyof FormValues, value: any) => {
    setFormValues({ ...formValues, [fieldName]: value });

    // remove entry from InputErros, if exists
    if (fieldName in errorValues) setErrorValues({ ...errorValues, [fieldName]: null });
  };

  const buildRecordDataFromFormValue = (_formData?: FormValues): RecordData => {
    const { title, content, publishDateAt, publishHourAt, publishMinuteAt, active } = _formData ? _formData : formValues;
    const recordData: RecordData = {
      title,
      content,
      publishDateAt,
      publishHourAt,
      publishMinuteAt,
      active,
    };

    return recordData;
  };

  const handlePublishDateActive = (event: any) => {
    const isChecked = event.target.checked;

    if (!isChecked) {
      setFormValues({ ...formValues, active: isChecked });
      return;
    }

    setFieldValue("active", isChecked);
  };

  useEffect(() => {
    const currentDate = new Date();

    const formValues: Partial<FormValues> = {};
    formValues.content = { deDE: "", enGB: "", frFR: "", itIT: "" };
    formValues.publishDateAt = Utils.formatDateForDatePicker(currentDate);
    formValues.publishHourAt = Utils.formatDateForTimePicker(currentDate, "hour");
    formValues.publishMinuteAt = Utils.formatDateForTimePicker(currentDate, "minute");
    formValues.active = false;
    formValues.title = { deDE: "", enGB: "", frFR: "", itIT: "" };

    if (mode === Mode.EDIT && news) {
      formValues.content = news.content;
      formValues.publishDateAt = Utils.formatDateForDatePicker(news.publishAt);
      formValues.publishHourAt = Utils.formatDateForTimePicker(news.publishAt, "hour");
      formValues.publishMinuteAt = Utils.formatDateForTimePicker(news.publishAt, "minute");
      formValues.title = news.title;
      formValues.active = news.active;
    }

    setFormValues(formValues as FormValues);
    setRecordData(buildRecordDataFromFormValue(formValues as FormValues));
  }, [news, mode]);

  const handleDateChanged = (e: any) => {
    setFieldValue("publishDateAt", e.target.value);
  };

  const validate = () => {
    const { title, content } = formValues;
    const foundErrors: ErrorValues = {};

    const checkIsValid = (translation: Translation): boolean => {
      let { deDE, enGB, frFR, itIT } = translation;
      return ![!!deDE, !!enGB, !!frFR, !!itIT].some(Boolean);
    };

    let isNotValid = checkIsValid(title);
    if (isNotValid) foundErrors.title = translate("news_error_title");

    isNotValid = checkIsValid(content);
    if (isNotValid) foundErrors.content = translate("news_error_content");

    setErrorValues(foundErrors);

    const isValid = Object.keys(foundErrors).length === 0;
    return isValid;
  };

  const handleSaveClicked = () => {
    const isValid = validate();
    if (!isValid) return;

    const { content, publishDateAt, publishHourAt, publishMinuteAt, title, active } = formValues;
    const usableDate = Utils.buildDateFrom(publishDateAt, publishHourAt, publishMinuteAt);

    switch (mode) {
      case Mode.ADD:
        const newNews: Partial<News> = { content, title: title };
        if (publishDateAt) newNews.publishAt = usableDate;

        savedClicked(newNews as News);
        setRecordData(buildRecordDataFromFormValue());

        break;

      case Mode.EDIT:
        const cachedNews = { ...props.news };
        cachedNews.content = content;
        cachedNews.title = title;
        cachedNews.publishAt = usableDate;
        cachedNews.active = active;

        savedClicked(cachedNews as News);
        setRecordData(buildRecordDataFromFormValue());

        break;
    }
  };

  const hasSomethingChanged = (): boolean => {
    const compData = buildRecordDataFromFormValue();
    return Utils.areObjEqual(compData, recordData);
  };

  return (
    <Container fluid>
      <Row className={`${styles.menu} g-4`}>
        <Menu
          mode={mode}
          onAbortClicked={abortClicked}
          onDeleteClicked={() => deleteClicked(props.news?.id as UUID)}
          onSaveClicked={handleSaveClicked}
          disableSave={hasSomethingChanged()}
        />
      </Row>
      <Row className={`${styles.container} g-4`}>
        <Col>
          <Row>
            <Col xs={2}>
              <b>{translate("news_label_published_on")}</b>
            </Col>
            <Col xs={5} className={styles.pullLeft}>
              <InputGroup className="mb-3">
                <InputGroup.Checkbox checked={formValues.active} onChange={handlePublishDateActive} />
                <Form.Control disabled={!formValues.active} type="date" value={formValues.publishDateAt as any} onChange={handleDateChanged} />
                <TimePicker
                  disabled={!formValues.active}
                  valueChanged={(value: string) => setFieldValue("publishHourAt", value)}
                  selectValue={formValues.publishHourAt}
                  range={23}
                />
                <InputGroup.Text>:</InputGroup.Text>
                <TimePicker
                  disabled={!formValues.active}
                  valueChanged={(value: string) => setFieldValue("publishMinuteAt", value)}
                  selectValue={formValues.publishMinuteAt}
                  range={60}
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{translate("lable_title")}</b>
              <Description rowDefaultCount={1} description={formValues.title} onChanged={(changedTitle: Translation) => setFieldValue("title", changedTitle)} />
              {errorValues.title && <div className={sharedStyles.errorTextStyle}>{errorValues.title}</div>}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{translate("label_news")}</b>
              <Description
                rowDefaultCount={9}
                description={formValues.content}
                onChanged={(changedContent: Translation) => setFieldValue("content", changedContent)}
              />
              {errorValues.content && <div className={sharedStyles.errorTextStyle}>{errorValues.content}</div>}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default EditView;
