import styles from "./component.module.css";

// icon:language | Tabler Icons https://tablericons.com/ | Csaba Kissi
function TranslationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={styles.icon}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M4 5h7M9 3v2c0 4.418-2.239 8-5 8M5 9c-.003 2.144 2.952 3.908 6.7 4M12 20l4-9 4 9M19.1 18h-6.2" />
    </svg>
  );
}

export default TranslationIcon;
