import sharedStyles from "../component.shared.module.css";

import ListView from "./ListView/ListView";
import { useEffect, useState } from "react";
import ApiClient from "../../interface/client/apiClient";

import EditView from "./EditView/EditView";
import { Mode } from "../../interface/client/enums";
import { TranslationHandler } from "../Utils/TranslationProvider";
import { AdminUser, UUID } from "../../infrastructure/types";
import { AdminUserType } from "../../infrastructure/enums";
import ToastContainerFactory from "../Utils/ToastContainerFactory/ToastContainerFactory";
import { Col, Container, Row } from "react-bootstrap";
import Utils from "../../infrastructure/utils";

type FormValues = {
  mode: Mode;
  selectedRecord: AdminUser | undefined;
  isLoading: boolean;
  adminUserType: AdminUserType | null;
  search: string | undefined;
  recordCollection: Map<UUID, AdminUser>;
};

function AdminUserView() {
  if (!Utils.isLoggedIn()) return <></>;

  const [formValues, setFormValues] = useState<FormValues>({
    mode: Mode.DEFAULT,
    selectedRecord: undefined,
    adminUserType: null,
    isLoading: true,
    search: undefined,
    recordCollection: new Map(),
  });

  const setFormValueField = (fieldName: keyof FormValues, value: any): void => {
    setFormValues({ ...formValues, [fieldName]: value });
  };

  const { translate } = TranslationHandler();

  useEffect(() => {
    const preloadData = async () => {
      const response = await ApiClient().adminUserGetMany(formValues.search, formValues.adminUserType);
      if (response.error === null) {
        // content was already loaded
        if (!formValues.isLoading) return;

        const recordCollection = new Map();
        for (const adminUser of response.data) recordCollection.set(adminUser.id, adminUser);

        setFormValues({ ...formValues, recordCollection, isLoading: false });
      }
    };

    if (formValues.mode === Mode.DEFAULT) preloadData();
  });

  const handleAddHasClicked = () => {
    setFormValueField("mode", Mode.ADD);
  };

  const handleAbortClicked = () => {
    setFormValues({ ...formValues, mode: Mode.DEFAULT, isLoading: true });
  };

  const handleSearchTyped = (searchText: string) => {
    setFormValues({ ...formValues, search: searchText, isLoading: true });
  };

  const handleAdminUserTypeChanged = (adminUserType: number) => {
    const usedAdminUserType = adminUserType != -1 ? adminUserType : null;
    setFormValues({ ...formValues, adminUserType: usedAdminUserType, isLoading: true });
  };

  const handleListViewChildClicked = (editChildID: UUID) => {
    const selectedRecord = formValues.recordCollection.get(editChildID) as AdminUser;
    setFormValues({ ...formValues, selectedRecord, mode: Mode.EDIT });
  };

  const handleNewRealEstateHasBeenAdded = () => {
    setFormValues({ ...formValues, mode: Mode.EDIT });
  };

  let subTitle = translate("adminUser_header_subtitle_selection");
  let usedComponent = <></>;
  switch (formValues.mode) {
    case Mode.DEFAULT:
      usedComponent = (
        <ListView
          adminUsers={[...formValues.recordCollection.values()]}
          onAddClicked={handleAddHasClicked}
          onSearchTyped={handleSearchTyped}
          onAdminUserTypeChanged={handleAdminUserTypeChanged}
          triggerIsLoading={false}
          onChildClicked={handleListViewChildClicked}
        />
      );
      break;
    case Mode.ADD:
      subTitle = translate("adminUser_header_subtitle_add");
      usedComponent = <EditView mode={Mode.ADD} hasNewRealEstateBeenAdded={handleNewRealEstateHasBeenAdded} close={handleAbortClicked} />;
      break;
    case Mode.EDIT:
      subTitle = `${translate("adminUser_header_subtitle_edit")} ${formValues.selectedRecord?.firstName} ${formValues.selectedRecord?.lastName} `;
      usedComponent = (
        <EditView
          mode={Mode.EDIT}
          recordID={formValues.selectedRecord?.id}
          hasNewRealEstateBeenAdded={handleNewRealEstateHasBeenAdded}
          close={handleAbortClicked}
        />
      );
      break;
  }

  return (
    <>
      <ToastContainerFactory />

      <Container fluid className={sharedStyles.component}>
        <div className={sharedStyles.headLine}>
          <div className={sharedStyles.pullRightHeadlineContent}>
            <Row>
              <Col>
                <h2>{translate("component_title_AdminUser")} </h2>
              </Col>
            </Row>
            <Row>
              <Col className={sharedStyles.pullUpHeadlineContent}>{subTitle}</Col>
            </Row>
          </div>
        </div>

        {usedComponent}
      </Container>
    </>
  );
}

export default AdminUserView;
