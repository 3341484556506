import { Card } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewItemProps } from "./type";

import ApiClient from "../../../interface/client/apiClient";
import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";

function ListViewItem(props: ListViewItemProps) {
  const { onClicked, name, pictureID } = props;
  const [usedImg, setUsedImg] = useState(<Card.Img style={{ opacity: 0.05 }} className={styles.img} src={"/FeWoServ_logo.png"} />);
  const [isLoading, setIsLoading] = useState(true);

  const handleOnClick = () => onClicked(props.id);

  useEffect(() => {
    const loadImage = async () => {
      const pictureResponse = await ApiClient().pictureGet(pictureID);
      if (pictureResponse.error === null) {
        const { raw } = pictureResponse.data;

        setUsedImg(<Card.Img className={styles.img} src={raw} />);
        setIsLoading(false);
      }
    };

    if (pictureID) loadImage();
  }, [pictureID]);

  return (
    <Card className={`${styles.card} ${styles.clearPadding}`} onClick={handleOnClick}>
      <Card.Header className={styles.cardHeader}>
        <h5>{name}</h5>
      </Card.Header>
      <div className={styles.spinnerContainer}>{isLoading && <PuffLoader speedMultiplier={0.5} color="rgba(85, 90, 89, 0.41)" />}</div>
      <Card.Body className={`${styles.cardBody} ${styles.clearPadding}`}>{usedImg}</Card.Body>
    </Card>
  );
}

export default ListViewItem;
