import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { Attribute, Translation } from "../../../../infrastructure/types";

import styles from "./component.module.css";
import { Col, Form, Row } from "react-bootstrap";
import Description from "./Description";
import { Mode } from "../../../../interface/client/enums";
import Utils from "../../../../infrastructure/utils";

type AttributeModalProps = {
  showModal: boolean;
  modalTitleTranslationKey: string;
  attribute: Attribute | null;
  mode: Mode;

  onAssume: (attribute: Attribute) => void;
  close: () => void;
};

type FormValues = {
  usedName: Translation;
  usedSVG: string;

  usedAttribute: Attribute | null;
  show: boolean;
  mode: Mode;
};

type RecordData = Pick<FormValues, "usedName" | "usedSVG">;

type ErrorValues = {
  name?: string;
};

const defaultSvg = ` <svg
viewBox="0 0 1024 1024"
fill="currentColor"
height="1em"
width="1em"
{...props}
>
<path d="M854.6 288.7c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2zM402 549c0 5.4 4.4 9.5 9.8 9.5h32.4c5.4 0 9.8-4.2 9.8-9.4 0-28.2 25.8-51.6 58-51.6s58 23.4 58 51.5c0 25.3-21 47.2-49.3 50.9-19.3 2.8-34.5 20.3-34.7 40.1v32c0 5.5 4.5 10 10 10h32c5.5 0 10-4.5 10-10v-12.2c0-6 4-11.5 9.7-13.3 44.6-14.4 75-54 74.3-98.9-.8-55.5-49.2-100.8-108.5-101.6-61.4-.7-111.5 45.6-111.5 103zm110 227a32 32 0 100-64 32 32 0 000 64z" />
</svg>`;

function EditModal(props: AttributeModalProps) {
  const { close, onAssume, attribute, modalTitleTranslationKey, showModal, mode } = props;
  const { translate, translateObj } = TranslationHandler();

  const defaultFormValues = { mode: Mode.ADD, show: showModal, usedAttribute: attribute, usedSVG: defaultSvg, usedName: {} };
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValues);
  const [recordData, setRecordData] = useState<RecordData>(defaultFormValues);

  const [errorValues, setErrorValues] = useState<ErrorValues>({});

  const setFormValueField = (fieldName: keyof FormValues, value: any) => {
    setFormValues({ ...formValues, [fieldName]: value });

    setErrorValues({});
  };

  const setErrorField = (fieldName: keyof ErrorValues, value: string): void => {
    setErrorValues({ ...errorValues, [fieldName]: value });
  };

  const buildRecordDataFromFormValue = (_formData?: FormValues): RecordData => {
    const { usedName, usedSVG } = _formData ? _formData : formValues;
    const recordData: RecordData = {
      usedName,
      usedSVG,
    };

    return recordData;
  };

  const validate = (): boolean => {
    const { usedName } = formValues;

    const { deDE, enGB, frFR, itIT } = usedName;
    const isValid = [!!deDE, !!enGB, !!frFR, !!itIT].some(Boolean);
    if (!isValid) setErrorField("name", translate("attribute_error_text_no_name"));
    return isValid;
  };

  useEffect(() => {
    const usedSVG = attribute ? attribute.svg : defaultSvg;
    const usedName = attribute ? attribute.name : {};

    const usedFormValues = { ...formValues, show: showModal, usedAttribute: attribute, usedSVG, usedName, mode };
    setFormValues(usedFormValues);
    setRecordData(buildRecordDataFromFormValue(usedFormValues));
  }, [showModal, attribute]);

  const handleSvgChanged = (e: any) => {
    const value = e.target.value;

    if (value.includes("svg")) setFormValueField("usedSVG", value);
  };

  const hasSomethingChanged = (): boolean => {
    const compData = buildRecordDataFromFormValue();
    return Utils.areObjEqual(compData, recordData);
  };

  const handleOnAssume = () => {
    const isValid = validate();
    if (!isValid) return;

    const cachedAttribute = { ...attribute } as Attribute;
    cachedAttribute.svg = formValues.usedSVG;
    cachedAttribute.name = formValues.usedName;

    onAssume(cachedAttribute);
  };

  const SVGRenderer: React.FC<{ svgString: string }> = ({ svgString }) => {
    if (!svgString) return <></>;

    return <div className={styles.pullLeft5} dangerouslySetInnerHTML={{ __html: svgString }} />;
  };

  let title = translate(modalTitleTranslationKey);
  if (mode === Mode.EDIT && attribute) title += `:  ${translateObj(attribute.name)}`;

  return (
    <Modal show={formValues.show} onHide={close} aria-labelledby="contained-modal-title-vcenter" size="lg" centered keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Description description={formValues.usedName} onChanged={(translation: Translation) => setFormValueField("usedName", translation)} />
            <div className={styles.error}>{errorValues.name}</div>
          </Col>
        </Row>
        <h3>Icon</h3>
        <Row>
          <Col xs={1}>
            <SVGRenderer svgString={formValues.usedSVG as string} />
          </Col>
          <Col>
            <Form>
              <Form.Control type="text" value={formValues.usedSVG} onChange={(e) => handleSvgChanged(e)} />
            </Form>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {translate("modal_btn_abort")}
        </Button>
        <Button variant="primary" onClick={handleOnAssume} disabled={!hasSomethingChanged()}>
          {translate("modal_btn_ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
