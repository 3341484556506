import React, { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import ApiClient from "../../../../interface/client/apiClient";
import { UUID } from "../../../../infrastructure/types";
import { PuffLoader } from "react-spinners";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./component.module.css";
import { Col, Container, Row } from "react-bootstrap";
import ArrowLeftIcon from "../../../Icons/ArrowLeftIcon";
import ArrowRightIcon from "../../../Icons/ArrowRightIcon";

export type ImageCarouselProps = { pictureIDs: UUID[] };

type ImageCarouselItemProps = { pictureID: UUID };

const ImageCarouselItem: React.FC<ImageCarouselItemProps> = ({ pictureID }) => {
  const [usedImg, setUsedImg] = useState(<img style={{ opacity: 0.05 }} className={styles.img} src={"/FeWoServ_logo.png"} />);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadImage = async () => {
      const pictureResponse = await ApiClient().pictureGet(pictureID);
      if (pictureResponse.error === null) {
        const { raw } = pictureResponse.data;
        setUsedImg(<img className={styles.img} src={raw} />);
        setIsLoading(false);
      }
    };

    if (pictureID) loadImage();
  }, [pictureID]);

  return (
    <div>
      <div className={styles.centerContainer}>{isLoading && <PuffLoader speedMultiplier={0.5} color="rgba(85, 90, 89, 0.41)" />}</div>
      {usedImg && usedImg}
    </div>
  );
};

function ImageCarousel(props: ImageCarouselProps) {
  const { pictureIDs } = props;

  const sliderRef = useRef<Slider>(null);
  const [slider, setSlider] = useState<Slider | null>(null);

  useEffect(() => {
    setSlider(sliderRef.current);
  }, []);

  const next = () => {
    slider?.slickNext();
  };

  const previous = () => {
    slider?.slickPrev();
  };

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  let usedComp = <ImageCarouselItem key={pictureIDs[0]} pictureID={pictureIDs[0]} />;

  if (pictureIDs.length > 1) {
    usedComp = (
      <Slider ref={sliderRef} {...settings}>
        {pictureIDs.map((pictureID) => (
          <ImageCarouselItem key={pictureID} pictureID={pictureID} />
        ))}
      </Slider>
    );
  }

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row className={styles.slider}>
        <Col xs={1} className={styles.arrow} style={{ position: "relative", right: "-3em" }}>
          <div onClick={previous}>
            <ArrowLeftIcon style={{ position: "relative", left: "-0.6em" }} />
          </div>
        </Col>
        <Col xs={10}>{usedComp}</Col>
        <Col xs={1} className={styles.arrow} style={{ position: "relative", right: "3em" }}>
          <div onClick={next}>
            <ArrowRightIcon style={{ position: "relative", left: "-0.8em" }} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ImageCarousel;
