// icon:button_add | System UIcons https://systemuicons.com/ | Corey Ginnivan
import * as React from "react";
import styles from "./component.module.css";

function AddIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={`${styles.addIcon} float-end`} viewBox="0 0 21 21" fill="currentColor" height="1em" width="1em" {...props}>
      <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M16.5 14.5v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2zM10.5 7.5v6.056M13.5 10.5h-6" />
      </g>
    </svg>
  );
}

export default AddIcon;
