import { Col, Container, OverlayTrigger, Popover, Row } from "react-bootstrap";
import Utils from "../../../../infrastructure/utils";

import styles from "./component.module.css";
import { BookingSummary, UUID } from "../../../../infrastructure/types";
import AdultIcon from "../../../Icons/AdultIcon";
import ChildIcon from "../../../Icons/ChildIcon";
import PetIcon from "../../../Icons/PetIcon";
import MessageIcon from "../../../Icons/MessageIcon";
import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { BookingStatus } from "../../../../infrastructure/enums";

const bookingStatus = new Map([
  [0, "booking_state_available"],
  [1, "booking_state_reserved"],
  [2, "booking_state_confirmed"],
  [3, "booking_state_canceled"],
  [4, "booking_state_completed"],
  [5, "booking_state_blocked"],
  [6, "booking_state_blocked"],
]);

const bookingStatusStyle = new Map([
  [0, styles.available],
  [1, styles.reserved],
  [2, styles.confirmed],
  [3, styles.canceled],
  [4, styles.completed],
  [5, styles.blocked],
  [6, styles.blockedByAdmin],
]);

export type ViewPortDates = {
  fromDate: Date;
  toDate: Date;
};

type BookingItemProps = {
  date: Date;
  apartmentID: UUID;

  isInPast: boolean;
  isNotFinishedYet: boolean;
  daysLeft: number;

  bookingSummary: BookingSummary;

  onClicked: (date: Date, apartmentID: UUID) => void;
};

function BookingItem(props: BookingItemProps) {
  const { translate } = TranslationHandler();

  const popover = (bookingSummary: BookingSummary) => {
    const { guestInfo, fromDate, priceSummary, status, stayDays, toDate, userName } = bookingSummary;
    const { adultAmount, childAmount, petAmount } = guestInfo;
    const { total } = priceSummary;

    return (
      <Popover id="popover-basic">
        <Popover.Header as="h3" className={bookingStatusStyle.get(status)}>
          <Container className={styles.clear}>
            <Row>
              <Col>
                <strong>{translate(bookingStatus.get(status) as string)}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                {Utils.formatDateForSaison(fromDate)} - {Utils.formatDateForSaison(toDate)} / {stayDays} {translate("booking_booking_item_days")}
              </Col>
            </Row>
          </Container>
        </Popover.Header>
        <Popover.Body>
          <Container className={styles.clear}>
            <Row>
              <Col></Col>
            </Row>

            <Row>
              <Container className={`${styles.pullLeft1em} ${styles.pushDown1em}`}>
                <Row>
                  <Col>
                    <AdultIcon className={styles.iconAdult} />
                    {adultAmount}
                  </Col>
                  <Col>
                    <ChildIcon className={styles.iconChild} /> {childAmount}
                  </Col>
                  <Col>
                    <PetIcon className={styles.iconPet} /> {petAmount}
                  </Col>
                </Row>
              </Container>
            </Row>

            <Row className={styles.pushDown05em}>
              <Col>
                {props.bookingSummary.messages.length !== 0 && <MessageIcon className={styles.iconMessage} />}
                {`  ${userName}`}
              </Col>
            </Row>

            <hr />

            <Row>
              <Col>
                <strong>{total.toFixed(2)} CHF</strong>
              </Col>
            </Row>
          </Container>
        </Popover.Body>
      </Popover>
    );
  };

  const popoverBlocked = (bookingSummary: BookingSummary) => {
    const { fromDate, status, stayDays, toDate, userName } = bookingSummary;

    return (
      <Popover id="popover-blocked">
        <Popover.Header as="h3" className={bookingStatusStyle.get(status)}>
          <Container className={styles.clear}>
            <Row>
              <Col>
                <strong>{translate(bookingStatus.get(status) as string)}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                {Utils.formatDateForSaison(fromDate)} - {Utils.formatDateForSaison(toDate)} / {stayDays} {translate("booking_booking_item_days")}
              </Col>
            </Row>
          </Container>
        </Popover.Header>
        <Popover.Body>
          <Container className={styles.clear}>
            <Row className={styles.pushDown05em}>
              <Col>{` ${translate("booking_booking_item_blocked_by")} ${userName}`}</Col>
            </Row>
          </Container>
        </Popover.Body>
      </Popover>
    );
  };

  const handleOnClick = () => {
    props.onClicked(props.bookingSummary.fromDate, props.apartmentID);
  };

  const buildInfoText = () => {
    const { guestInfo, userName, status, priceSummary } = props.bookingSummary;

    const totalGuestAmount = guestInfo.adultAmount + guestInfo.childAmount;
    let text = userName + " / " + totalGuestAmount + " " + translate("booking_booking_item_guest") + " / " + priceSummary.total.toFixed(2) + " CHF";

    const isBlocked = [BookingStatus.BLOCKED, BookingStatus.BLOCKED_BY_ADMIN].includes(status);
    if (isBlocked) text = `${translate("booking_booking_item_blocked_by")} ${userName}`;
    
    switch (props.daysLeft) {
      case 5:
        text = `${text.substring(0, 36)} ...`;
        break;
      case 4:
        text = `${text.substring(0, 20)} ...`;
        break;
      case 3:
        text = `${text.substring(0, 12)} ...`;
        break;
      case 2:
        text = `${text.substring(0, 8)} ...`;
        break;
      case 1:
        text = `${text.substring(0, 2)} ...`;
        break;

      default:
        break;
    }

    return text;
  };

  const highlightBackgroundCollection = [styles.itemHeader];

  if (props.isInPast && props.isNotFinishedYet) highlightBackgroundCollection.push(styles.past);

  const higlightBackground = highlightBackgroundCollection.join(" ");

  const isBlocked = props.bookingSummary.status === 6;
  const usedPopover = isBlocked ? popoverBlocked(props.bookingSummary) : popover(props.bookingSummary);

  return (
    <OverlayTrigger key={"top"} placement={"top"} overlay={usedPopover}>
      <Container className={higlightBackground} onClick={() => handleOnClick()}>
        <Row style={{ width: `${props.daysLeft * 4}em` }} className={`${styles.bookingHover} ${bookingStatusStyle.get(props.bookingSummary.status)}`}>
          <Col>{buildInfoText()}</Col>
        </Row>
      </Container>
    </OverlayTrigger>
  );
}

export default BookingItem;
