// icon:favorite | Unicons https://iconscout.com/unicons | Iconscout
import styles from "./component.module.css";

function Disabled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={styles.icon} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M22 9.67a1 1 0 00-.86-.67l-5.69-.83L12.9 3a1 1 0 00-1.8 0L8.55 8.16 2.86 9a1 1 0 00-.81.68 1 1 0 00.25 1l4.13 4-1 5.68a1 1 0 001.47 1.08l5.1-2.67 5.1 2.67a.93.93 0 00.46.12 1 1 0 00.59-.19 1 1 0 00.4-1l-1-5.68 4.13-4A1 1 0 0022 9.67zm-6.15 4a1 1 0 00-.29.88l.72 4.2-3.76-2a1.06 1.06 0 00-.94 0l-3.76 2 .72-4.2a1 1 0 00-.29-.88l-3-3 4.21-.61a1 1 0 00.76-.55L12 5.7l1.88 3.82a1 1 0 00.76.55l4.21.61z" />
    </svg>
  );
}

// icon:favorite | Unicons https://iconscout.com/unicons | Iconscout
function Enabled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={styles.icon} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M17.562 21.56a1 1 0 01-.465-.116L12 18.764l-5.097 2.68a1 1 0 01-1.45-1.053l.973-5.676-4.124-4.02a1 1 0 01.554-1.705l5.699-.828 2.549-5.164a1.04 1.04 0 011.793 0l2.548 5.164 5.699.828a1 1 0 01.554 1.705l-4.124 4.02.974 5.676a1 1 0 01-.985 1.169z" />
    </svg>
  );
}

export default { Disabled, Enabled };
