import { ChangeEvent } from "react";

import { Form } from "react-bootstrap";
import styles from "./component.module.css";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import { AdminUserType } from "../../../infrastructure/enums";
import { TranslationAdminUserMap } from "../ListView/shared";

export type AdminTypeSelecterProps = {
  preSelectType?: number;
  disableAllSelect?: boolean;
  onAdminUserTypeChanged: (adminUserType: number) => void;
};

function AdminTypeSelecter(props: AdminTypeSelecterProps) {
  const { translate } = TranslationHandler();
  const { onAdminUserTypeChanged, preSelectType, disableAllSelect } = props;

  const handleAdminUserTypeOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onAdminUserTypeChanged(Number(event.target.value));
  };

  const buildItem = (pos: number): JSX.Element => {
    const translationIdentifier = TranslationAdminUserMap.get(pos) as string;

    return (
      <option key={pos} value={pos}>
        {translate(translationIdentifier)}
      </option>
    );
  };

  const buildSelectItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    if (!disableAllSelect) {
      const defaultItem = buildItem(-1);
      items.push(defaultItem);
    }

    for (const adminUserTypeID of Object.keys(AdminUserType)) {
      const isEntryNotUsable = isNaN(Number(adminUserTypeID));
      if (isEntryNotUsable) break;

      

      const item = buildItem(Number(adminUserTypeID));
      items.push(item);
    }

    return items;
  };

  return (
    <Form.Select value={preSelectType} onChange={handleAdminUserTypeOnChange} className={styles.removeBorder} key={"select"} aria-label="userAdminType select">
      {buildSelectItems()}
    </Form.Select>
  );
}

export default AdminTypeSelecter;
