export enum Locale {
  "deDE" = "deDE",
  "frFR" = "frFR",
  "itIT" = "itIT",
  "enGB" = "enGB",
}

export enum AdminUserType {
  ADMINISTRATOR = 1,
  REAL_ESTATE_OWNER = 2,
}

export enum SaisonType {
  PEAK = 0,
  HIGH = 1,
  MIDDLE = 2,
  LOW = 3,
}

export enum RequestPermission {
  ADMIN_USER_VIEW = "ADMIN_USER_VIEW",
  ADMIN_USER_EDIT = "ADMIN_USER_EDIT",
  ADMIN_USER_DELETE = "ADMIN_USER_DELETE",
  APARTMENT_VIEW = "APARTMENT_VIEW",
  APARTMENT_EDIT = "APARTMENT_EDIT",
  APARTMENT_DELETE = "APARTMENT_DELETE",
  BOOKING_VIEW = "BOOKING_VIEW",
  BOOKING_EDIT = "BOOKING_EDIT",
  BOOKING_DELETE = "BOOKING_DELETE",
  PERMISSION_VIEW = "PERMISSION_VIEW",
  PERMISSION_EDIT = "PERMISSION_EDIT",
  PERMISSION_DELETE = "PERMISSION_DELETE",
  REAL_ESTATE_VIEW = "REAL_ESTATE_VIEW",
  REAL_ESTATE_EDIT = "REAL_ESTATE_EDIT",
  REAL_ESTATE_DELETE = "REAL_ESTATE_DELETE",
  NEWS_VIEW = "NEWS_VIEW",
  NEWS_EDIT = "NEWS_EDIT",
  NEWS_DELETE = "NEWS_DELETE",
  SETTINGS_VIEW = "SETTINGS_VIEW",
  SETTINGS_EDIT = "SETTINGS_EDIT",
  SETTINGS_DELETE = "SETTINGS_DELETE",
  USER_VIEW = "USER_VIEW",
  USER_EDIT = "USER_EDIT",
  USER_DELETE = "USER_DELETE",
  PICTURE_VIEW = "PICTURE_VIEW",
  PICTURE_EDIT = "PICTURE_EDIT",
  PICTURE_DELETE = "PICTURE_DELETE",
}

export enum BookingStatus {
  AVAILABLE,
  RESERVED,
  CONFIRMED,
  CANCELED,
  COMPLETED,
  BLOCKED,
  BLOCKED_BY_ADMIN,
}
