import { Col, Container, Row } from "react-bootstrap";
import sharedStyles from "../component.shared.module.css";
import ListView from "./ListView/ListView";
import { useEffect, useState } from "react";
import ApiClient from "../../interface/client/apiClient";

import EditView from "./EditView/EditView";
import { ListViewItem } from "./ListView/type";
import { Mode } from "../../interface/client/enums";
import ToastContainerFactory, { toastErrorWithCorrelationID } from "../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../Utils/TranslationProvider";
import { Apartment, UUID } from "../../infrastructure/types";
import ReadOnlyView from "./ReadOnlyView/ReadOnlyView";
import Utils from "../../infrastructure/utils";
import { useLocation, useNavigate } from "react-router-dom";

function ApartmentView() {
  if (!Utils.isLoggedIn()) return <></>;

  const isRealEstateOwner = Utils.getIdentityFromLocalStorage()?.isRealEstateOwner() ?? false;
  const Navigate = useNavigate();
  const urlLocation = useLocation();

  const [mode, setMode] = useState<Mode>(Mode.DEFAULT);
  const [selectedRecord, setSelectedRecord] = useState<Apartment | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string | undefined>();
  const [recordCollection, setRecordCollection] = useState<Map<UUID, Apartment>>(new Map());
  const [listViewItems, setListViewItems] = useState<ListViewItem[]>([]);

  const { translate } = TranslationHandler();

  useEffect(() => {
    const preloadData = async () => {
      const response = await ApiClient().apartmentGetMany(search);
      if (response.error === null) {
        // content was already loaded
        if (!isLoading) return;

        const recordCollection = new Map();
        const listViewItems: ListViewItem[] = [];
        for (const apartment of response.data) {
          const { id, name, pictureIDs, isActive, realEstateName } = apartment;

          const listViewItem: ListViewItem = { id, isActive, pictureID: "", name, realEstateName: "N/A" };
          if (realEstateName) listViewItem.realEstateName = realEstateName;
          if (pictureIDs.length !== 0) listViewItem.pictureID = pictureIDs[0];

          listViewItems.push(listViewItem);
          recordCollection.set(id, apartment);
        }

        setListViewItems(listViewItems);
        setIsLoading(false);
        setRecordCollection(recordCollection);
        preselectRecord(recordCollection);
      } else {
        toastErrorWithCorrelationID(translate(response.errorTRKey), response.correlationID);
      }
    };

    if (mode === Mode.DEFAULT) preloadData();
  });

  const handleAddHasClicked = () => {
    setMode(Mode.ADD);
  };

  const handleAbortClicked = () => {
    setMode(Mode.DEFAULT);
    setIsLoading(true);
    Navigate(`/apartment`);
  };

  const handleSearchTyped = (searchText: string) => {
    setSearch(searchText);
    setIsLoading(true);
  };

  const handleListViewChildClicked = (editChildID: UUID) => {
    const record = recordCollection?.get(editChildID) as Apartment;
    const usedMode = isRealEstateOwner ? Mode.VIEW : Mode.EDIT;

    setSelectedRecord(record);
    setMode(usedMode);
    Navigate(`/apartment/${editChildID}`);
  };

  const preselectRecord = (usedRecordCollection: Map<UUID, Apartment>) => {
    if (urlLocation.pathname.includes("/apartment")) {
      const pathSplit = urlLocation.pathname.split("/");

      const record = usedRecordCollection?.get(pathSplit[2]) as Apartment;
      if (!record) return;
      const usedMode = isRealEstateOwner ? Mode.VIEW : Mode.EDIT;

      setSelectedRecord(record);
      setMode(usedMode);
    }
  };

  const handleNewRealEstateHasBeenAdded = (newApartment: Apartment) => {
    setMode(Mode.EDIT);
    setSelectedRecord(newApartment);
    setIsLoading(true);
  };

  let subTitle = translate("apartment_header_subtitle_selection");

  let usedComponent = <></>;
  switch (mode) {
    case Mode.DEFAULT:
      usedComponent = (
        <ListView
          readonly={isRealEstateOwner}
          items={listViewItems as ListViewItem[]}
          onAddClicked={handleAddHasClicked}
          onSearchTyped={handleSearchTyped}
          triggerIsLoading={false}
          onChildClicked={handleListViewChildClicked}
        />
      );
      break;
    case Mode.ADD:
      subTitle = translate("apartment_header_subtitle_add");
      usedComponent = <EditView mode={Mode.ADD} hasNewRealEstateBeenAdded={handleNewRealEstateHasBeenAdded} close={handleAbortClicked} />;
      break;
    case Mode.EDIT:
      subTitle = `${translate("apartment_header_subtitle_edit")} ${selectedRecord?.name}`;
      usedComponent = (
        <EditView mode={Mode.EDIT} recordID={selectedRecord?.id} hasNewRealEstateBeenAdded={handleNewRealEstateHasBeenAdded} close={handleAbortClicked} />
      );
      break;
    case Mode.VIEW:
      subTitle = `${translate("apartment_header_subtitle_view")} ${selectedRecord?.name}`;
      usedComponent = <ReadOnlyView withBackBtn recordID={selectedRecord?.id} onAbortClicked={handleAbortClicked} />;
      break;
  }

  return (
    <>
      <ToastContainerFactory />

      <Container fluid className={sharedStyles.component}>
        <div className={sharedStyles.headLine}>
          <div className={sharedStyles.pullRightHeadlineContent}>
            <Row>
              <Col>
                <h2>{translate("component_title_Apartment")} </h2>
              </Col>
            </Row>
            <Row>
              <Col className={sharedStyles.pullUpHeadlineContent}>{subTitle}</Col>
            </Row>
          </div>
        </div>

        {usedComponent}
      </Container>
    </>
  );
}

export default ApartmentView;
