import styles from "./component.module.css";

import { useLocation, useNavigate } from "react-router-dom";
import Utils from "../../infrastructure/utils";
import { useEffect, useState } from "react";
import NavbarHeader from "./TopMenu/TopMenu";
import SideMenu from "./SideMenu";
import BookingView from "../Booking";
import RealEstateView from "../RealEstate/RealEstate";
import ApartmentView from "../Apartment";
import { Col, Container, Row } from "react-bootstrap";
import AdminUserView from "../AdminUser/AdminUser";
import SettingsView from "../Settings";
import NewsView from "../News";
import IdentityProvider from "../Utils/IdentityProvider";
import { RequestPermission } from "../../infrastructure/enums";

function Main() {
  const urlLocation = useLocation();

  const Navigate = useNavigate();
  const [usedComp, setUsedComp] = useState(Utils.getSelectedSideBarItemToLocalStorage());
  const {
    BOOKING_VIEW,
    BOOKING_EDIT,
    APARTMENT_VIEW,
    REAL_ESTATE_VIEW,
    REAL_ESTATE_EDIT,
    REAL_ESTATE_DELETE,
    NEWS_VIEW,
    NEWS_EDIT,
    NEWS_DELETE,
    SETTINGS_VIEW,
    SETTINGS_EDIT,
    SETTINGS_DELETE,
    ADMIN_USER_VIEW,
    ADMIN_USER_EDIT,
    ADMIN_USER_DELETE,
  } = RequestPermission;

  useEffect(() => {
    const isLoggedIn = Utils.isLoggedIn();
    if (!isLoggedIn) {
      Navigate("/login");
      return;
    }

    const pathSegments = urlLocation.pathname.split("/");
    const dst = pathSegments[1];
    if (pathSegments.length > 1 && usedComp !== dst) {
      setUsedComp(dst);
      Utils.setSelectedSideBarItemToLocalStorage(dst);
    }
  });

  const handleSideMenuOnChange = (selectedComp: string) => {
    setUsedComp(selectedComp);
    Utils.setSelectedSideBarItemToLocalStorage(selectedComp);
    Navigate(`/${selectedComp}`);
  };

  let usedComponent;
  switch (usedComp) {
    case "booking":
      usedComponent = (
        <IdentityProvider permissions={[BOOKING_VIEW, BOOKING_EDIT]}>
          <BookingView />
        </IdentityProvider>
      );
      break;
    case "realestate":
      usedComponent = (
        <IdentityProvider permissions={[REAL_ESTATE_VIEW, REAL_ESTATE_EDIT, REAL_ESTATE_DELETE]}>
          <RealEstateView />
        </IdentityProvider>
      );
      break;
    case "apartment":
      usedComponent = (
        <IdentityProvider permissions={[APARTMENT_VIEW]}>
          <ApartmentView />
        </IdentityProvider>
      );
      break;
    case "news":
      usedComponent = (
        <IdentityProvider permissions={[NEWS_VIEW, NEWS_EDIT, NEWS_DELETE]}>
          <NewsView />
        </IdentityProvider>
      );
      break;
    case "adminUser":
      usedComponent = (
        <IdentityProvider permissions={[ADMIN_USER_VIEW, ADMIN_USER_EDIT, ADMIN_USER_DELETE]}>
          <AdminUserView />
        </IdentityProvider>
      );
      break;
    case "settings":
      usedComponent = (
        <IdentityProvider permissions={[SETTINGS_VIEW, SETTINGS_EDIT, SETTINGS_DELETE]}>
          <SettingsView />
        </IdentityProvider>
      );
      break;
    default:
      usedComponent = (
        <IdentityProvider permissions={[BOOKING_VIEW, BOOKING_EDIT]}>
          <BookingView />
        </IdentityProvider>
      );
      break;
  }

  return (
    <Container fluid className="g-0">
      <Row>
        <Col>
          <NavbarHeader />
        </Col>
      </Row>
      <Row className={styles.fillRemainingSpace}>
        <Col sm={1}>
          <SideMenu hasChanged={handleSideMenuOnChange} />
        </Col>
        <Col className={styles.centerComponent}>{usedComponent}</Col>
      </Row>
    </Container>
  );
}

export default Main;
