import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import styles from "./component.module.css";
import sharedStyles from "../component.shared.module.css";

import ToastContainerFactory from "../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../Utils/TranslationProvider";
import Attributes from "./Attributes/Attributes";
import Saisons from "./Saisons/Saisons";
import Utils from "../../infrastructure/utils";

function SettingsView() {
  if (!Utils.isLoggedIn()) return <></>;

  const { translate } = TranslationHandler();

  return (
    <>
      <ToastContainerFactory />

      <Container fluid className={sharedStyles.component}>
        <div className={sharedStyles.headLine}>
          <div className={sharedStyles.pullRightHeadlineContent}>
            <Row>
              <Col>
                <h2>{translate("component_title_Settings")}</h2>
              </Col>
            </Row>
            <Row>
              <Col className={sharedStyles.pullUpHeadlineContent}>{translate("settings_header_subtitle_selection")}</Col>
            </Row>
          </div>
        </div>
        <Container fluid>
          <Tabs defaultActiveKey="attributes" className={styles.tabs}>
            <Tab className={styles.tab} eventKey="attributes" title="Attribute">
              <Attributes />
            </Tab>
            <Tab className={styles.tab} eventKey="saisons" title="Saisons">
              <Saisons />
            </Tab>
          </Tabs>
        </Container>
      </Container>
    </>
  );
}

export default SettingsView;
