import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { ReadOnlyViewProps } from "./type";
import { useEffect, useState } from "react";
import ApiClient from "../../../interface/client/apiClient";
import { ReadOnlyApartment } from "../../../infrastructure/types";
import { toastErrorWithCorrelationID } from "../../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import PriceView from "./PriceView/PriceView";
import HouseIcon from "../../Icons/HouseIcon";
import AdultIcon from "../../Icons/AdultIcon";
import BedICon from "../../Icons/BedIcon";
import ImageCarousel from "./ImageCarousel/ImageCarousel";
import VerticalAttriuteViewView from "./VerticalAttriuteView/VerticalAttriuteView";
import { Locale, SaisonType } from "../../../infrastructure/enums";
import Utils from "../../../infrastructure/utils";
import BackIcon from "../../Icons/BackIcon";

type CompValues = {
  readOnlyApartment: ReadOnlyApartment | null;
  isLoading: boolean;
};

const typeToIdentifier = new Map([
  [0, "saison_type_peak"],
  [1, "saison_type_high"],
  [2, "saison_type_middle"],
  [3, "saison_type_low"],
]);

function ReadOnlyView(props: ReadOnlyViewProps) {
  if (!Utils.isLoggedIn()) return <></>;

  const { translate, translateObj } = TranslationHandler();

  const [compValues, setCompValues] = useState<CompValues>({ isLoading: true, readOnlyApartment: null });
  const [activeLocale, setActiveLocale] = useState<Locale>(Utils.getLocale());
  const [activeSeason, setActiveSeason] = useState<SaisonType>(SaisonType.LOW);

  useEffect(() => {
    const loadData = async () => {
      const { correlationID, data, error, errorTRKey } = await ApiClient().apartmentGetReadOnly(props.recordID as string);
      if (error === null) {
        setCompValues({ ...compValues, isLoading: false, readOnlyApartment: data });
      } else {
        toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
      }
    };

    if (compValues.isLoading && props.recordID) loadData();
  }, [compValues.isLoading]);

  if (!props.recordID) return <></>;
  if (!compValues.readOnlyApartment) return <></>;

  const buildLanguageSelection = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    for (const locale of Object.keys(Locale)) {
      const usedBtnVariant = activeLocale === locale ? "outline-dark" : "rng";
      const item = (
        <Button key={locale} style={{ marginRight: "0.3em" }} size="sm" variant={usedBtnVariant} onClick={() => setActiveLocale(locale as Locale)}>
          {translate(locale)}
        </Button>
      );

      items.push(item);
    }

    return items;
  };

  const buildLanguageSesonSelection = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    for (const seasonType of typeToIdentifier.keys()) {
      const usedBtnVariant = activeSeason === seasonType ? "outline-dark" : "rng";
      const trIdentifier = typeToIdentifier.get(seasonType) as string;

      const item = (
        <Button key={seasonType} style={{ marginRight: "0.3em" }} size="sm" variant={usedBtnVariant} onClick={() => setActiveSeason(seasonType)}>
          {translate(trIdentifier)}
        </Button>
      );

      items.push(item);
    }

    return items;
  };

  return (
    <>
      <Container fluid className={styles.container}>
        <Row style={{ padding: "0.5em", marginBlock: "2em" }}>
          {props.withBackBtn && (
            <Col xs={2} style={{ borderRight: "1px solid lightgrey" }}>
              <Button size="sm" type="submit" variant="outline-dark" onClick={() => props.onAbortClicked()}>
                <BackIcon className={styles.backBtn} />
                {translate("topmenu_btn_back")}
              </Button>
            </Col>
          )}

          <Col xs={3} style={{ paddingLeft: "3em" }}>
            {buildLanguageSelection()}
          </Col>
          <Col style={{ paddingLeft: "4em" }}>{buildLanguageSesonSelection()}</Col>
        </Row>
      </Container>
      <Container fluid className={styles.container}>
        <Row>
          <Col xs={8}>
            <Container fluid style={{ paddingTop: "2em" }}>
              <Row>
                <Col style={{ margin: 0 }} xs={8}>
                  <h3>{compValues.readOnlyApartment.name}</h3>
                </Col>
              </Row>
            </Container>

            {/* top attr. / favorits */}
            <ul className={styles.horizontalList}>
              <li>
                <AdultIcon className={styles.icon} /> {compValues.readOnlyApartment.attributeCollection.allowedNumberOfPeople.toString()}{" "}
                {translate("apartment_label_allowed_number_of_people", activeLocale)}
              </li>
              <li>
                <BedICon className={styles.icon} /> {compValues.readOnlyApartment.attributeCollection.sleepingPlaces.toString()}{" "}
                {translate("apartment_label_sleeping_places", activeLocale)}
              </li>
              <li>
                <HouseIcon className={styles.icon} /> {compValues.readOnlyApartment.attributeCollection.roomSize.toString()} &#13217;
              </li>
            </ul>

            {/*description*/}
            <p style={{ marginLeft: "1.5em" }}>{translateObj(compValues.readOnlyApartment.description, activeLocale)}</p>

            {/* start ATTRIBUTES */}
            <div style={{ paddingTop: "1.5em", paddingLeft: "1.5em" }}>
              <h4>{translate("apartment_label_equipment", activeLocale)}</h4>
              <VerticalAttriuteViewView activeLocale={activeLocale} attributes={compValues.readOnlyApartment.attributeCollection.topAttributes} />
            </div>
          </Col>
          <Col>
            <Container fluid style={{ paddingTop: "2.4em" }}>
              <Row style={{ maxWidth: "30em", maxHeight: "30em" }}>
                <Col style={{ padding: 0 }}>
                  <ImageCarousel pictureIDs={compValues.readOnlyApartment.pictureIDs} />
                </Col>
              </Row>
              <Row style={{ paddingTop: "1em" }}>
                <Col>
                  <PriceView activeLocale={activeLocale} activeSeasonType={activeSeason} seasonPrice={compValues.readOnlyApartment.saisonPrice} />
                </Col>
              </Row>
            </Container>
            {/*Price*/}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReadOnlyView;
