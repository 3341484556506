import { Button, Dropdown, DropdownButton, Image } from "react-bootstrap";
import styles from "./component.module.css";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Utils from "../../../infrastructure/utils";
import { useNavigate } from "react-router-dom";
import { Locale } from "../../../infrastructure/enums";

import { TranslationHandler } from "../../Utils/TranslationProvider";
import LanguagePicker from "../../Utils/LanguagePicker/LanguagePicker";
import IconLogout from "../../Icons/LogoutIcon";
import NotificationOverlay from "../NotificationOverlay/NotificationOverlay";

function TopMenu() {
  const Navigate = useNavigate();

  const { translate, setLocale } = TranslationHandler();
  const identity = Utils.getIdentityFromLocalStorage();

  const handleOnLocaleChanged = (changedLocale: Locale) => setLocale(changedLocale);

  const handleLogout = (event: any) => {
    event.preventDefault();

    Utils.setAsLogout();

    Navigate("/");
  };

  return (
    <>
      <NotificationOverlay />

      <Navbar fixed="top" className={styles.topMenu}>
        <Container fluid>
          <Navbar.Brand>
            <Image className={styles.headerImage} src="/android-chrome-192x192.png" />
          </Navbar.Brand>
          <Navbar.Text className={`${styles.pullRight05} ms-auto`}>
            {translate("topmenu_label_adminUser_signedIn")}: <i>{identity?.getUserName()}</i>
          </Navbar.Text>
          <LanguagePicker onLocaleChanged={(changedLocale) => handleOnLocaleChanged(changedLocale)} />
          <Navbar.Text className={styles.logout}>
            <Button variant="outline-dark" size="sm" onClick={handleLogout}>
              {translate("topmenu_btn_logout")}
              <IconLogout className={styles.logoutIcon} />
            </Button>
          </Navbar.Text>
        </Container>
      </Navbar>
    </>
  );
}

export default TopMenu;
