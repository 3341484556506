import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import ApiClient from "../../../interface/client/apiClient";

import ToastContainerFactory, { toastErrorWithCorrelationID, toastSuccess } from "../../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../../Utils/TranslationProvider";
import ListView from "./ListView/ListView";
import { Attribute, UUID, UUIDOrNull } from "../../../infrastructure/types";
import EditModal from "./EditModal/EditModal";
import { Mode } from "../../../interface/client/enums";
import DeleteModal from "./DeleteModal/DeleteModal";

type FormValues = {
  mode: Mode;
  search: string | null;
  attributes: Attribute[];

  selectedAttribute: Attribute | null;
  attributeCollection: Map<UUID, Attribute>;
  attributeToDeleteID: UUIDOrNull;

  shouldLoading: boolean;
  showModifyModal: boolean;
  showDeleteModal: boolean;
};

function Attributes() {
  const { translate } = TranslationHandler();

  const [formValues, setFormValues] = useState<FormValues>({
    mode: Mode.ADD,
    attributes: [],
    shouldLoading: true,
    search: null,
    showModifyModal: false,
    showDeleteModal: false,
    selectedAttribute: null,
    attributeCollection: new Map(),
    attributeToDeleteID: null,
  });

  useEffect(() => {
    const preloadData = async () => {
      const { error, data, errorTRKey, correlationID } = await ApiClient().attributeGetMany(formValues.search);
      if (error === null) {
        if (!formValues.shouldLoading) return;

        const attributeCollection = new Map();
        for (const attribute of data) attributeCollection.set(attribute.id, attribute);

        setFormValues({ ...formValues, attributes: data, attributeCollection, shouldLoading: false });

        return;
      }

      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
    };

    preloadData();
  });

  const handleOnSearch = (search: string) => {
    setFormValues({ ...formValues, search, shouldLoading: true });
  };

  const handleAddClicked = () => {
    setFormValues({ ...formValues, showModifyModal: true, mode: Mode.ADD });
  };

  const handleChildClicked = (listViewChildID: UUID) => {
    const attribute = formValues.attributeCollection.get(listViewChildID);
    if (!attribute) return;

    setFormValues({ ...formValues, selectedAttribute: attribute, showModifyModal: true, mode: Mode.EDIT });
  };

  const handleDeleteApproved = async () => {
    const { attributeToDeleteID } = formValues;
    if (!attributeToDeleteID) return;

    const foundAttribute = formValues.attributeCollection.get(attributeToDeleteID);
    if (!foundAttribute) return;

    const cachedAttributeCollection = new Map(formValues.attributeCollection);
    cachedAttributeCollection.delete(attributeToDeleteID);

    const { correlationID, error, errorTRKey } = await ApiClient().attributeDelete(attributeToDeleteID);
    if (error === null) {
      setFormValues({
        ...formValues,
        attributeCollection: cachedAttributeCollection,
        attributeToDeleteID: null,
        selectedAttribute: null,
        showDeleteModal: false,
        shouldLoading: true,
      });

      toastSuccess(translate("attribute_toast_deleted"), 2);
    } else {
      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
    }
  };

  const handleChildDeleted = async (listViewChildID: UUID) => {
    setFormValues({ ...formValues, showDeleteModal: true, attributeToDeleteID: listViewChildID });
  };

  const handleOnModalClose = () => {
    setFormValues({ ...formValues, selectedAttribute: null, showModifyModal: false });
  };

  const handleChildAssumed = async (attribute: Attribute) => {
    switch (formValues.mode) {
      case Mode.ADD:
        await createAttribute(attribute);
        break;
      case Mode.EDIT:
        await updateAttribute(attribute);
        break;
    }
  };

  const createAttribute = async (attribute: Attribute) => {
    const { name, svg } = attribute;

    const { data, error, errorTRKey, correlationID } = await ApiClient().attributeCreate(name, svg);
    if (error === null) {
      const cachedAttributeCollection = new Map(formValues.attributeCollection);
      cachedAttributeCollection.set(data.id, data);

      setFormValues({ ...formValues, attributeCollection: cachedAttributeCollection, selectedAttribute: null, showModifyModal: false, shouldLoading: true });
      toastSuccess(translate("attribute_toast_created"), 2);
    } else {
      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
    }
  };

  const updateAttribute = async (attribute: Attribute) => {
    const foundAttribute = formValues.attributeCollection.get(attribute.id);
    if (!foundAttribute) return;

    const cachedAttributeCollection = new Map(formValues.attributeCollection);
    cachedAttributeCollection.set(foundAttribute.id, attribute);

    const updatedIcon = foundAttribute.svg !== attribute.svg ? attribute.svg : undefined;
    const updatedName = foundAttribute.name !== attribute.name ? attribute.name : undefined;

    const { correlationID, error, errorTRKey } = await ApiClient().attributeUpdate(foundAttribute.id, updatedName, updatedIcon);
    if (error === null) {
      setFormValues({ ...formValues, attributeCollection: cachedAttributeCollection, selectedAttribute: null, showModifyModal: false, shouldLoading: true });
      toastSuccess(translate("attribute_toast_update"));
    } else {
      toastErrorWithCorrelationID(translate(errorTRKey), correlationID);
    }
  };

  return (
    <>
      <ToastContainerFactory />
      <EditModal
        mode={formValues.mode}
        attribute={formValues.selectedAttribute}
        close={handleOnModalClose}
        modalTitleTranslationKey={formValues.mode === Mode.ADD ? "attribute_modal_title_add" : "attribute_modal_title_edit"}
        showModal={formValues.showModifyModal}
        onAssume={handleChildAssumed}
        key="editModal"
      />
      <DeleteModal
        handleAborted={() => setFormValues({ ...formValues, showDeleteModal: false })}
        handleDeleteApproved={handleDeleteApproved}
        showModal={formValues.showDeleteModal}
      />

      <Container fluid>
        <ListView
          onSearch={handleOnSearch}
          enableSaveBtn={true}
          items={formValues.attributes}
          onSaveClicked={() => {}}
          onChildDeletedClicked={handleChildDeleted}
          onChildClicked={handleChildClicked}
          onAddClicked={handleAddClicked}
        />
      </Container>
    </>
  );
}

export default Attributes;
