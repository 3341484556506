import { TranslationMap, TranslationStrMap } from "./type";

export const TranslationAdminUserMap: TranslationMap = new Map([
  [-1, "adminUser_menu_selectAll"],
  [1, "adminUser_menu_selectAdmin"],
  [2, "adminUser_menu_selectRealEstateOwner"],
]);

export const TranslationAdminUserStrMap: TranslationStrMap = new Map([
  ["-1", "adminUser_menu_selectAll"],
  ["1", "adminUser_menu_selectAdmin"],
  ["2", "adminUser_menu_selectRealEstateOwner"],
]);
