import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TranslationHandler } from "../../../../Utils/TranslationProvider";

type DeleteModalProps = {
  handleDeleteApproved: () => void;
  handleAborted: () => void;
  showModal: boolean;
};

function DeleteModal(props: DeleteModalProps) {
  const { handleDeleteApproved, handleAborted, showModal } = props;
  const { translate } = TranslationHandler();

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal show={show} onHide={handleAborted} backdrop="static" keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{translate("modal_btn_delete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{translate("realestate_modal_btn_delete_body")}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleAborted}>
          {translate("modal_btn_abort")}
        </Button>
        <Button variant="primary" onClick={handleDeleteApproved}>
          {translate("modal_btn_delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
