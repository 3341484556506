import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import ApiClient from "../../../../interface/client/apiClient";
import { UUID } from "../../../../infrastructure/types";
import EditModal from "./EditModal/EditModal";

import styles from "./component.module.css";
import { TranslationHandler } from "../../../Utils/TranslationProvider";

interface ImageUploaderProps {
  disabled: boolean;
  existingPictureID: UUID;

  pictureUploaded: (newPictureID: UUID) => void;
}

function ImageUploader(props: ImageUploaderProps) {
  const { existingPictureID, pictureUploaded, disabled } = props;
  const { translate } = TranslationHandler();

  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [imageStrToCrop, setImageToCrop] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const uploadPicture = async (croppedFile: File) => {
    const { error, data } = await ApiClient().pictureUpload(croppedFile, {});
    if (error == null) {
      pictureUploaded(data[0].id);
    } else {
      console.error(error);
    }
  };

  const handleImageCroped = (cropedImageResult: { blobUrl: string; croppedFile: File } | null) => {
    if (cropedImageResult === null) return;

    const { blobUrl, croppedFile } = cropedImageResult;

    uploadPicture(croppedFile);
    setUploadedImage(blobUrl);
    setShowModal(false);
  };

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (!existingPictureID) return;

      const pictureResponse = await ApiClient().pictureGet(existingPictureID);
      if (pictureResponse.error === null) {
        const { id, raw } = pictureResponse.data;
        setUploadedImage(raw);
      }
    };

    loadData();
  }, [existingPictureID]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    for (const file of acceptedFiles) {
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setImageToCrop(imageUrl);
        setShowModal(true);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 5000000,

    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });

  const usedPointer = disabled ? "not-allowed" : "pointer";
  const usedDropzoneProps = disabled ? {} : getRootProps();
  const usedTRKey = disabled ? "realestate_picture_handler_disabled" : "realestate_picture_handler_drag_and_drop"

  return (
    <>
      <EditModal show={showModal} close={() => setShowModal(false)} imageStrToCrop={imageStrToCrop} onImageCroped={handleImageCroped} />

      {/*  getRootProps initiate the dropzone */}
      <div {...usedDropzoneProps} style={{ textAlign: "center" }}>
        {uploadedImage ? (
          <img src={uploadedImage} alt="Uploaded" style={{ maxWidth: "100%", cursor: "pointer" }} />
        ) : (
          <div className={styles.noImage}>
            <strong style={{ position: "relative", top: "1em" }}>{translate(usedTRKey)}</strong>
            <img src={"/FeWoServ_logo.png"} alt="Upload" style={{ maxWidth: "100%", cursor: `${usedPointer}` }} />
          </div>
        )}
      </div>
    </>
  );
}

export default ImageUploader;
