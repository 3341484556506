import { Container, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { ListViewProps } from "./type";
import Menu from "./Menu";
import ListViewItem from "./ListViewItem";
import ListViewAddItem from "./ListViewAddItem";
import Utils from "../../../infrastructure/utils";

function ListView(props: ListViewProps) {
  const { items, onAddClicked, onChildClicked, onSearchTyped, triggerIsLoading } = props;

  const handleSearchTyped = (searchText: string) => {
    onSearchTyped(searchText);
  };

  const handleListViewChildClicked = (recordID: string) => {
    Utils.scrollToTop();

    onChildClicked(recordID);
  };

  const listViewItems = items.map((entry) => (
    <ListViewItem pictureID={entry.pictureID} name={entry.name} key={entry.id} id={entry.id} onClicked={handleListViewChildClicked} />
  ));
  listViewItems.unshift(<ListViewAddItem key={"0"} onClicked={onAddClicked} />);

  let usedComponent: any = <div>Loading ...</div>;
  if (!triggerIsLoading) usedComponent = listViewItems;

  return (
    <Container fluid>
      <Row className={`${styles.container} g-4`}>
        <Menu onSearchTyped={handleSearchTyped} />
      </Row>

      <Row xs={1} md={2} className={`${styles.container} g-4`}>
        {usedComponent}
      </Row>
    </Container>
  );
}

export default ListView;
