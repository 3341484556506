// icon:child | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from "react";

function ChildIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 320 512" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M224 64c0 35.3-28.7 64-64 64S96 99.3 96 64s28.7-64 64-64 64 28.7 64 64zm-80 320v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V287.8L59.1 321c-9.4 15-29.2 19.4-44.1 10s-19.5-29.1-10.1-44l39.9-63.3C69.7 184 113.2 160 160 160s90.3 24 115.2 63.6l39.9 63.4c9.4 15 4.9 34.7-10 44.1s-34.7 4.9-44.1-10l-21-33.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32v-96h-32z" />
    </svg>
  );
}

export default ChildIcon;
