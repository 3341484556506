import styles from "./component.module.css";
import sharedStyles from "../component.shared.module.css";

import { useNavigate } from "react-router-dom";
import ApiClient from "../../interface/client/apiClient";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import Utils from "../../infrastructure/utils";
import { useEffect, useState } from "react";
import ToastContainerFactory, { toastError, toastInfo } from "../Utils/ToastContainerFactory/ToastContainerFactory";

type FormValues = {
  email: string;
};

type ErrorValues = Partial<FormValues>;

function PasswordForgottenView() {
  const Navigate = useNavigate();

  const defaultFormValues: FormValues = { email: "" };
  const [formValue, setFormValue] = useState<FormValues>(defaultFormValues);
  const [InputErrors, setInputErrors] = useState<ErrorValues>({});

  const setField = (field: keyof FormValues, value: string) => {
    setFormValue({ ...formValue, [field]: value });

    // remove entry from InputErros, if exists
    if (!!InputErrors[field]) setInputErrors({ ...InputErrors, [field as any]: null });
  };

  const hasError = (field: keyof FormValues) => {
    return InputErrors[field] != null;
  };

  const getError = (field: keyof FormValues) => {
    return InputErrors[field];
  };

  const validateForm = () => {
    const foundErrors: ErrorValues = {};

    let isValid = Utils.isValidEmail(formValue.email);
    if (!isValid) foundErrors.email = "Bitte gib eine valide Email ein";

    setInputErrors(foundErrors);

    const isAllValid = Object.keys(foundErrors).length === 0;
    return isAllValid;
  };

  useEffect(() => {});

  const handleBackClicked = (event: any) => {
    event.preventDefault();
    Navigate("/login");
  };

  const triggerForgotPwd = async (event: any) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) return;

    // we always fire the success notification to ensure that a hacker do not get to much informations
    await ApiClient().forgotPwd(formValue.email);

    toastInfo("Sie erhalten in kürze eine Email, um Ihr Passwort neu zu setzten", 10);
  };

  return (
    <>
      <ToastContainerFactory />

      <div className={sharedStyles.bg}>
        <Card className={sharedStyles.glas_card}>
          <Image src="/FeWoServ_logo.png" />
          <Card.Title className={styles.title}>
            <h4>Passwort vergessen ?</h4>
            <br />
            <span>Kein Ding, wir helfen dir dabei.</span>
          </Card.Title>
          <Card.Body className={styles.clear}>
            <Container fluid className={styles.clear}>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Gib deine Email ein"
                    autoComplete="email"
                    onChange={(e) => setField("email", e.target.value)}
                    value={formValue.email}
                    isInvalid={hasError("email")}
                    className={sharedStyles.glas_form}
                  />
                  <Form.Control.Feedback type="invalid">{getError("email")}</Form.Control.Feedback>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <Button type="submit" size="sm" variant="outline-dark" onClick={handleBackClicked}>
                    Zum Login
                  </Button>
                </Col>
                <Col>
                  <Button type="submit" size="sm" variant="outline-dark" className="float-end button" onClick={triggerForgotPwd}>
                    Los gehts !
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default PasswordForgottenView;
