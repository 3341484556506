import { Dropdown, DropdownButton } from "react-bootstrap";
import { Locale } from "../../../infrastructure/enums";
import { TranslationHandler } from "../TranslationProvider";

type LanguagePickerProps = {
  onLocaleChanged: (changedLocale: Locale) => void;
};

function LanguagePicker(props: LanguagePickerProps) {
  const { onLocaleChanged } = props;
  const { translate } = TranslationHandler();
  const handleOnSelect = (eventKey: string | null) => {
    onLocaleChanged(eventKey as Locale);
  };

  const dropdownItems: JSX.Element[] = [];
  for (const locale of Object.keys(Locale)) {
    const dropdownItem = (
      <Dropdown.Item key={locale} eventKey={locale} as="button">
        {translate(locale)}
      </Dropdown.Item>
    );

    dropdownItems.push(dropdownItem);
  }

  return (
    <DropdownButton variant="outline-dark" size="sm" id="dropdown-item-button" title={translate("topmenu_btn_language")} onSelect={handleOnSelect}>
      {dropdownItems}
    </DropdownButton>
  );
}

export default LanguagePicker;
