import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { FixCost, SaisonPrice } from "../../../../infrastructure/types";

import styles from "./component.module.css";
import { Col, Row } from "react-bootstrap";

type FormValues = {
  basePrice: string;
  peakPrice: string;
  highPrice: string;
  middlePrice: string;
  lowPrice: string;
  fixCosts: string;
  total: string;
};

type PriceModalProps = {
  saisonPrice: SaisonPrice;
};

function transform(value: number): string {
  return value ? value.toFixed(2) : "0.00";
}

function calculateTotalFixCosts(fixCost: FixCost): string {
  let totalFixCosts = 0;

  totalFixCosts += fixCost.cleaningFee.price;

  return totalFixCosts.toFixed(2);
}

function transformSaisonPrice(saisonPrice: SaisonPrice): FormValues {
  const { fixCost, highPrice, basePrice, lowPrice, middlePrice, peakPrice, total } = saisonPrice;

  const formValues: FormValues = {
    basePrice: transform(basePrice),
    fixCosts: calculateTotalFixCosts(fixCost),
    peakPrice: transform(peakPrice),
    highPrice: transform(highPrice),
    middlePrice: transform(middlePrice),
    lowPrice: transform(lowPrice),
    total: transform(total),
  };

  return formValues;
}

function PriceViewer(props: PriceModalProps) {
  const { translate } = TranslationHandler();

  const { saisonPrice } = props;
  const { highPrice, lowPrice, middlePrice, peakPrice, basePrice, fixCosts } = transformSaisonPrice(saisonPrice);

  return (
    <Row>
      <Col className={styles.pullUp}>
        <Row style={{ paddingBottom: "0.5em" }}>
          <Col xs={5}>{translate("saison_type_base")}</Col>
          <Col className="text-end">{basePrice} CHF</Col>
          <Col xs={4}></Col>
        </Row>

        <Row>
          <Col xs={5}>{translate("saison_type_low")}</Col>
          <Col className="text-end">{lowPrice} CHF</Col>
          <Col xs={4}></Col>
        </Row>

        <Row>
          <Col xs={5}>{translate("saison_type_middle")}</Col>
          <Col className="text-end">{middlePrice} CHF</Col>
          <Col xs={4}></Col>
        </Row>

        <Row>
          <Col xs={5}>{translate("saison_type_high")}</Col>
          <Col className="text-end">{highPrice} CHF</Col>
          <Col xs={4}></Col>
        </Row>

        <Row>
          <Col xs={5}>{translate("saison_type_peak")}</Col>
          <Col className="text-end">{peakPrice} CHF</Col>
          <Col xs={4}></Col>
        </Row>

        <Row style={{ paddingTop: "0.5em" }}>
          <Col xs={5}>{translate("label_fixCosts")}</Col>
          <Col className="text-end">{fixCosts} CHF</Col>
          <Col xs={4}></Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PriceViewer;
