import { Col, Container, Row } from "react-bootstrap";
import sharedStyles from "../component.shared.module.css";

import ListView from "./ListView/ListView";
import { useEffect, useState } from "react";
import ApiClient from "../../interface/client/apiClient";

import EditView from "./EditView/EditView";
import { ListViewItem } from "./ListView/type";
import { Mode } from "../../interface/client/enums";
import ToastContainerFactory, { toastErrorWithCorrelationID } from "../Utils/ToastContainerFactory/ToastContainerFactory";
import { TranslationHandler } from "../Utils/TranslationProvider";
import { RealEstate, UUID } from "../../infrastructure/types";
import Utils from "../../infrastructure/utils";

function RealEstateView() {
  if (!Utils.isLoggedIn()) return <></>;

  const { translate } = TranslationHandler();

  const [mode, setMode] = useState<Mode>(Mode.DEFAULT);
  const [selectedRecord, setSelectedRecord] = useState<RealEstate | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string | undefined>();
  const [recordCollection, setRecordCollection] = useState<Map<UUID, RealEstate>>(new Map());
  const [listViewItems, setListViewItems] = useState<ListViewItem[]>([]);

  useEffect(() => {
    const preloadData = async () => {
      const response = await ApiClient().realEstateGetMany(search);
      if (response.error === null) {
        const recordCollection = new Map();
        const listViewItems: ListViewItem[] = [];
        for (const realEstate of response.data) {
          const { id, name, pictureID } = realEstate;
          const listViewItem: ListViewItem = { id, name, pictureID };

          listViewItems.push(listViewItem);
          recordCollection.set(id, realEstate);
        }

        setListViewItems(listViewItems);
        setRecordCollection(recordCollection);
        setIsLoading(false);
      } else {
        toastErrorWithCorrelationID(translate(response.errorTRKey), response.correlationID);
      }
    };

    if (mode === Mode.DEFAULT && isLoading) preloadData();
  });

  const handleAddHasClicked = () => {
    setMode(Mode.ADD);
  };

  const handleAbortClicked = () => {
    setMode(Mode.DEFAULT);
    setIsLoading(true);
  };

  const handleSearchTyped = (searchText: string) => {
    setSearch(searchText);
    setIsLoading(true);
  };

  const handleListViewChildClicked = (editChildID: string) => {
    const selectedRecord = recordCollection.get(editChildID) as RealEstate;

    setSelectedRecord(selectedRecord);
    setMode(Mode.EDIT);
  };

  const handleNewRealEstateHasBeenAdded = (newRealEstate: RealEstate) => {
    setMode(Mode.EDIT);
    setSelectedRecord(newRealEstate);
  };

  let subTitle = translate("realestate_header_subtitle_selection");
  let usedComponent = <></>;

  switch (mode) {
    case Mode.DEFAULT:
      usedComponent = (
        <ListView
          items={listViewItems}
          onAddClicked={handleAddHasClicked}
          onSearchTyped={handleSearchTyped}
          triggerIsLoading={false}
          onChildClicked={handleListViewChildClicked}
        />
      );
      break;
    case Mode.ADD:
      subTitle = translate("realestate_header_subtitle_add");
      usedComponent = <EditView mode={Mode.ADD} hasNewRealEstateBeenAdded={handleNewRealEstateHasBeenAdded} close={handleAbortClicked} />;
      break;
    case Mode.EDIT:
      subTitle = `${translate("realestate_header_subtitle_edit")} ${selectedRecord?.name}`;
      usedComponent = (
        <EditView mode={Mode.EDIT} recordID={selectedRecord?.id} hasNewRealEstateBeenAdded={handleNewRealEstateHasBeenAdded} close={handleAbortClicked} />
      );
      break;
  }

  return (
    <>
      <ToastContainerFactory />

      <Container fluid className={sharedStyles.component}>
        <div className={sharedStyles.headLine}>
          <div className={sharedStyles.pullRightHeadlineContent}>
            <Row>
              <Col>
                <h2>{translate("component_title_RealEstate")} </h2>
              </Col>
            </Row>
            <Row>
              <Col className={sharedStyles.pullUpHeadlineContent}>{subTitle}</Col>
            </Row>
          </div>
        </div>

        {usedComponent}
      </Container>
    </>
  );
}
export default RealEstateView;
