import { Col, Form, InputGroup, Row } from "react-bootstrap";
import styles from "./component.module.css";
import { useState } from "react";
import { TranslationHandler } from "../../../../Utils/TranslationProvider";
import Utils from "../../../../../infrastructure/utils";

type FixCostProps = {
  defaultValues: { cleaningFeePrice: number };
  hasChanged: (changedValue: "cleaningFee", priceStr: string) => void;
};

function FixCost(props: FixCostProps) {
  const { defaultValues } = props;

  const { translate } = TranslationHandler();
  const [cleaningFeePrice, setCleaningFeePrice] = useState<string>(defaultValues.cleaningFeePrice.toString());

  const updateCleaningFeePrice = (priceStr: string) => {
    const isValid = Utils.onlyNumericsAllowed(priceStr);
    if (!isValid) return;

    setCleaningFeePrice(priceStr);
    props.hasChanged("cleaningFee", priceStr);
  };

  return (
    <Row>
      <b>{translate("label_fixCosts")}</b>

      <Col xs={4}>
        <Form.Group className="mb-3">
          <InputGroup className="mb-3">
            <Form.Control type="text" className="text-end" value={cleaningFeePrice} onChange={(e) => updateCleaningFeePrice(e.target.value)} />
            <InputGroup.Text>CHF</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      {/* TODO: FixKosten impl. */}
      <Col className={styles.fixCost}>{translate("fix_costs_label_cleaning_fee")}</Col>
    </Row>
  );
}

export default FixCost;
