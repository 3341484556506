import React from "react";
import Login from "../Login/Login";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "../Main";
import { Container } from "react-bootstrap";

import styles from "./component.module.css";
import PasswordResetView from "../PasswordReset/PasswordResetView";
import PasswordForgottenView from "../PasswordForgotten/PasswordForgottenView";

class App extends React.Component {
  public render() {
    return (
      <Container fluid className={`${styles.app} g-0`}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Main />} />
            <Route path="/pwdReset/:tokenIdentifier/:locale" element={<PasswordResetView variante="reset" />} />
            <Route path="/invite/:tokenIdentifier/:locale" element={<PasswordResetView variante="invite" />} />
            <Route path="/pwdForgotten" element={<PasswordForgottenView />} />
            <Route path="*" element={<Main />} />
          </Routes>
        </Router>
      </Container>
    );
  }
}

export default App;
