import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { Translation } from "../../../../../../infrastructure/types";
import { TranslationHandler } from "../../../../../Utils/TranslationProvider";
import Description from "../../../Description/Description";

type TranslationModalProps = {
  handleTranslationUpdated: (updatedTranslation: Translation) => void;
  handleAborted: () => void;

  translation: Translation;
  showModal: boolean;
};

function TranslationModal(props: TranslationModalProps) {
  const { handleTranslationUpdated, handleAborted, showModal, translation } = props;
  const { translate } = TranslationHandler();

  const [show, setShow] = useState<boolean>(false);
  const [usedTranslation, setUsedTranslation] = useState<Translation>({});

  useEffect(() => {
    setUsedTranslation(translation);
    setShow(showModal);
  }, [showModal, translation]);

  return (
    <Modal show={show} onHide={handleAborted} keyboard={true}>
      <Modal.Header closeButton>
        <Container>
          <Row>
            <Col>
              <Modal.Title>{translate("apartment_picture_translation_modal_title")}</Modal.Title>
            </Col>
          </Row>
          <Row>
            <Col>{translate("apartment_picture_translation_modal_description")}</Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Description description={translation} onChanged={(updatedTranslation: Translation) => setUsedTranslation(updatedTranslation)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleAborted}>
          {translate("modal_btn_abort")}
        </Button>
        <Button variant="primary" onClick={() => handleTranslationUpdated(usedTranslation)}>
          {translate("modal_btn_ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TranslationModal;
