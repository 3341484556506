// icon:checkmark-done-outline | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from "react";
import styles from "./component.module.css";

function DoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={`${styles.icon} `} viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284"
      />
    </svg>
  );
}

export default DoneIcon;
