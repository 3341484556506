import { Locale } from "./enums";
import { UUID } from "./types";

type IdentityProps = {
  permissions?: string[];
  userName?: string;
  resetPWD?: boolean;
  locale?: Locale;
  id?: UUID;
  isREOwner?: boolean;
};

class Identity {
  private isInitialized: boolean;

  private id: UUID;
  private locale: Locale;
  private permissions: string[];
  private userName: string;
  private isREOwner: boolean;

  public static buildFrom(buildInfo: string): Identity {
    const rowData = JSON.parse(buildInfo);

    const props: IdentityProps = {
      permissions: rowData["permissions"],
      userName: rowData["userName"],
      resetPWD: rowData["resetPWD"],
      locale: rowData["locale"],
      id: rowData["id"],
      isREOwner: rowData["isREOwner"],
    };

    return new Identity(props);
  }

  public constructor(props: IdentityProps) {
    const { id, isREOwner, locale, permissions, resetPWD, userName } = props;

    this.id = id ?? "";
    this.permissions = permissions ?? [];
    this.userName = userName ? userName : "N/A";
    this.locale = locale ?? Locale.enGB;
    this.isREOwner = isREOwner ?? false;

    this.isInitialized = true;
  }

  public hasPermission(permissionsToCheck: string[]): boolean {
    if (!this.isInitialized) return false;

    let hasPermission = true;

    for (const permissionToCheck of permissionsToCheck) {
      const isIncluded = this.permissions.includes(permissionToCheck);
      if (!isIncluded) {
        hasPermission = false;
        break;
      }
    }

    return hasPermission;
  }

  public getUserName() {
    return this.userName;
  }

  public getLocale() {
    return this.locale;
  }

  public getID() {
    return this.id;
  }

  public isRealEstateOwner(): boolean {
    return this.isREOwner;
  }
}

export default Identity;
