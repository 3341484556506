import { Form } from "react-bootstrap";
import { Locale } from "../../../infrastructure/enums";
import { TranslationHandler } from "../TranslationProvider";
import { ChangeEvent } from "react";
import styles from "./component.module.css";

type LanguagePickerProps = {
  preSelectLocale: Locale;

  onLocaleChanged: (changedLocale: Locale) => void;
};

function LocalePicker(props: LanguagePickerProps) {
  const { onLocaleChanged, preSelectLocale } = props;
  const { translate } = TranslationHandler();

  const handleOnChanged = (event: ChangeEvent<HTMLSelectElement>) => onLocaleChanged(event.target.value as Locale);

  const buildItem = (locale: string): JSX.Element => {
    return (
      <option key={locale} value={locale}>
        {translate(locale)}
      </option>
    );
  };

  const buildSelectItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    for (const locale of Object.keys(Locale)) {
      const item = buildItem(locale);
      items.push(item);
    }

    return items;
  };

  return (
    <Form.Select value={preSelectLocale} className={styles.removeBorder} onChange={handleOnChanged} key={"select"} aria-label="userAdminType select">
      {buildSelectItems()}
    </Form.Select>
  );
}

export default LocalePicker;
