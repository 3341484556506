import { TranslationHandler } from "../../../Utils/TranslationProvider";
import { NameToValue, UUID } from "../../../../infrastructure/types";
import { Col, Form } from "react-bootstrap";

import styles from "./component.module.css";

type DataPickerProps = {
  topItemID: UUID;
  itemContentMap: NameToValue<string>;
  translationKey?: string;

  errorText?: string;
  onChanged: (selectedItemID: string) => void;
};

function buildSelectItem(uuid: UUID, name: string): JSX.Element {
  return (
    <option key={uuid} value={uuid}>
      {name}
    </option>
  );
}

function buildSelectItems(contentMap: NameToValue<string>, emptyHeader: boolean): JSX.Element[] {
  if (contentMap.size === 0) return [];

  const selectItems = [];
  if (emptyHeader) selectItems.push(buildSelectItem("", "-"));

  for (const [itemID, name] of contentMap) {
    const selectItem = buildSelectItem(itemID, name);
    selectItems.push(selectItem);
  }

  return selectItems;
}

function DataPicker(props: DataPickerProps) {
  const { itemContentMap, topItemID, translationKey, onChanged, errorText } = props;
  const { translate } = TranslationHandler();

  const withEmptyHeader = topItemID === "";
  const errorClass = errorText ? styles.errorSelect : "";

  return (
    <Col>
      <Form.Group className="mb-3">
        {translationKey && <b>{translate(translationKey)}</b>}

        <Form.Select className={errorClass} value={topItemID} id={translationKey} onChange={(e) => onChanged(e.target.value)}>
          {buildSelectItems(itemContentMap, withEmptyHeader)}
        </Form.Select>
      </Form.Group>
      {errorText && <div className={styles.errorText}>{errorText}</div>}
    </Col>
  );
}

export default DataPicker;
