import { Col, Container, Row } from "react-bootstrap";

import styles from "./component.module.css";
import { BookingSummary, UUID } from "../../../../infrastructure/types";
import { BookingStatus } from "../../../../infrastructure/enums";

export type ViewPortDates = {
  fromDate: Date;
  toDate: Date;
};

type BookingItemProps = {
  date: Date;
  apartmentID: UUID;

  isInPast: boolean;
  isNotFinishedYet: boolean;
  daysLeft: number;

  bookingSummary: BookingSummary;

  onClicked: (date: Date, apartmentID: UUID) => void;
};

function BookingItemDisabled(props: BookingItemProps) {
  const handleOnClick = () => {
    // blocked or canceled
    const isClickAllowed = props.bookingSummary.status === BookingStatus.BLOCKED || props.bookingSummary.status === BookingStatus.CANCELED;
    if (!isClickAllowed) return;

    props.onClicked(props.bookingSummary.fromDate, props.apartmentID);
  };

  const buildInfoText = () => {
    // blocked or canceled
    const isClickAllowed = props.bookingSummary.status === BookingStatus.BLOCKED || props.bookingSummary.status === BookingStatus.CANCELED;
    if (!isClickAllowed) return "‎ ";
  };

  const highlightBackgroundCollection = [styles.itemHeader];

  if (props.isInPast && props.isNotFinishedYet) highlightBackgroundCollection.push(styles.past);

  const higlightBackground = highlightBackgroundCollection.join(" ");

  return (
    <Container className={higlightBackground} onClick={() => handleOnClick()}>
      <Row style={{ width: `${props.daysLeft * 4}em` }} className={`${styles.bookingHover} ${styles.disabled}`}>
        <Col>{buildInfoText()}</Col>
      </Row>
    </Container>
  );
}

export default BookingItemDisabled;
