// icon:refresh | System UIcons https://systemuicons.com/ | Corey Ginnivan
import * as React from "react";

function RefreshIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 21 21" fill="currentColor" height="1em" width="1em" {...props}>
      <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M6.5 3.5c-2.412 1.378-4 4.024-4 7a8 8 0 008 8m4-1c2.287-1.408 4-4.118 4-7a8 8 0 00-8-8" />
        <path d="M6.5 7.5v-4h-4M14.5 13.5v4h4" />
      </g>
    </svg>
  );
}

export default RefreshIcon;
